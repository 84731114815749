import React from 'react'
import * as S from './_styles'

export type CardWithHoverEffectProps = {
  module: string;
  title: string;
  description: string;
  backgroundImage: string;
}

const CardWithHoverEffect = ({
  module,
  title,
  description,
  backgroundImage,
}: CardWithHoverEffectProps) => {
  return (
    <S.CardWrapper backgroundImage={backgroundImage}>
      <div className='module_and_title'>
        <span
          className='module'
          dangerouslySetInnerHTML={{ __html: module }}
        />
        <span
          className='title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div
        className='description_with_mask'
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </S.CardWrapper>
  )
}

export default CardWithHoverEffect
