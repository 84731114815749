import React from 'react'
import { widths } from 'src/styles/breakpoints'
import { laranja } from 'src/styles/colors'

import companyData from '../../assets/data/company.json'

import InterCoIcons from 'src/components/UI/MarkdownIcon/InterCoIcons'

import useUtms from 'src/hooks/useUtms'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, ButtonLink, Circle } from './style'

type companyProps = {
  icon: string;
  text: string;
}

const Company = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: 'LP Pj Pro' })
  const width = useWidth(300)

  return (
    <Section id='criterios'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white'>
              Como minha empresa pode se tornar PJ Pro?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>
              Se sua empresa se encaixar em pelo menos um dos critérios ao lado, passa a usufruir das vantagens de ser PJ Pro, sem custos ou necessidade de cadastro
            </p>
            {
              width >= widths.md && (
                <ButtonLink
                  href={utmLink}
                  target='__blank'
                  rel='noreferrer'
                  onClick={() => sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Abrir conta PJ',
                    section_name: 'Como minha empresa pode se tornar PJ Pro?',
                    redirect_url: utmLink,
                  })}
                >
                  Abrir conta PJ
                </ButtonLink>
              )
            }
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            {
              companyData.map((item: companyProps) => (
                <div
                  className='d-flex align-items-center list-item'
                  key={item.icon}
                >
                  <Circle>
                    <InterCoIcons
                      size='MD'
                      width={16}
                      height={16}
                      color={laranja.terra}
                      icon={item.icon}
                    />
                  </Circle>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-white mb-0'>
                    {item.text}
                  </p>
                </div>
              ))
            }
            {
              width < widths.md && (
                <ButtonLink
                  href={utmLink}
                  target='__blank'
                  rel='noreferrer'
                  onClick={() => sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Abrir conta PJ',
                    section_name: 'Como minha empresa pode se tornar PJ Pro?',
                    redirect_url: utmLink,
                  })}
                >
                  Abrir conta PJ
                </ButtonLink>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Company
