import 'inter-site-components/lib/isc.css'
import React from 'react'
import WithFeatureFlagProvider from './src/shared/WithFeatureFlagProvider'

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    React.ReactDOM.render(element, container, callback)
  }
}

export const wrapRootElement = WithFeatureFlagProvider
