import React, { Dispatch, SetStateAction } from 'react'
import * as S from './_styles'
import Simulator from './components/_simulator'

type HeroProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const S1BoraDesapertar = ({ setIsOpen }: HeroProps) => {
  return (
    <S.BoraDespertarSection
      id='bora-desapertar'
    >
      <div className='container'>
        <S.Row className='row'>
          <S.Texts className='col-12 col-md-6 col-xl-5'>
            <S.Title>Bora desapertar</S.Title>
            <S.SubTitle>Antecipação Saque-Aniversário FGTS a partir de R$100</S.SubTitle>
            <S.Description>Receba agora até 15 anos do seu saque-aniversário sem parcelas mensais. Libere um dinheiro que já é seu e bora desapertar o bolso!</S.Description>
          </S.Texts>
          <Simulator setIsOpen={setIsOpen} />
        </S.Row>
      </div>
    </S.BoraDespertarSection>
  )
}

export default S1BoraDesapertar
