import React from 'react'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'

import { ComoFuncionaSection } from './style'
import ImageWebp from 'src/components/ImageWebp'

const ComoFunciona = () => {
  return (
    <ComoFuncionaSection className='bg-orange--extra'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-sm-6 col-md-5 mb-4 mb-sm-0 order-1 order-md-2'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra9-fgts/image.webp'
              altDescription=''
            />
          </div>
          <div className='col-12 col-sm-6 col-xl-5 order-2 order-md-1'>
            <h3 className='fs-24 lh-30 fs-lg-28 lh-lg-32 fs-xl-48 lh-xl-54 text-white mb-4 fw-500'>
              Como funciona a taxa da Antecipação do Saque-Aniversário?
            </h3>
            <p className='mb-0 fs-14 lh-19 fs-md-16 lh-md-22 fs-xl-18 lh-xl-24 text-white mb-4'>
              A taxa da Antecipação do Saque-Aniversário FGTS do Inter é a partir de 1,39% a.m., sendo aplicada anualmente sobre o valor total da antecipação e descontado apenas uma vez, diretamente no saldo do FGTS, <strong>sem comprometer a sua renda.</strong>
            </p>
            <UTMLink
              link='SIMULADOR_ANTECIPACAO_FGTS'
              text='Simule sua Antecipação do FGTS'
              btncolor='fs-14 btn text-orange--extra btn--lg btn-white text-none rounded-3 fw-700'
              sectionName='Como funciona a taxa da Antecipação do Saque-Aniversário?'
              dobra='dobra_08'
            />
          </div>
        </div>
      </div>
    </ComoFuncionaSection>
  )
}

export default ComoFunciona
