import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

type HeightProp = {
  height?: string;
}

export const ModulesCarouselContainer = styled.aside<HeightProp>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;

  margin-bottom: 24px;
  gap: 32px;

  scrollbar-color: ${orange[500]} ${grayscale[200]};

  @media ${device.tablet} {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: 32px;
    margin-bottom: 0;
  }

  @media ${device.desktopLG} {
    max-height: ${(props: HeightProp) => props.height};
  }
`

export const Module = styled.div`
  cursor: pointer;
  min-width: 216px;
  width: 216px;

  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 0;
    width: 100%;
  }
`

export const ModuleThumbnail = styled.img`
  width: 100%;
  height: auto;

  margin-bottom: 16px;
`

export const ModuleNumber = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;

  color: ${grayscale[400]};
 
  margin-bottom: 0;
`

export const ModuleTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: left;

  color: ${grayscale[500]};

  margin-bottom: 0;
`
