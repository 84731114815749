import React, { createContext, useContext, ReactNode } from 'react'

export type State = string

export type City = {
  state: State;
  cities: {
    name: string;
    neighborhoods: string[];
  }[];
}

export type Partner = {
  fantasyName: string;
  cnpj: string;
  uf: string;
  city: string;
  address: string;
  neighborhood: string;
  contractor: string;
}

export type CorrespondenteBancarioContextProps = {
  states: State[];
  cities: City[];
  partners: {
    [key: State]: Partner[];
  };
}

const CorrespondenteBancarioContext = createContext<CorrespondenteBancarioContextProps | undefined>(undefined)

export const CorrespondenteBancarioProvider = ({ children, data }: { children: ReactNode; data: CorrespondenteBancarioContextProps }) => {
  return (
    <CorrespondenteBancarioContext.Provider value={{ ...data }}>
      {children}
    </CorrespondenteBancarioContext.Provider>
  )
}

export const useCorrespondenteBancario = (): CorrespondenteBancarioContextProps => {
  const context = useContext(CorrespondenteBancarioContext)
  if (!context) {
    throw new Error('useCorrespondenteBancario must be used within a CorrespondenteBancarioProvider')
  }
  return context
}
