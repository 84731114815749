import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import S1BemVindoAWebserie from './sections/s1-bem-vindo-a-webserie/_index'
import S2DomineOMundoDeCriptoAgora from './sections/s2-domine-o-mundo-de-cripto/_index'

import { initTheme, Theme } from '@interco/inter-ui'
import VideoContextProvider from './context/videosContext'
import CustomHeader from '../components/custom-header/_customHeader'

const Webserie = () => {
  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <VideoContextProvider>
      <Layout pageContext={pageContext}>
        <CustomHeader />
        <S1BemVindoAWebserie />
        <S2DomineOMundoDeCriptoAgora />
      </Layout>
    </VideoContextProvider>
  )
}

export default Webserie
