import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, red, white } from 'src/styles/colors'
import { graphiteOne } from 'src/styles/newColors'
import styled from 'styled-components'

type PropsData = {
  page: string;
}

export const Container = styled.div<PropsData>`
  min-height: 714px;
  background: ${(props: PropsData) => props.page === 'interOne' ? white : '#707070'};
  position: relative;
  z-index: 1;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: ${breakpoints.md}) {
    min-height: 459px;
    margin-top: 52.5px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
  }
  @media (min-width: ${breakpoints.xxl}) {
    margin-top: 52.5px;
  }

  h2 {
    color: ${(props: PropsData) => props.page === 'interOne' ? graphiteOne : white};
    margin-bottom: 24px;
  }

  input {
    height: 48px;
    border-radius: 8px;
    width: 100%;

    &.error {
      border: 1px solid ${(props: PropsData) => props.page === 'interOne' ? red.base : orange.base};
    }
  }

  label {
    color: ${(props: PropsData) => props.page === 'interOne' ? grayscale[400] : white};
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  span {

    &.error {
      font-size: 12px;
      line-height: 15px;
      color: ${(props: PropsData) => props.page === 'interOne' ? red.base : white};
      text-align: right;
      margin-top: 3px;
    }
  }

  button {
    background: ${orange.extra};
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    border-radius: 8px;
    height: 48px;
    color: ${white};
    width: 100%;
    max-width: 100%;
  }
`

export const SetSent = styled.div<PropsData>`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding: 100px 85px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    font-family: 'Citrina', Helvetica, sans-serif;
    color: ${(props: PropsData) => props.page === 'interOne' ? graphiteOne : white};
    font-size: 24px;
    line-height: 26px;
    padding: 0 40px;
    margin: 24px 0;
  }
`

export const SetError = styled.div<PropsData>`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding: 100px 85px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    font-family: 'Citrina', Helvetica, sans-serif;
    color: ${(props: PropsData) => props.page === 'interOne' ? graphiteOne : white};
    font-size: 24px;
    line-height: 26px;
    padding: 0 40px;
    margin: 24px 0;
  }
`
