/* eslint-disable no-unused-expressions */
import { ACESSO_TAG, FLUXO_TAG, PRODUCT_NAME, TEXT_CONTENT } from "../_constants"
import { Investment } from "../components/InvestmentsDisplay/_InvestmentsDisplay"
import { InterBridge } from "../hooks/_useInterBridgeInstance"

export const tagPageLoad = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(ACESSO_TAG, {
    screen: TEXT_CONTENT.heading,
    content_action: "Dado carregado",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}

export const tagCardClick = async (iWb: InterBridge | undefined, title: string) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "bottomsheet",
    content_action: "Toque",
    action_id: title,
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}

export const tagCtaClick = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "botao",
    content_action: "Toque",
    action_id: TEXT_CONTENT.cta,
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}

export const tagVideoClick = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "video",
    content_action: "Toque",
    action_id:
    'Como dobrar seu dinheiro no tesouro direto? O jeito mais fácil de investir',
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}

export const tagHelpClick = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "botao",
    content_action: "Toque",
    action_id: "Ajuda",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}

export const tagBackClick = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "botao",
    content_action: "Toque",
    action_id: "Voltar",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })
}

export const tagAccordionClick = async (iWb: InterBridge | undefined, question: string) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: 'Construa seu Tesouro',
    ref_type: 'botao',
    content_action: 'Toque',
    action_id: question,
    adjust_event_token: '0',
  })
}

export const tagTermsAndConditionsClick = async (iWb: InterBridge | undefined) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: 'Construa seu Tesouro',
    ref_type: 'botao',
    content_action: 'Toque',
    action_id: 'Termos e Condições',
    adjust_event_token: '0',
  })
}

export const tagBottomSheetClick = async (iWb: InterBridge | undefined, investment: Investment) => {
  return iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: 'bottomsheet',
    content_action: 'Toque',
    action_id: investment.title,
    adjust_event_token: '0',
    product: PRODUCT_NAME,
  })
}
