import React from "react"
import { Section, Title, Text } from "./_style"

export default function Hero () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-6'>
            <Title>Abrindo sua Conta <br /> Inter Empresas</Title>
            <Text>
              <strong>Você está muito perto de destravar novas possibilidades pro seu negócio! </strong> <br />
              Siga o passo a passo e simplifique a abertura da sua conta PJ.
            </Text>
          </div>
        </div>
      </div>
    </Section>
  )
}
