import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Card = styled.div`
  background: ${grayscale[100]};
  border-radius: 16px;
  height: 167px;
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.md}){
    height: 232px;
  }
`
