import React from 'react'
import useUtms from 'src/hooks/useUtms'
import pageContext from './pageContext.json'
import BaseLayout from 'src/layouts/BaseLayout'

import {
  Hero,
  Conheca,
  ConteComUmAdvisor,
  Whatsapp,
  InvestimentosEmpresariais,
  ComoMinhaEmpresa,
  QuerSabreMaisSobreAContaPj,
} from './sections/_index'

function PJEnterprise () {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }
  return (
    <BaseLayout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
      <Hero />
      <Conheca />
      <ConteComUmAdvisor />
      <Whatsapp />
      <InvestimentosEmpresariais />
      <ComoMinhaEmpresa />
      <QuerSabreMaisSobreAContaPj />
    </BaseLayout>
  )
}

export default PJEnterprise
