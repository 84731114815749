import React from 'react'
import Img from 'gatsby-image'

import pageQuery from '../../pageQuery'

import { Section } from './style'

const Exclusive = () => {
  const data = pageQuery()

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-5 col-lg-6 col-xl-5 order-xl-last'>
            <Img fluid={data?.exclusiveAdvisor?.fluid} />
          </div>
          <div className='col-12 col-md-6 pr-xl-0'>
            <h2 className='fs-20 lh-25 fs-md-22 lh-md-28 fs-lg-30 lh-lg-38 fs-xl-40 lh-xl-50 text-grayscale--500'>
              Área exclusiva no Super App com atendimento ágil
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
              Encontre todas as informações da sua conta, com os benefícios exclusivos que sua empresa tem direito e entre em contato com seu advisor.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Exclusive
