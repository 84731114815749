import React from "react"
import { Section, Title, Text } from "./_style"
import ImageWebp from "src/components/ImageWebp"
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"
import useWidth from "src/hooks/window/useWidth"

export default function Conclusao () {
  const width = useWidth()

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 d-flex justify-content-center align-items-center order-1 order-md-2'>
            {
              width < 768 ? (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra11-360/image.webp'
                  altDescription=''
                  arrayNumbers={[ 350, 350, 400, 450 ]}
                  arrayNumbersHeight={[ 161, 350, 400, 450 ]}
                />
              )
              : (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra11-web/image.webp'
                  altDescription=''
                  arrayNumbers={[ 350, 350, 400, 450 ]}
                  arrayNumbersHeight={[ 350, 350, 400, 450 ]}
                />
              )
            }
          </div>
          <div className='col-12 col-md-6 mt-2 order-2 order-md-1 d-flex flex-column justify-content-center mt-4 mt-md-0'>
            <Title>Cadastro</Title>
            <Text>Nossa resposta leva, em média, 24 horas - mas, caso não receba nada nesse prazo, não se preocupe: te enviaremos um parecer sobre seu caso em até 5 dias úteis.</Text>
            <br />
            <Text>Atenção para algumas informações importantes: </Text>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' className='mr-2' />
              <Text>Após análise, caso haja alguma pendência te enviaremos um e-mail para que você possa corrigi-la.</Text>
            </div>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' className='mr-2' />
              <Text>Se sua conta for negada, você poderá solicitar uma nova abertura de conta em 180 dias.</Text>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
