import React, { ChangeEvent } from 'react'
import axios from 'axios'

import * as S from './_styles'
import * as URLS from 'src/config/api/Urls'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'

import { useForm, UseFormMethods } from 'react-hook-form'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { getParameterByName } from 'src/shared/helpers'

import CriptoPopups from '../../components/popups/_popups'
import { Input } from '@interco/inter-ui/components/Input'
import { Button } from '@interco/inter-ui/components/Button'
import AcceptTerms from 'src/components/AcceptTerms'
interface IFormData {
  nome: string;
  email: string;
  cpf: string;
}

const S1AprendaTudoSobreCriptomoedas = () => {
  return (
    <S.Section1AprendaTudoSobreCriptomoedas
      id='aprenda-tudo-sobre-criptomoedas'
      aria-label='Pessoa sorrindo de camiseta preta de braços cruzados e no fundo, uma moeda de criptomoedas dourada.'
    >
      <div className='container'>
        <S.Row className='row'>
          <S1AprendaTudoSobreCriptomoedas.Texts />
          <S1AprendaTudoSobreCriptomoedas.Form />
        </S.Row>
      </div>
    </S.Section1AprendaTudoSobreCriptomoedas>
  )
}

S1AprendaTudoSobreCriptomoedas.Texts = () => (
  <S.TextContent className='col-12 col-md-6 col-xl-5'>
    <S.Image>
      <source
        srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/logo-cripto-360/image.webp'
        media='(min-width: 768px)'
      />
      <S.ImageSource
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/logo-cripto360-mobile/image.webp'
      />
    </S.Image>
    <S.Title>
      Aprenda tudo sobre criptomoedas
    </S.Title>
    <S.Description>
      Aproveite para desbravar sobre o futuro das finanças com aulas gratuitas e bônus exclusivo. Inscreva-se agora!
    </S.Description>
  </S.TextContent>
)

S1AprendaTudoSobreCriptomoedas.Form = () => {
  const [ openErrorModal, setOpenErrorModal ] = React.useState<boolean>(false)
  const [ openSuccessModal, setOpenSuccessModal ] = React.useState<boolean>(false)
  const [ loading, setLoading ] = React.useState<boolean>(false)

  const [ sendDataLayer ] = useDataLayer()

  const [ aceite, setAceite ] = React.useState<boolean>(false)
  const {
    handleSubmit,
    errors,
    register,
    setValue,
    reset,
  }: UseFormMethods<IFormData> = useForm<IFormData>({
    defaultValues: {
      nome: '',
      email: '',
      cpf: '',
    },
  })

  function resetFormState () {
    setOpenErrorModal(false)
    setOpenSuccessModal(false)
    setAceite(false)
    setLoading(false)
    reset()
  }

  function onSubmit (data: IFormData) {
    setLoading(true)

    const formData = {
      nome: data.nome,
      email: data.email,
      CpfCnpj: data.cpf.replace(/\D/g, ''),
      aceite: aceite,
      campanha: 'Tudo Sobre Cripto',
      descricao: 'Principal',
      conteudo01: getParameterByName('utm_source', ''),
      conteudo02: getParameterByName('utm_medium', ''),
      conteudo03: getParameterByName('utm_campaign', ''),
    }

    axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setOpenSuccessModal(true)
        setLoading(false)
      })
      .catch(() => {
        setOpenErrorModal(true)
        setLoading(false)
      })
  }

  return (
    <>
      <CriptoPopups
        successVariant='success'
        colorVariant='dark'
        isModalOpen={openSuccessModal}
        setIsModalOpen={() => resetFormState()}
      >
        <CriptoPopups.Title>
          Cadastro realizado com sucesso!
        </CriptoPopups.Title>
        <CriptoPopups.Description>
          Parabéns, sua websérie está liberada.
        </CriptoPopups.Description>
        <Button
          fullWidth
          as='a'
          href='/tudo-sobre-criptomoedas/1/'
          rel='noreferrer'
          onClick={() => sendDataLayer({
            section: 'dobra_01',
            section_name: 'Aprenda tudo sobre criptomoedas',
            element_action: 'click button',
            element_name: 'Assistir agora',
            redirect_url: '/tudo-sobre-criptomoedas/1/',
          })}
        >
          Assistir agora
        </Button>

      </CriptoPopups>
      <CriptoPopups
        successVariant='error'
        colorVariant='dark'
        isModalOpen={openErrorModal}
        setIsModalOpen={() => resetFormState()}
      >
        <CriptoPopups.Title>
          Houve um erro por aqui
        </CriptoPopups.Title>
        <CriptoPopups.Description>
          Não foi possível criar o seu cadastro. Experimente voltar dentro de alguns instantes.
        </CriptoPopups.Description>
        <Button
          fullWidth
          onClick={() => {
            sendDataLayer({
              section: 'dobra_01',
              section_name: 'Aprenda tudo sobre criptomoedas',
              element_action: 'click button',
              element_name: 'Entendi',
            })
            resetFormState()
          }}
        >
          Entendi
        </Button>
      </CriptoPopups>
      <S.SubscriptionForm
        className='col-12 col-md-6 col-lg-4'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          className='input'
          id='nome'
          name='nome'
          label='Nome'
          placeholder='Digite seu nome completo'
          ref={register({
            required: 'Digite seu nome completo',
            validate: {
              isFullName: (name: string) => name.split(' ').length > 1 || 'Por favor, digite seu nome completo',
            },
          })}
          error={!!errors.nome}
          infoText={errors.nome?.message}
        />

        <Input
          className='input'
          id='email'
          name='email'
          label='E-mail'
          placeholder='Digite seu e-mail'
          ref={register({
            required: 'Digite seu e-mail',
            validate: {
              isValidEmail: (email: string) => email.includes('@') || 'Por favor, digite um e-mail válido',
              haveDomain: (email: string) => {
                const domain = email.split('@')[1]
                return domain.includes('.') || 'Por favor, digite um e-mail válido'
              },
            },
          })}
          error={!!errors.email}
          infoText={errors.email?.message}
        />

        <Input
          className='input'
          id='cpf'
          name='cpf'
          label='CPF'
          placeholder='Digite seu CPF'
          ref={register({
            required: 'Digite seu CPF',
            validate: {
              isValidCPF: (cpf: string) => Validations.cpf(cpf) || 'Por favor, digite um CPF válido',
            },
          })}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
          error={!!errors.cpf}
          infoText={errors.cpf?.message}
        />

        <AcceptTerms
          accept={aceite}
          setAccept={setAceite}
          styles='accept_terms'
          dataLayer={{
            section: 'dobra_01',
            section_name: 'Aprenda tudo sobre criptomoedas',
            element_name: 'Política de privacidade',
            element_action: 'click button',
          }}
        />

        <Button
          fullWidth
          type='submit'
          disabled={!aceite}
          isLoading={loading}
          onClick={() => sendDataLayer({
            section: 'dobra_01',
            section_name: 'Aprenda tudo sobre criptomoedas',
            element_name: 'Quero me cadastrar',
            element_action: 'submit',
          })}
        >
          Quero me cadastrar
        </Button>
      </S.SubscriptionForm>
    </>
  )
}

export default S1AprendaTudoSobreCriptomoedas
