import React from 'react'

import { Section, Button, ButtonLink } from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ModalProps = {
  setIsModal: Function;
}

const Hero = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_1',
      section_name: 'Seu próximo destino com muito mais cashback',
      element_action: 'click button',
      element_name: 'Quero viajar',
      c_page: window.location.href,
    })
  }
  return (
    <Section
      className='py-5 d-flex align-items-end align-items-md-center'
      role='img'
      aria-label='Paisagem de uma praia'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 pr-xl-0'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mt-5 mb-3 mt-md-0'>
              Tudo pra sua viagem com muito cashback
            </h1>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--500'>Garanta <span className='fw-600'>passagens aéreas e de ônibus, hotéis, aluguel de carros</span> e muito mais com a economia de receber parte do valor pago de volta, direto na sua conta.</p>
            <Button onClick={() => handleClick()} className='d-none d-md-block'>Quero viajar</Button>
            <ButtonLink
              className='d-md-none'
              href='https://inter-co.onelink.me/Qyu7/53fu0m0i'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_1',
                section_name: 'Seu próximo destino com muito mais cashback',
                element_action: 'click button',
                element_name: 'Quero viajar',
                c_page: window.location.href,
              })}
            >
              Quero viajar
            </ButtonLink>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
