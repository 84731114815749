import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white, grayscale, laranja, brand } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 619px;
  display: flex;
  align-items: center;
  background-color: ${brand.sand};

  h2 {
    margin-bottom: 16px;
    color: ${laranja.terra};
    font-weight: 500;
  }

  p {
    color: ${laranja.terra}
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 378px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 464px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 550px;
  }

  .react-multiple-carousel__arrow {
    svg {
      fill: ${laranja.terra} !important;
    }
  }

  .react-multi-carousel-track {
    padding-top: 0;
  }
`
export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${brand.primary};
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${white};
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 32px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 40px;
  }
`
export const Card = styled.div`
  width: 100%;
  height: auto;
  padding: 24px;
  border: 1px solid ${grayscale[200]};
  border-radius: 16px;
  min-height: 100%;
  background-color: ${white};

  .icon {
    margin-bottom: 32px;
  }
  h3 {
    margin-bottom: 16px;
    color: ${laranja.terra}
  }
  p{
    color: ${laranja.terra}
  }
 
  @media (min-width: ${breakpoints.md}) {
    padding: 24px 16px;

    .icon {
      margin-bottom: 24px;
    }
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 30px 26px;
  }
`
