import { breakpoints } from "src/styles/breakpoints"
import { primary, white } from "src/styles/newColors"
import styled from "styled-components"

export const SectionConsignadoFild = styled.section`
  background: ${primary[500]};
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 100%;

  @media (min-width: ${breakpoints.md}){
    padding-top: 64px;
  }
  @media (min-width: ${breakpoints.lg}){
    padding-top: 80px;
  }
  @media (min-width: ${breakpoints.xl}){
    padding-top: 96px;
  }
`

export const TitleConsignadoFild = styled.h1`
  font-family: 'Citrina', Helvetica, sans-serif;
  font-size: 42px;
  line-height: 50.4px;
  color: ${white};
  margin-bottom: 26px;
  font-weight: 400;

  @media (min-width: ${breakpoints.md}){
    font-size: 58px;
    line-height: 69.6px;
    margin-bottom: 24px;
  }
  @media (min-width: ${breakpoints.lg}){
    font-size: 49px;
    line-height: 58.8px;
  }
  @media (min-width: ${breakpoints.xl}){
    font-size: 85px;
    line-height: 93.5px;
  }
`

export const DescriptionConsignadoFild = styled.p`
  font-family: 'Citrina', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${white};
  margin-bottom: 38px;

  @media (min-width: ${breakpoints.md}){
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: ${breakpoints.lg}){
    margin-bottom: 40px;
  }
`
