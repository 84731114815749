import React, { MouseEvent } from 'react'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import IconWhatsapp from './assets/images/icon-whatsapp.png'

import {
  Container,
  CloseButton,
 } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type Popup = {
  closeModal: () => void;
  setPopup?: (state: boolean) => void;
  whatsappMessage: string;
  title: string;
  subTitle: string;
  dataLayer: IDataLayerParams;
}

function PopupWhatsapp ({ closeModal, setPopup, whatsappMessage, title, subTitle, dataLayer }: Popup) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    closeModal()
    setPopup && setPopup(true)
  }

  const closeButton = true && (
    <CloseButton className='close-button border-0 bg-transparent pt-4' onClick={handleClick}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
      <Container className='container bg-white'>
        {closeButton}
        <div className='row h-100 align-items-end'>
          <div className='col-12 px-0'>
            <h3 className='fs-24 lh-30 text-grayscale--500 fw-600 font-citrina mb-4 text-center'>{title}</h3>
            <p className='fs-12 lh-14 text-grayscale--400 mb-2 text-center'>{subTitle}</p>
            <a
              href={`https://api.whatsapp.com/send?text=${whatsappMessage}`}
              className='btn btn--lg bg-orange--base text-none text-white rounded-2 fs-14 lh-16 fw-700 mt-2 mw-100 w-100'
              onClick={() => {
                sendDatalayerEvent(dataLayer)
              }}
            >
              Compartilhar via WhatsApp
              <div className='ml-2'>
                <img src={IconWhatsapp} alt='Whatsapp Icon' width={24} height={24} />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PopupWhatsapp
