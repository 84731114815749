import { laranja } from 'src/styles/colors'
import styled from 'styled-components'

import { breakpoints } from 'src/styles/breakpoints'

const BannerSm = 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-5-360/image.webp'
const BannerMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-5-768/image.webp'
const BannerLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-5-1024/image.webp'
const BannerXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-5-1440/image.webp'

export const Section = styled.section`
  background-image: url(${BannerSm});
  background-repeat: no-repeat;
  min-height: 662px;
  background-color: #FCF8EE;

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  h2 , p {
    color: ${laranja.terra};
  }

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${BannerMd});
    background-repeat: no-repeat;
    min-height: 320px;
    background-position: left;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${BannerLg});
    background-repeat: no-repeat;
    min-height: 476px;
    background-position: left;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${BannerXl});
    background-repeat: no-repeat;
    min-height: 646px;
    background-position: left;
  }
`
