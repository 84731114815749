import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import { Modal } from 'src/components/Modal'
import PopupWhatsapp from 'src/components/PopupWhatsapp'
import WhatsappBottomSheet from 'src/components/WhatsappBottomSheet'

import * as S from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IIndiqueAmigosTorcedoresProps {
  sectionOrder: string;
}

const S3_IndiqueAmigos = ({ sectionOrder }: IIndiqueAmigosTorcedoresProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const buttonTitle = 'Convocar a galera'
  const whatsappMessage = 'Aproveite a parceria Inter + Athletico e venha simplificar a vida com uma Conta Digital gratuita, cartão sem anuidade e acúmulo de pontos. Baixe o Super App: https://intergo.app/56aa8020'

  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ bottomSheet, setBottomSheet ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Indique amigos torcedores e aproveite ainda mais a parceria Athletico + Inter!',
    element_action: 'click button',
    element_name: 'Compartilhar via whatsapp',
  }

  const windowWidth = useWidth(300)
  const domReady = useDomReady()
  const WIDTH_MD = 768

  const handleClick = () => {
    sendDatalayerEvent({
      section: `dobra_${sectionOrder}`,
      section_name: 'Indique amigos torcedores e aproveite ainda mais a parceria Athletico + Inter!',
      element_action: 'click button',
      element_name: buttonTitle,
    })
    if (windowWidth >= WIDTH_MD) {
      setIsPopupOpen(!isPopupOpen)
    } else {
      setBottomSheet(!bottomSheet)
    }
  }

  const PopupExitPage = domReady && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen} locationToRender={document.body}>
      <PopupWhatsapp
        whatsappMessage={whatsappMessage}
        closeModal={() => setIsPopupOpen(false)}
        title='Chame seus amigos para aproveitar os benefícios Inter + CAP'
        subTitle='Você pode compartilhar esse link com quantos amigos quiser'
        dataLayer={dataLayer}
      />
    </Modal>
  )

  const BottomSheetMobile = domReady && (
    <Modal isModalOpen={bottomSheet} setIsModalOpen={setBottomSheet} locationToRender={document.body}>
      <WhatsappBottomSheet
        whatsappMessage={whatsappMessage}
        title='Chame seus amigos para aproveitar os benefícios Inter + CAP'
        subTitle='Você pode compartilhar esse link com quantos amigos quiser'
        dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <S.Section>
      {PopupExitPage}
      {BottomSheetMobile}

      <S.ContentInfo>
        <h2 className='title'>
          Quem indica amigo é
        </h2>
        <p className='description'>
          Com tantas vantagens assim, você não pode deixar seus amigos torcedores de fora.
          E ainda aproveita pra receber pontos Loop para cada amigo indicado que abrir uma conta.
        </p>
        <div className='box-cta'>
          <a
            onClick={handleClick}
            title='Convoque a torcida e ganhe pontos Loop'
            className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
          >
            Convoque a torcida e ganhe pontos Loop
          </a>
        </div>
      </S.ContentInfo>
      <S.ContentImage>
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/cap-dobra4-cartoes/image.webp'
          alt='tela de celular mostrando a home do Super App Inter'
        />
      </S.ContentImage>
    </S.Section>
  )
}

export default S3_IndiqueAmigos
