import { orange } from 'src/styles/colors'
import styled from 'styled-components'

type ShowMoreProps = {
  opened: boolean;
};

export const ShowMoreWrapper = styled.div<ShowMoreProps>`
  transition: max-height 300ms ease-in-out;
  height: auto;
  max-height: ${(props: ShowMoreProps) => props.opened ? '500px' : '64px'};
  overflow: hidden;

  margin-top: 24px;
`

export const ShowMoreButton = styled.p`
  cursor: pointer;

  font-family: Inter, sans-serif, arial;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: ${orange[500]};
  padding: 10px 0;

  margin: 0 auto 24px auto;

  .icon_source {
    width: 16px;
    height: 16px;

    margin-left: 8px;
  }
`
