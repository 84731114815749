import React from 'react'

import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './_styles'

import { SupportContentCardType } from '../../../_types'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { supportContent } from './_supportContent'

import { orange } from 'src/styles/colors'

const SupportContents = () => {
  const width = useWidth()
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.SupportContent>
      <S.SupportContentTitle>Conteúdo de apoio:</S.SupportContentTitle>
      <S.SupportContentCardsList>
        {
          supportContent.map((item: SupportContentCardType) => (
            <S.SupportContentCard
              key={item.text}
              href={item.link}
              target='_blank'
              onClick={() => sendDataLayer({
                section: 'dobra_01',
                section_name: 'Olá, bem-vindo(a) à websérie Tudo sobre Cripto!',
                element_name: 'Acessar',
                element_action: 'click banner',
                redirect_url: item.link,
              })}
            >
              <S.SupportContentCardText>{item.text}</S.SupportContentCardText>
              { width < 768
                ? (<IconsSwitch icon='ic_chevron_right' lib='interco' size='MD' color={orange[500]} customPath='action-navigation/' className='right_chevron' />)
                : (<S.SupportContentCardLink>Acessar</S.SupportContentCardLink>)
              }
            </S.SupportContentCard>
          ))
        }
      </S.SupportContentCardsList>
    </S.SupportContent>
  )
}

export default SupportContents
