import React from 'react'
import Img from 'gatsby-image'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import Icon from 'src/components/UI/MarkdownIcon'
import useWidthResize from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import trPoupancaOuIPCA from './assets/data/data.json'

import usePageQuery from '../../pageQuery'

import { PerfectForYouSection, Card, CardMobileDiv, BoxCarousel } from './style'

type CardType = {
  icon: string;
  title: string;
  description: string;
}

const TRPoupancaOuIPCA = () => {
  const data = usePageQuery()
  const windowWidth = useWidthResize()
  const isMobile = windowWidth < WIDTH_MD

  return (
    <PerfectForYouSection id='qual-a-taxa-ideal' className='py-5'>
      <div className='container'>
        <div className='row align-items-lg-center align-items-xl-start'>
          <div className='col-12 col-md-6 col-lg-5 mt-xl-4 mb-4 mb-md-0 mt-md-5 mt-lg-0'>
            <Img fluid={data.herobannerTRPoupancaOuIPCA?.fluid} alt='Casal feliz com a aquisição de um imóvel.' />
          </div>
          <div className='col-12 col-md-6 offset-lg-1 pr-md-0'>

            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-3'>
              Seu financiamento com correção pelo IPCA
            </h2>

            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4'>
              No Inter, seu financiamento imobiliário é atrelado ao IPCA, índice pós-fixado oficial da inflação. Com ele, as taxas estão mais sujeitas a variações e refletem o momento econômico.
            </p>

            <div>
              <Icon width='24' height='24' icon='pagamentos/juros' directory='v2' color='orange--extra' />
              <p className='fs-18 lh-22 text-grayscale--500 fw-700 mb-3 mt-1'>Como funciona</p>
            </div>

            {isMobile ? (
              <CardMobileDiv className='row justify-content-center mb-4'>
                {trPoupancaOuIPCA.map((CardData: CardType) => (
                  <Card className='bg-grayscale--100' key={CardData.icon}>
                    <p className='fs-14 fs-lg-18 lh-17 lh-lg-22 text-gray--700 m-0'>{CardData.description}</p>
                  </Card>
                  ))}
              </CardMobileDiv>
              ) : (
                <BoxCarousel>
                  <DefaultCarousel
                    xl={{ items: 2 }}
                    lg={{ items: 1, partialVisibilityGutter: 170 }}
                    md={{ items: 1, partialVisibilityGutter: 51 }}
                  >
                    {trPoupancaOuIPCA.map((CardData: CardType) => (
                      <Card className='bg-grayscale--100 mr-4' key={CardData.icon}>
                        <p className='fs-14 fs-lg-18 lh-17 lh-lg-22 text-gray--700 m-0'>{CardData.description}</p>
                      </Card>
                  ))}
                  </DefaultCarousel>
                </BoxCarousel>
            )}

            <p className='fs-14 lh-16 text-grayscale--400 mt-4'>
              Por se tratar de um índice futuro, o IPCA não está incluso em nossas simulações.
            </p>
          </div>
        </div>
      </div>
    </PerfectForYouSection>
  )
}

export default TRPoupancaOuIPCA
