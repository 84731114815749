import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

type HeightProp = {
  height?: string;
}

export const S1BemVindoAWebserieSection = styled.section`
  background-color: ${grayscale[100]};
  overflow: hidden;
  padding: 40px 0;
  
  h1, h2, h3, h4, h5 {
    font-family: Citrina, sans-serif, arial;
  }

  p {
    font-family: Inter, sans-serif, Arial;
  }

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL} {
    padding: 96px 0;
  }
`

export const Row = styled.div`
  .selected_module_thumb {
    @media ${device.desktopLG} {
      max-height: fit-content;
    }
  }
`

export const ContentContainer = styled.article<HeightProp>`
  @media ${device.tablet} {
    display: flex;
    height: ${(props: HeightProp) => props.height ?? 'auto'};
  }

  @media ${device.desktopLG} {
    flex-direction: column;
    max-height: fit-content;
  }
`

export const VideoContent = styled.div`
  height: fit-content;
  max-height: fit-content;
`
