import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import data from '../../assets/data/dataMEI.json'
import { Fees } from './style'

export const MEI = () => {
  return (
    <div className='row align-items-md-center'>
      <div className='col-12 col-md-6 order-2 order-md-1 mt-5'>
        <Fees>
          {
            data.map((item: {type: string; fee: string}) => (
              <div key={item.type} className='d-flex justify-content-between w-100'>
                <span>{item.type}</span>
                <span><strong>{item.fee}</strong></span>
              </div>
            ))
          }
        </Fees>
      </div>
      <div className='col-12 col-md-6 order-1 order-md-2 d-flex justify-content-center'>
        <ImageWebp
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/rebranding-maquininha-carousel2-better-quality/image.webp'
          altDescription=''
          arrayNumbers={[ 400, 400, 400, 500, 550 ]}
        />
      </div>
    </div>
  )
}
