import React, { useState } from 'react'

// Components
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import { Modal } from 'src/components/Modal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Hooks
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import { widths } from 'src/styles/breakpoints'

// Assets
import Dollar from '../../assets/image/dollar.png'

import { Section, Card, Button, LinkButton } from './style'
import { SuperAppInterProps } from '../../types'

const Conditions = ({ setOpenModal }: SuperAppInterProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const [ open, setOpen ] = useState(false)
  const domReady = useDomReady()

  const openModal = domReady && (
    <Modal isModalOpen={open} setIsModalOpen={setOpen} locationToRender={document.body}>
      <ModalAccount
        closeModal={() => setOpen(false)}
      />
    </Modal>
  )

  return (
    <Section className='py-5 bg-yellow--extra-light'>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-center'>
              Moeda poupada é moeda ganha
            </h2>
            <p className='fs-14 fs-md-18 lh-17 lh-md-22 fw-400 text-center'>
              Suas compras mais inteligentes com o Super App
            </p>
          </div>
          <div className='col-md-12 col-lg-12 offset-lg-1 col-xl-10 offset-xl-0 mt-5 mt-lg-0'>
            <div className='row justify-content-center mt-lg-5'>
              <div className='col-12 col-md-4 col-lg-3 col-xl-4 col-xl-3 mb-5 mb-md-0 d-flex justify-content-center'>
                <Card className='px-3 py-4'>
                  <div className='d-flex headerCard'>
                    <div><img src={Dollar} /></div>
                    <div className='titleCupons title px-3 py-2'><span className='fs-20 lh-24 text-grayscale--500'>Alerta  de preço</span></div>
                  </div>
                  <p className='fs-18 lh-22 fw-700 text-white text-center'>
                    Escolha o produto, crie o alerta e seja avisado quando o preço cair
                  </p>
                </Card>
              </div>
              <div className='col-12 col-md-4 col-lg-4 col-xl-4 mb-5 d-flex justify-content-center'>
                <Card className='px-3 py-4'>
                  <div className='d-flex headerCard'>
                    <div><img src={Dollar} /></div>
                    <div className='titleAlerta title px-3 py-2'><span className='fs-20 lh-24 text-grayscale--500'>Orange Box</span></div>
                  </div>
                  <p className='fs-18 lh-22 fw-700 text-white text-center mt-md-1'>
                    Caixa surpresa com produtos até pela metade do preço
                  </p>
                </Card>
              </div>
              <div className='col-12 col-md-4 col-lg-4 col-xl-4 mb-5 d-flex justify-content-center'>
                <Card className='px-3 py-4'>
                  <div className='d-flex headerCard'>
                    <div><img src={Dollar} /></div>
                    <div className='titlePagamento title px-3 py-2'><span className='fs-20 lh-24 text-grayscale--500'>Pague do seu jeito</span></div>
                  </div>
                  <p className='fs-18 lh-22 fw-700 text-white text-center'>
                    Parcelamento em até 24x no Crediário Digital e muito mais
                  </p>
                </Card>
              </div>
              <div className='col-12 col-md-4 col-lg-4 col-xl-4 mb-5 mb-md-0 d-flex justify-content-center'>
                <Card className='px-3 py-4'>
                  <div className='d-flex headerCard'>
                    <div><img src={Dollar} /></div>
                    <div className='titlePagamento title px-3 py-2'><span className='fs-20 lh-24 text-grayscale--500'>Madrugadão</span></div>
                  </div>
                  <p className='fs-18 lh-22 fw-700 text-white text-center mt-md-1'>
                    Tem oferta relâmpago de OOh às 06h
                  </p>
                </Card>
              </div>
              <div className='col-12 col-md-4 col-lg-4 col-xl-4 mb-md-0 d-flex justify-content-center'>
                <Card className='px-3 py-4'>
                  <div className='d-flex headerCard'>
                    <div><img src={Dollar} /></div>
                    <div className='titleLiveShop title px-3 py-2'><span className='fs-20 lh-24 text-grayscale--500'>Live Shop</span></div>
                  </div>
                  <p className='fs-18 lh-22 fw-700 text-white text-center mt-md-1'>
                    Mega ofertas ao vivo pra zerar sua lista de desejos
                  </p>
                </Card>
              </div>
              <div className='col-12 d-flex justify-content-center mt-3 mt-md-4'>
                {
                  width < widths.md ? (
                    <LinkButton
                      href='https://inter-co.onelink.me/Qyu7/5p8n7moc'
                      target='_blank' rel='noreferrer'
                      className='btn btn--lg rounded-2 fs-14 fw-600 text-none text-white mt-3 mb-0 mw-100'
                    >
                      Acessar Super App
                    </LinkButton>
                  ) : (
                    <Button
                      className='btn btn--lg rounded-2 fs-14 fw-600 text-none text-white mt-3 mb-0 mw-100'
                      onClick={() => {
                      setOpenModal && setOpenModal(true)
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        section_name: 'Semana do Consumidor 2024',
                        element_name: 'Acessar Super App',
                        element_action: 'click button',
                      })
                      } }
                    >
                      Acessar Super App
                    </Button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Conditions
