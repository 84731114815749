import React from "react"
import { Section, Title, Text } from "./_style"
import DefaultCarousel from "src/components/UI/Carousels/DefaultCarousel"
import data from './data.json'
import ImageWebp from "src/components/ImageWebp"
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"

export default function DuasEtapas () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <Title>Verificação em duas etapas</Title>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 30 }}
              md={{ items: 2, partialVisibilityGutter: 20 }}
              lg={{ items: 3, partialVisibilityGutter: 20 }}
              xl={{ items: 3, partialVisibilityGutter: 20 }}
            >
              {
                data.map((item: {img: string; text: string[]}, index: number) => (
                  <div key={index} className='mx-2'>
                    <ImageWebp pathSrc={item.img} altDescription='' className='mb-2' />
                    {
                      item.text.map((text: string, textIndex: number) => (
                        <div key={textIndex}>
                          <OrangeIcon size='MD' icon='arrow-right' color='#72370E' className='mr-2' />
                          <Text>{text}</Text>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}
