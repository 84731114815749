import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'

const JurosReduzidos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://inter.co/canais-de-atendimento/'

  return (
    <div className='bg-orange--extra d-flex align-items-center justify-content-center' style={{ height: 312 }}>
      <div className='container py-5'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-4'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-white'>
              Dúvidas sobre seu contrato?
            </h2>
          </div>
          <div className='col-12 col-md-6'>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--100 mb-3'>
              Acesse nossos canais de atendimento. Estamos prontos para te ajudar.
            </p>
            <a
              href={url}
              target='_blank'
              rel='noreferrer'
              className='btn btn-white text-orange--extra fw-bold btn--lg rounded-2 fs-14 fw-700 text-none text-md-center d-flex justify-content-between'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_5',
                  section_name: 'Dúvidas sobre seu contrato?',
                  element_action: 'click button',
                  element_name: 'Canais de atendimento',
                  redirect_url: url,
                 })
              }}
            >Canais de atendimento
              <IcChevronRight height={24} width={26} color='#FF7A00' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JurosReduzidos
