import React, { useEffect } from 'react'

import * as S from './_styles'

import { useVideoContext } from '../../context/videosContext'
import useWidth from 'src/hooks/window/useWidth'

import ShowMore from './components/showMore/_showMore'

import Title from './components/section-1-subcomponents/title/_title'
import WebserieModules from './components/section-1-subcomponents/webserieModules/_webserieModules'
import SelectedModule from './components/section-1-subcomponents/selectedModule/_selectedModule'
import SupportContents from './components/section-1-subcomponents/supportContent/_supportContents'

const S1BemVindoAWebserie = () => {
  const width = useWidth()

  return (
    <>
      {width < 768 && (<S1BemVindoAWebserie.MobileView />)}
      {width >= 768 && width < 1024 && (<S1BemVindoAWebserie.TabletView />)}
      {width >= 1024 && (<S1BemVindoAWebserie.LgDesktopView />)}
    </>
  )
}

S1BemVindoAWebserie.MobileView = () => {
  const titleContainerRef = React.useRef<HTMLDivElement>(null)
  const titlePosition = titleContainerRef.current
    ? titleContainerRef.current.getBoundingClientRect().top + window.scrollY
    : 20

  return (
    <S.S1BemVindoAWebserieSection>
      <div className='container'>
        <S.Row className='row'>
          <Title titleContainerRef={titleContainerRef} />
          <S.ContentContainer className='col-12 col-lg-8 col-xl-9'>
            <S.VideoContent>
              <SelectedModule.Thumb titleContainerPosition={titlePosition} />
              <SelectedModule.Title />
              <ShowMore>
                <SelectedModule.Topics />
              </ShowMore>
            </S.VideoContent>
          </S.ContentContainer>
          <WebserieModules titleContainerPosition={titlePosition} />
          <SupportContents />
        </S.Row>
      </div>
    </S.S1BemVindoAWebserieSection>
  )
}

S1BemVindoAWebserie.TabletView = () => {
  const currentModule = useVideoContext().getCurrentModule()

  const titleContainerRef = React.useRef<HTMLDivElement>(null)
  const videoContentRef = React.useRef<HTMLDivElement>(null)
  const [ topicsHeight, setTopicsHeight ] = React.useState<string>('auto')

  const titlePosition = titleContainerRef.current
    ? titleContainerRef.current.getBoundingClientRect().top + window.scrollY
    : 20

  useEffect(() => {
    setTopicsHeight(`${videoContentRef.current?.clientHeight}px`)
  }, [ currentModule ])

  return (
    <S.S1BemVindoAWebserieSection>
      <div className='container'>
        <S.Row className='row'>
          <Title titleContainerRef={titleContainerRef} />
          <SelectedModule.Thumb titleContainerPosition={titlePosition} />
          <S.ContentContainer
            className='col-12 col-lg-8 col-xl-9'
            height={topicsHeight}
          >
            <S.VideoContent
              ref={videoContentRef}
              className='col-12 col-md-7'
            >
              <SelectedModule.Title />
              <SelectedModule.Topics />
              <SupportContents />
            </S.VideoContent>
            <WebserieModules titleContainerPosition={titlePosition} />
          </S.ContentContainer>
        </S.Row>
      </div>
    </S.S1BemVindoAWebserieSection>
  )
}

S1BemVindoAWebserie.LgDesktopView = () => {
  const currentModule = useVideoContext().getCurrentModule()

  const contentContainerRef = React.useRef<HTMLDivElement>(null)
  const titleContainerRef = React.useRef<HTMLDivElement>(null)

  const [ contentHeight, setContentHeight ] = React.useState<string>('auto')
  const [ imageIsLoaded, setImageIsLoaded ] = React.useState<boolean>(false)

  const titlePosition = titleContainerRef.current
    ? titleContainerRef.current.getBoundingClientRect().top + window.scrollY
    : 20

  useEffect(() => {
    setContentHeight(`${contentContainerRef.current?.clientHeight}px`)
  }, [ currentModule, imageIsLoaded ])

  return (
    <S.S1BemVindoAWebserieSection>
      <div className='container'>
        <S.Row className='row'>
          <Title titleContainerRef={titleContainerRef} />
          <S.ContentContainer
            className='col-12 col-lg-8 col-xl-9'
            ref={contentContainerRef}
          >
            <SelectedModule.Thumb
              setImageIsLoaded={setImageIsLoaded}
              titleContainerPosition={titlePosition}
            />
            <S.VideoContent className='col-12 col-md-7 col-lg-12'>
              <SelectedModule.Title />
              <SelectedModule.Topics />
              <SupportContents />
            </S.VideoContent>
          </S.ContentContainer>
          <WebserieModules
            titleContainerPosition={titlePosition}
            height={contentHeight}
          />
        </S.Row>
      </div>
    </S.S1BemVindoAWebserieSection>
  )
}

export default S1BemVindoAWebserie
