import NewHeader from '@interco/institucional-frontend-lib-header/dist'
import loadable from '@loadable/component'
import GetAppBanner from 'components/GetAppBanner'
import createI18n from 'components/i18n/i18n'
import Footer from 'components/Layout/Footer'
import MetaHeader from 'components/NewComponents/MetaHeader'
import * as URLs from 'config/api/Urls'
import QueryString from 'query-string'
import React, { memo, useContext, useEffect, useState } from 'react'
import { I18nextProvider, translate } from 'react-i18next'
import { Modal } from 'src/components/Modal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import CDP from './components/CDP'

import { useLocation } from '@reach/router'
import { GlobalContext, GlobalProvider, IGlobalContext } from 'src/context/GlobalContext'
import { ModalContext } from 'src/hoc/contexts/'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from '../../hooks/window/useWidth'

import 'src/assets/sass/global.scss'
import OpenAccountQRCODE from '../../assets/images/qr-code-open-account-pf-modal.png'

import { BaseLayoutProps } from './types'

// Dynamic Import Forms
const OpenAccountPJForm = loadable(() => import('src/components/UI/Forms/OpenAccountPJForm'))
const OpenAccountRightForm = loadable(() => import('src/components/UI/Forms/OpenAccountRightForm'))
const InterBlackContactForm = loadable(() => import('src/components/UI/Forms/InterBlackContactForm'))
const InterOneContactForm = loadable(() => import('src/components/UI/Forms/InterOneContactForm'))

const WIDTH_LG = 992
function BaseLayout ({ pageContext, children, modalOpenInterOneDefaultValue, modalOpenInterBlackDefaultValue }: BaseLayoutProps) {
  const [ modalOpenYourAccount, setModalOpenYourAccount ] = useState(false)
  const [ modalOpenYourAccountPJ, setModalOpenYourAccountPJ ] = useState(false)
  const [ modalOpenInterOneContactForm, setModalOpenInterOneContactForm ] = useState(modalOpenInterOneDefaultValue || false)
  const [ modalOpenInterBlackContactForm, setModalOpenInterBlackContactForm ] = useState(modalOpenInterBlackDefaultValue || false)
  const [ dataLayerContext, setDataLayerContext ] = useState<IDataLayerParams>({
    section: 'null',
    section_name: 'null',
    element_name: 'null',
  })

  const windowWidth = useWidth(300)
  const domReady = useDomReady()
  const location = useLocation()
  const [ typeDataLayer, setTypeDataLayer ] = useState('ga_event_body')
  const { headerColorContrast, scrollTopHomeValue }: IGlobalContext = useContext(GlobalContext)

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'null',
    section_name: 'null',
    element_name: 'null',
  })

  // Abrir modal com url
  const queryString = QueryString.parse(location.href)

  useEffect(() => {
    if (queryString.modal === 'open') {
      setModalOpenYourAccount(true)
      setTypeDataLayer(typeDataLayer)
      window.history.pushState('', '', window.location.href.replace('?&modal=open', ''))
    }
  }, [ typeDataLayer ])

  const openAccountCustomOrigin = pageContext.openAccountCustomOrigin || `site-institucional_${location.pathname}`

  return (
    <ModalContext.Provider value={{
        'open-your-account-form': {
          modal: modalOpenYourAccount,
          setModal: setModalOpenYourAccount,
          isSetDataLayer: setTypeDataLayer,
        },
        'open-your-account-form-pj': {
          modal: modalOpenYourAccountPJ,
          setModal: setModalOpenYourAccountPJ,
          isSetDataLayer: setTypeDataLayer,
          setDataLayerContext: setDataLayerContext,
        },
        'modal-open-inter-black-contact-form': {
          modal: modalOpenInterBlackContactForm,
          setModal: setModalOpenInterBlackContactForm,
          isSetDataLayer: setTypeDataLayer,
        },
        'modal-open-inter-one-contact-form': {
          modal: modalOpenInterOneContactForm,
          setModal: setModalOpenInterOneContactForm,
          isSetDataLayer: setTypeDataLayer,
        },
    }}
    >
      <MetaHeader
        title={pageContext.metaTitle}
        metaDescription={pageContext.metaDescription}
        canonical={`${URLs.SITE_BASE_URL}${pageContext.slug}`}
        url={`${URLs.SITE_BASE_URL}${pageContext.slug}`}
        ogTitle={pageContext.ogTitle}
        ogUrl={pageContext.ogUrl}
        ogDescription={pageContext.ogDescription}
        ogImage={pageContext.ogImage}
        ogImageWidth={pageContext.ogImageWidth}
        ogImageHeight={pageContext.ogImageHeight}
        ogType={pageContext.ogType}
        twitterCard={pageContext.twitterCard}
        twitterSite={pageContext.twitterSite}
        twitterCreator={pageContext.twitterCreator}
        crazyEggId={pageContext.crazyEggId || false}
        noFollowOption={pageContext.noFollowOption || false}
        noIndexOption={pageContext.noIndexOption || false}
        structuredData={pageContext.structuredData}
        isClarityScript={pageContext.isClarityScript || false}
        redirectUsa={pageContext.redirectUsa || ''}
        hrefLang={pageContext.hrefLang || undefined}
        removeFaqStructureData={pageContext.removeFaqStructureData || false}
      />
      {domReady && !pageContext.hideHeader && (
        <NewHeader
          theme={pageContext.headerTheme}
          headerColorContrast={headerColorContrast}
          positionFixed={pageContext.headerProps?.positionFixed}
          darkBackground={pageContext.headerProps?.darkBackground}
          lang={pageContext.headerLang}
          isHeaderUs={pageContext.isHeaderUs}
        />
        )
      }
      <main id='main'>
        {children}
      </main>
      {!pageContext.hideFooter && (!pageContext.darkFooter
      ? <Footer
          slug={pageContext.slug}
          pageEN={pageContext.pageEN}
          pagePT={pageContext.pagePT}
          isHeaderUS={pageContext.hideHeaderUS}
        />
      : <Footer
          theme='dark'
          slug={pageContext.slug}
          pageEN={pageContext.pageEN}
          pagePT={pageContext.pagePT}
          isHeaderUS={pageContext.hideHeaderUS}
        />)}
      {
        domReady && windowWidth >= WIDTH_LG
        ? null
        : <GetAppBanner scrollTopHomeValue={scrollTopHomeValue} />

      }
      {
        domReady && (
          <Modal
            isModalOpen={modalOpenYourAccount}
            setIsModalOpen={setModalOpenYourAccount}
            locationToRender={document.body}
          >
            {modalOpenYourAccount &&
              <OpenAccountRightForm
                closeModal={() => setModalOpenYourAccount(false)}
                customOrigin={openAccountCustomOrigin}
                qrBaixeApp={OpenAccountQRCODE}
                dataLayer={dataLayer}
                typeDataLayer={typeDataLayer}
              />
            }
          </Modal>
        )
      }
      {
        domReady && (
          <Modal
            isModalOpen={modalOpenYourAccountPJ}
            setIsModalOpen={setModalOpenYourAccountPJ}
            locationToRender={document.body}
          >
            {modalOpenYourAccountPJ &&
              <OpenAccountPJForm
                closeModal={() => setModalOpenYourAccountPJ(false)}
                dataLayer={dataLayerContext}
              />}
          </Modal>
        )
      }
      {
        domReady && (
          <Modal
            isModalOpen={modalOpenInterBlackContactForm}
            setIsModalOpen={setModalOpenInterBlackContactForm}
            locationToRender={document.body}
          >
            {modalOpenInterBlackContactForm &&
              <InterBlackContactForm closeModal={() => setModalOpenInterBlackContactForm(false)} customOrigin='LP_BLACK' />}
          </Modal>
        )
      }
      {
        domReady && (
          <Modal
            isModalOpen={modalOpenInterOneContactForm} setIsModalOpen={setModalOpenInterOneContactForm}
            locationToRender={document.body}
          >
            {modalOpenInterOneContactForm &&
              <InterOneContactForm closeModal={() => setModalOpenInterOneContactForm(false)} customOrigin='LP_ONE' />}
          </Modal>
        )
      }
    </ModalContext.Provider>
  )
}

const WrapperBaseLayout = <P extends BaseLayoutProps>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    let result = ''
    const language = props.pageContext.slug.match(/(\/)(\w{2})(\/)/)

    if (language) {
      result = language && language[0].replace(/\//g, '')
      if (result === 'pj' || result === 'us') {
        result = 'pt'
      }
    } else {
      result = 'pt'
    }

    return (
      <GlobalProvider>
        <I18nextProvider i18n={createI18n} initialLanguage={result}>
          <>
            <CDP />
            <Component {...props} />
          </>
        </I18nextProvider>
      </GlobalProvider>
    )
  }
}

BaseLayout.defaultProps = {
  darkFooter: false,
  pageContext: {},
  children: null,
  handleHeaderOpenAccount: null,
}

export default WrapperBaseLayout(translate('Components')(memo((BaseLayout))))
