import React from 'react'

import { Section } from './styles'

import ImageWebp from 'src/components/ImageWebp'

export const QuerSabreMaisSobreAContaPj = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-4 order-md-last order-lg-first mb-4 mb-md-0'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-7/image.webp'
              altDescription='Conta PJ Enterprise Inter Empresas”'
              arrayNumbers={[ ]}
              arrayNumbersHeight={[ ]}
            />
          </div>

          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 col-xl-5 offset-xl-2'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-3 mr-n2 mr-xl-n3'>
              Quer saber mais sobre a Conta PJ Enterprise Inter Empresas?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-0 mr-n2'>
              Conte com a gente para tirar todas as suas dúvidas através dos nossos canais oficiais de atendimento.
              <span className='fw-700 d-block mt-4'>
                Para sua comodidade, salve em sua agenda: 0800 940 0007
              </span>
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
