import React, { useState } from 'react'
import { SectionWrapper, Card, Logo, ExpandButton } from './style'
import FerramentasJSON from '../../assets/data/ferramentas.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

interface IParaVoceInvestirMelhorProps {
  handleClick: () => void;
  sendDatalayerEvent: Function;
}

type FerramentasCarouselProps = {
  id: number;
  logo: string;
  title: string;
  description: string;
  priceUpInfo: string;
  price: string;
  priceInfo: string;
  button: string;
  link: string;
  active: boolean;
  advantages: string[];
}

const ParaVoceInvestirMelhor = ({ handleClick, sendDatalayerEvent }: IParaVoceInvestirMelhorProps) => {
  const [ expanded, setExpanded ] = useState(false)

  return (
    <SectionWrapper id='tenha-as-melhores-ferramentas-com-o-investpro'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 pr-0 pl-md-0 pr-md-3 pl-lg-3 pr-lg-0 text-center'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-white fw-600 mb-3'>
              Conheça as melhores ferramentas para traders
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-grayscale--100 mb-0'>
              Acesse o Super App do Inter e escolha a melhor opção para obter os melhores resultados.
            </p>
          </div>
          <div className='col-12 mt-2 mt-md-0 px-0'>
            <DefaultCarousel
              sm={{ items: 1, slidesToSlide: 1 }}
              md={{ items: 2, slidesToSlide: 1 }}
              lg={{ items: 2, slidesToSlide: 1 }}
              xl={{ items: 3, slidesToSlide: 1 }}
            >
              {
              FerramentasJSON.map((item: FerramentasCarouselProps) => (
                <Card key={item.id} expanded={expanded}>
                  <Logo className='col-12 d-flex justify-content-center'>
                    <img width={150} src={item.logo} />
                  </Logo>
                  <div style={{ padding: 24 }}>
                    <div className='spacing-evenly'>
                      <h3
                        className='fs-28 lh-32 text-grayscale--500 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fw-600'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <p
                        className='fs-16 fs-lg-16 lh-20 lh-lg-19 text-grayscale--500'
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      <p
                        className='fs-16 fs-lg-16 lh-20 lh-lg-19 text-grayscale--500'
                        dangerouslySetInnerHTML={{ __html: item.priceUpInfo }}
                      />
                      <p
                        className='fs-40 lh-44 text-grayscale--500 font-citrina fw-700'
                        dangerouslySetInnerHTML={{ __html: item.price }}
                      />
                      <p
                        className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--400'
                        dangerouslySetInnerHTML={{ __html: item.priceInfo }}
                      />
                      {
                      item.active ? (
                        <a
                          className='btn--lg btn btn-orange--extra text-white rounded-2 fs-14 fw-700 text-none mw-100'
                          dangerouslySetInnerHTML={{ __html: item.button }}
                          href={item.link}
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_05',
                              section_name: 'As melhores ferramentas para traders',
                              element_action: 'click button',
                              element_name: item.button,
                            })
                          }}
                        />
                      ) : (
                        <div
                          className='btn--lg btn bg-grayscale--200 text-grayscale--300 rounded-2 fs-14 fw-700 text-none mw-100'
                          dangerouslySetInnerHTML={{ __html: item.button }}
                        />
                      )
                    }
                    </div>

                    <p className='fw-600 fs-16 lh-20 text-grayscale--500 mt-5'>Principais vantagens</p>
                    {
                        item.advantages.map((advantage: string) => (
                          <div key={advantage} className='mb-2'>
                            <OrangeDsIcon icon='check-circle' size='MD' color='#161616' className='d-inline' />
                            <p className='fs-14 lh-16 text-grayscale--500 ml-2 d-inline'>{advantage}</p>
                          </div>
                        ))
                      }
                  </div>
                  {
                    item.advantages.length > 4 ? (
                      <ExpandButton onClick={() => setExpanded(!expanded)}>
                        Mais vantagens
                      </ExpandButton>
                    ) : ''
                  }

                </Card>
              ))
            }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ParaVoceInvestirMelhor
