import React from "react"
import { Section, Title, Text } from "./_style"

export default function End () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 mt-2 order-2 order-md-1 d-flex flex-column justify-content-center mt-4 mt-md-0'>
            <Title>Chegou até aqui?<br />Parabéns!</Title>
            <Text>Você deu o primeiro passo para fazer uma gestão financeira inteligente do seu negócio.</Text>
          </div>
        </div>
      </div>
    </Section>
  )
}
