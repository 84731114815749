import React from 'react'

import * as S from './_styles'
import courseAdvantagesJson from './courseAdvantages.json'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { orange } from 'src/styles/colors'
import OpenVideo from 'src/components/OpenVideo'

type CourseAdvantage = {
  description: string;
  icon: {
    name: string;
    customPath: string;
  };
};

const S2OportunidadeParaComprarCripto = () => {
  return (
    <S.Section2OportunidadeParaComprarCripto
      id='oportunidade-para-comprar-cripto-e-agora'
    >
      <div className='container'>
        <S.Row className='row'>
          <OpenVideo
            styles='col-12 col-md-6 col-xl-7'
            link='https://www.youtube.com/embed/Yv-syBMvDpI'
          >
            <S.VideoThumbnail
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/thumbnail-o-que-e-bitcion/image.webp'
            />
          </OpenVideo>
          <S.TextsContent
            className='col-12 col-md-6 col-xl-5'
          >
            <S.Title>
              Oportunidade para comprar cripto é agora!
            </S.Title>
            <S.CourseAdvantagesList>
              {courseAdvantagesJson.map((courseAdvantage: CourseAdvantage) => (
                <S.CourseAdvantage key={courseAdvantage.description}>
                  <S.CourseAdvantageIcon>
                    <IconsSwitch
                      className='icon'
                      icon={courseAdvantage.icon.name}
                      size='MD'
                      color={orange.extra}
                      customPath={courseAdvantage.icon.customPath}
                      lib='interco'
                    />
                  </S.CourseAdvantageIcon>
                  <S.CourseAdvantageDescription>
                    {courseAdvantage.description}
                  </S.CourseAdvantageDescription>
                </S.CourseAdvantage>
              ))}
            </S.CourseAdvantagesList>
          </S.TextsContent>
        </S.Row>
      </div>
    </S.Section2OportunidadeParaComprarCripto>
  )
}

export default S2OportunidadeParaComprarCripto
