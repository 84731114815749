import React from 'react'
import { Section } from './styles'

export const InvestimentosEmpresariais = () => {
  return (
    <Section
      className='py-5 py-md-0 d-flex align-items-end align-items-md-center justify-content-between'
      role='img'
      aria-label='Conta Digital PJ Enterprise no Inter”'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-end'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-28 fs-lg-40 lh-lg-44 fw-500 mb-3'>
              Investimentos Empresariais sem custos na melhor corretora digital do Brasil
            </h2>
            <p className='fs-14 lh-19 fs-lg-16 lh-lg-19 fw-400'>
              Invista com facilidade e autonomia e, se precisar de auxílio nas decisões do seu negócio, pensamos seu planejamento junto com você.
            </p>
            <p className='fs-14 lh-19 fs-lg-16 lh-lg-19 fw-400'>
              Na conta Entreprise, as soluções de alocação de carteira são exclusivas, de acordo com o perfil da sua empresa e preparadas por profissionais qualificados.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InvestimentosEmpresariais
