import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Section, CTA } from './style'
import ArrowRight from 'inter-frontend-svgs/lib/orangeds/MD/arrow-right'

type HeroProps = {
  setIsModalOpen: (isOpen: boolean) => void;
};

export const Hero = ({ setIsModalOpen }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section>
      <video
        key='introVideo'
        height='auto'
        width='100%'
        loop
        muted
        autoPlay
        playsInline
        className='d-none d-md-block'
      >
        <source src='https://central-imagens.bancointer.com.br/gif/inter-pag-video-home/arquivo.webm' />
      </video>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-xl-5 d-flex flex-column justify-content-center align-items-start pt-xl-4'>
            <h1 className='title'>
              Inter Pag
            </h1>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-60 text-white mb-0' style={{ fontFamily: 'Inter' }}>
              A maquininha de cartão que revoluciona suas vendas.
            </h1>
            <CTA
              onClick={() => {
                  setIsModalOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Inter Pag, a maquininha de cartão que revoluciona suas vendas.',
                    element_action: 'click button',
                    element_name: 'Solicitar agora',
                  })
              }}
            >
              Solicitar agora
              <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
            </CTA>
          </div>
        </div>
      </div>
    </Section>
  )
}
