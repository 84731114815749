import React from 'react'

import * as S from './_styles'
import { Button } from '@interco/inter-ui/components/Button'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import carouselCards from './carouselCards.json'
import CardWithHoverEffect, { CardWithHoverEffectProps } from './components/cardWithHoverEffect/_index'
import { orange } from 'src/styles/colors'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const S3AMelhorMiniserieGratuita = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.Section3AMelhorWebserieGratuita
      id='a-melhor-webserie-gratuita-sobre-criptomoedas'
    >
      <div className='container'>
        <S.Row className='row'>
          <S.Texts className='col-12 col-md-6 col-lg-5'>
            <S.Title>
              A melhor websérie gratuita sobre criptomoedas
            </S.Title>
            <S.Description>
              Descubra como comprar criptomoedas e aproveitar as melhores oportunidades do mercado.
            </S.Description>
            <Button
              fullWidth
              as='a'
              href='#aprenda-tudo-sobre-criptomoedas'
              onClick={() => {
                sendDataLayer({
                  section: 'dobra_03',
                  section_name: 'A melhor websérie gratuita sobre criptomoedas',
                  element_action: 'click button',
                  element_name: 'Inscreva-se agora',
                  redirect_url: '#aprenda-tudo-sobre-criptomoedas',
                })
              }}
            >
              Inscreva-se agora
            </Button>
          </S.Texts>
          <S.Carousel className='col-12 col-md-6 col-lg-7 col-xl-6'>
            <DefaultCarousel
              sm={{ items: 2, partialVisibilityGutter: 0 }}
              md={{ items: 2, partialVisibilityGutter: 30 }}
              lg={{ items: 3, partialVisibilityGutter: 30 }}
              xl={{ items: 3, partialVisibilityGutter: 30 }}
              customDotColor={orange[500]}
              containerClass='carousel-container'
            >
              {carouselCards.map((card: CardWithHoverEffectProps) => (
                <CardWithHoverEffect
                  key={card.module}
                  module={card.module}
                  title={card.title}
                  description={card.description}
                  backgroundImage={card.backgroundImage}
                />
              ))}
            </DefaultCarousel>
          </S.Carousel>
        </S.Row>
      </div>
    </S.Section3AMelhorWebserieGratuita>
  )
}

export default S3AMelhorMiniserieGratuita
