import { breakpoints } from 'src/styles/breakpoints'
import { brand, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background: #3C3331;

  h2, p, h3 {
    font-family: 'Citrina' !important;
  }

  img {
    width: 100%;
  }

`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: ${brand.primary};
  color: ${white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  margin-top: 32px;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 377px;
  }

`
