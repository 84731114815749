import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section7Faq = styled.section`
  padding: 64px 0;
  
  @media ${device.desktopXXL} {
    padding: 96px 0;
  }

  a {
    text-decoration: underline;
  }
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, arial;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: ${grayscale[500]};

  margin-bottom: 32px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }

`
