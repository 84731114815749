import { Link } from 'gatsby'
import React, { useState } from 'react'
import BlogArticles from 'src/components/BlogArticles'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import FAQ from 'src/components/Faq'
import ImageWebp from 'src/components/ImageWebp'
import { FixedIncome } from 'src/components/InvestmentSimulators'
import InvestmentsTab from 'src/components/InvestmentsTab'
import { Modal } from 'src/components/Modal'
import Layout from 'src/layouts/BaseLayout'

import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import Home from '@interco/icons/build-v4/orangeds/MD/home'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'
import headerJson from './assets/data/header.json'
import QrCode from './assets/images/qr_tesouro.png'

import pageContext from './pageContext.json'

import { Box, FAQSection, LinkButton, Section } from './style'

const { qrCodeContaKidsAberturaDeContasInvestimentos }: { qrCodeContaKidsAberturaDeContasInvestimentos: { deeplink: string; qrcode: string } } = qrcodeInvestimentsData

function RendaFixaTesouroDireto () {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const width = useWidth(300)

  const dataLayerQR: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Tesouro Direto',
    element_action: 'click button',
    element_name: 'Quero conhecer',
    element_previous: 'Comece a investir agora',
  }

  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const OpenAccountModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={QrCode}
        dataLayer={dataLayerQR}
        agoraBaixeOAppLink='https://inter-co.onelink.me/Qyu7/q2ekb17g'
        deepLink='https://inter-co.onelink.me/Qyu7/gzu5o6rr'
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {OpenAccountModal}
      <Section id='Tesouro Direto'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-5 order-md-last text-center mb-4 mb-md-0'>
              {
                 width < WIDTH_MD
                  ? <ImageWebp
                      pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/image-tesouro-direto-mobile-small-lg/image.webp'
                      altDescription='Imagem da tela do app Inter, na sessão Tesouro Direto'
                      arrayNumbers={[ 204.66, 220.52, 298.26, 377.58, 503.71 ]}
                      arrayNumbersHeight={[ 258, 278, 376, 476, 635 ]}
                    />
                  : <ImageWebp
                      pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/imagimage-tesouro-direto-desktop/image.webp'
                      altDescription='Imagem da tela do app Inter, na sessão Tesouro Direto'
                      arrayNumbers={[ 204.66, 220.52, 298.26, 377.58, 503.71 ]}
                      arrayNumbersHeight={[ 258, 278, 376, 476, 635 ]}
                    />
              }
            </div>
            <div className='col-12 col-md-7'>
              <div className='mb-4 pt-2'>
                <Link to='/'><Home height={24} width={24} color='#6A6E81' />
                </Link>
                <OrangeIcon className='mx-2' icon='arrow-right' color='#161616' size='SM' />
                <Link
                  to='/pra-voce/investimentos/'
                  className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 mr-2'
                >
                  {headerJson.breadcrumb[0]}
                </Link>
                <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                <Link
                  to='/pra-voce/investimentos/renda-fixa/'
                  className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 ml-2 mr-2'
                >
                  {headerJson.breadcrumb[1]}
                </Link>
                <OrangeIcon icon='arrow-right' color='#161616' size='SM' className='ml-2' />
                <span className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 ml-2'>{headerJson.breadcrumb[2]}</span>
              </div>
              <div className='col-md-10 col-lg-12 col-xl-11 pl-md-0'>
                <h1>
                  Tesouro Direto
                </h1>
                <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
                  A partir de R$ 2,00, você pode investir em títulos públicos do Tesouro Direto e garantir um futuro financeiro seguro e rentável.
                </p>
                {
                  width < WIDTH_MD
                  ? (
                    <LinkButton
                      title='Quero conhecer'
                      href='https://inter-co.onelink.me/Qyu7/gzu5o6rr'
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          section_name: 'Tesouro Direto',
                          element_action: 'click button',
                          element_name: 'Quero conhecer',
                          redirect_url: 'https://inter-co.onelink.me/Qyu7/gzu5o6rr',
                        })
                      }}
                    > Quero conhecer
                    </LinkButton>
                  )
                  : (
                    <LinkButton
                      title='Quero conhecer'
                      onClick={() => {
                        setIsModalOpen(true)
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          section_name: 'Tesouro Direto',
                          element_action: 'click button',
                          element_name: 'Quero conhecer',
                        })
                      }}
                    >
                      Quero conhecer
                    </LinkButton>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section id='outros-investimentos' className='py-5'>
        <div className='container pb-lg-3'>
          <h2 className='fs-28 fs-md-42 mb-4 mt-2 text-grayscale--500 text-md-center font-sora'>
            Segurança para investir a longo prazo
          </h2>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 px-0'>
              <p className='fs-16 text-md-center text-grayscale--400'>
                Confira a simulação e veja como seu dinheiro pode render.
              </p>
            </div>
          </div>
          <div className='pt-4 d-md-none d-lg-none'>
            <h3 className='fs-17 text-grayscale--500 font-sora'>
              Veja um exemplo de como o seu dinheiro pode render.
            </h3>
          </div>
          <Box>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-4'>
                <div className='box-content mt-0 mt-lg-5 mb-4'>
                  <p className='fw-700 mb-1 text-grayscale--500 font-sora'>
                    Considerações utilizadas nos exemplos:
                  </p>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>Títulos Isentos de IR (LCI DI e Poupança) estão com taxas Gross-Up (consideramos a taxa como se o investimento fosse sujeito a IR, como um CDB). Assim, todos os valores demonstrados são brutos.</li>
                    <li>A expectativa é de que a <a href='https://blog.inter.co/taxa-selic-hoje/'>taxa Selic</a> oscile durante o período, do contrário, a rentabilidade poderá ser afetada.</li>
                    <li>Considera-se que o título do Tesouro Direto é a LFT (Tesouro Selic) e que sua rentabilidade é de 100% do CDI.</li>
                  </ul>
                </div>
                <div>
                  {
                    width >= 768 ? (
                      <button
                        title='Comece a investir agora'
                        className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
                        onClick={() => {
                        setIsModalOpen(true)
                          sendDatalayerEvent({
                            section: 'dobra_02',
                            section_name: 'Segurança para investir a longo prazo',
                            element_action: 'click button',
                            element_name: 'Comece a investir agora',
                          })
                        }}
                      >
                        Comece a investir agora
                      </button>
                    ) : (
                      <a
                        style={{ paddingTop: 13 }}
                        title='Quero conhecer'
                        className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-block d-md-none'
                        href='https://inter-co.onelink.me/Qyu7/gzu5o6rr'
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_01',
                            section_name: 'Investimentos que simplificam sua vida',
                            element_action: 'click button',
                            element_name: 'Quero conhecer',
                            redirect_url: 'https://inter-co.onelink.me/Qyu7/gzu5o6rr',
                          })
                        }}
                      > Comece a investir agora
                      </a>
                    )
                  }
                </div>
              </div>
              <div className='col-12 col-lg-8 px-0'>
                <FixedIncome products='tesouro,poupanca' />
              </div>
            </div>
          </Box>
        </div>
      </section>

      <section id='veja-outros-investimentos'>
        <div className='container pt-4 pb-md-5'>
          <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 font-sora mb-2 mb-md-4'>
            Veja outros tipos de investimentos
          </h2>
          <InvestmentsTab
            section='dobra_3'
            elementAction='click button'
            section_name='Veja outros tipos de investimentpos'
          />
        </div>
      </section>

      <section id='novidades-investimentos' className='py-md-4'>
        <div className='container'>
          <div className='col-12 mb-4'>
            <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center text-grayscale--500 font-sora mb-4'>
              Os melhores conteúdos sobre investimentos
            </h2>
            <p className='text-left mt-1 text-lg-center text-grayscale--400 mb-0'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <BlogArticles
            type='investments'
            section='dobra_4'
            elementAction='click button'
            elementName='Saiba mais'
            sectionName='Os melhores conteúdos sobre investimentos'
          />
        </div>
      </section>

      <FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-4 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h2 className='fs-28 fs-md-42 mb-4 text-md-center text-grayscale--500 font-sora'>
                Perguntas frequentes sobre o Tesouro Direto
              </h2>
              <p className='fs-16 text-md-center text-grayscale--400'>
                Tire suas dúvidas sobre o investimento no Tesouro Direto, que oferece diversas opções
                de títulos com diferentes prazos e rentabilidades.
              </p>
            </div>
            <FAQ
              className='summary-content'
              answerData={pageContext.structuredData.faq}
            />
          </div>
        </div>
      </FAQSection>

      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_06'
      />
    </Layout>
  )
}

export default RendaFixaTesouroDireto
