import React from 'react'
import ContatoConsultoresField from 'src/components/UI/Forms/ContatoConsultoresField'

import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG } from 'src/styles/breakpoints'

import * as S from './styles'

const ImageConsignadoFildXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/parceiros-fiel-1440/image.webp'

const HeroConsultoresField: React.FC = () => {
  const width = useWidth()

  return (
    <S.SectionConsignadoFild id='form-consignado'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-5 col-xl-7'>
            <S.TitleConsignadoFild>Empréstimo <strong>Consignado Inter</strong></S.TitleConsignadoFild>
            <S.DescriptionConsignadoFild>
              Crédito exclusivo para servidores públicos, aposentados e pensionistas do INSS. Simples, seguro e com parcelas
              a partir de R$24,70.
            </S.DescriptionConsignadoFild>
            {
              width >= WIDTH_LG &&
                <ImageWebp
                  arrayNumbers={[ 312, 366, 373, 645, 645 ]}
                  arrayNumbersHeight={[ 312, 366, 424.55, 773, 773 ]}
                  pathSrc={ImageConsignadoFildXL}
                  altDescription='Imagem representando um empréstimo consignado Inter'
                />
            }
          </div>
          <div className='col-12 col-lg-6 offset-lg-1 col-xl-5 offset-xl-0'>
            <ContatoConsultoresField
              section='dobra_01'
              sectionName='Empréstimo Consignado Inter'
            />
          </div>
        </div>
      </div>
    </S.SectionConsignadoFild>
  )
}

export default HeroConsultoresField
