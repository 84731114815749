import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { laranja } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 319px;
  display: flex;
  align-items: center;
  background-color: #FFF;

  h2 {
    margin-bottom: 16px;
    color: ${laranja.terra};
    font-weight: 500;
  }

  p {
    color: ${laranja.terra};
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 210px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 359px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 473px;
  }
`
