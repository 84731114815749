import { breakpoints } from 'src/styles/breakpoints'
import { brand, grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

const imageBg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-hero/image.webp'
const imageBgMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-hero-768/image.webp'
const imageBgLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-hero-1024/image.webp'

export const Section = styled.section`
  background: ${brand.primary};

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${imageBgMd});
    background-repeat: no-repeat;
    background-position:  left;
    min-height: 369px;
  }
  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${imageBgLg});
    background-repeat: no-repeat;
    background-position: left;
    min-height: 389px;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${imageBg});
    background-repeat: no-repeat;
    background-position: left;
    min-height: 609px;
  }
`

export const Button = styled.span`
  color: ${brand.primary};
  background-color: ${white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  border: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  margin-top: 24px;

  &:hover {
    color: ${grayscale[400]};
  }
`
