import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

import * as S from './style'

type ModalProps = {
  setIsModalOpen: Function;
  setDataLayer: (state: IDataLayerParams) => void;
}

const AbraSuaConta = ({ setIsModalOpen, setDataLayer }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth(300)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Ainda não possui conta no Inter?',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  }

  return (
    <S.SectionWrapper
      className='py-4 d-flex align-items-center'
      role='img'
      aria-label='Uma pessoa segurando em uma das mãos o celular com super app Inter e na outra o cartão Inter'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-end'>
          {
            width < widths.md && (
              <div className='col-12 col-md-6 col-xl-5'>
                <ImageWebp
                  altDescription='Pessoa segurando em uma das mãos o celular com super app Inter e na outra o cartão Inter'
                  arrayNumbers={[]}
                  arrayNumbersHeight={[]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra7-360/image.webp'
                />
              </div>
            )
          }
          <div className='col-12 mt-4 col-md-6 col-xl-5 mt-mt-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-white fw-500'>
              Ainda não possui conta no Inter?
            </h2>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-white fw-400 my-3'>
              Abra a sua conta gratuita e tenha muito mais vantagens na hora de contratar o seu empréstimo.
            </p>
            <S.Button
              title='Abra sua conta'
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >
              Abra sua conta
            </S.Button>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default AbraSuaConta
