import { TextContent } from "./_types"
import { InvestmentType } from "./hooks/_useInvestmentData"

export const TEXT_CONTENT: TextContent = {
  heading: 'Escolha o melhor caminho para o Tesouro',
  cta: 'Ver todos',
  bannerTitle: 'Vantagens dos títulos <br/>públicos',
  bannerBody: 'Considerado o mais seguro do Brasil, oferece diversas opções de títulos, aportes mínimos e liquidez diária.',
  menuOptions: [
    {
      icon: 'finance/ic_piggy_bank',
      title: 'Usar para uma emergência',
      fileToLoad: InvestmentType.tesouroSelic2027,
    },
    {
      icon: 'travel-mobility/ic_plane_departure',
      title: 'Transformar o sonho em realidade',
      fileToLoad: InvestmentType.tesouroIpca2029,
    },
    {
      icon: 'finance/ic_house_check',
      title: 'Guardar para a faculdade dos filhos',
      fileToLoad: InvestmentType.tesouroEduca2025,
    },
    {
      icon: 'finance/ic_wallet_money',
      title: 'Fazer um pé de meia',
      fileToLoad: InvestmentType.tesouroRenda2045,
    },
  ],
}

export const FLUXO_TAG = 'CONSTRUATESOURO_FLUXO'
export const ACESSO_TAG = 'CONSTRUATESOURO_ACESSO'
export const PRODUCT_NAME = 'tesouro'
export const termsUrl = 'https://image.comunicacao.bancointer.com.br/lib/fe2a117170640475761c77/m/1/RegulamentoTesouroDiretoaoPonto12092024.pdf'
