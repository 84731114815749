import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Section, Button } from './style'
import ImageWebp from 'src/components/ImageWebp'

export const MobileInterPag = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center justify-content-md-end'>
          <div className='col-12 col-md-6 mb-4'>
            <div className='image'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-dobra4-web/image.webp'
                altDescription=''
                arrayNumbers={[ 350, 300, 300, 300, 300 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <h2>Maquininha no celular</h2>
            <h3>Tecnologia e agilidade pro seu negócio</h3>

            <p className='fs-16 lh-22 fs-md-12 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
              Com a função Tap on Phone, você transforma gratuitamente seu celular numa maquininha de cartão e vende com muita mobilidade e agilidade.
            </p>
            <Button
              href='https://conta-digital-pj.inter.co/login?_gl=1*1re4yba*_gcl_au*MTM2Njc5OTM4MS4xNzMzNDA0MzMy'
              title='Abrir Conta Inter Empresas'
              onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Maquininha no celular',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Inter Empresas',
                  })
              }}
            >
              Abrir Conta Inter Empresas
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}
