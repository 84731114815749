export enum FormFields {
  name = 'name',
  email = 'email',
  cpf = 'cpf',
  celular = 'celular',
}

export interface IFormValues {
  name: FormFields.name;
  cpf: FormFields.cpf;
  celular: FormFields.celular;
  email: FormFields.email;
}

export type UtmStateType = string | string[] | null | undefined
