import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { Section } from './style'

const OpenFinancy = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section className='bg-gray py-5 d-flex align-items-center' role='img' aria-label='Um homem, sentado em um sofá enquanto acessa sua conta do Inter.'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 offset-md-6 offset-xl-7'>
            <div className='d-flex justify-content-center mt-3'>
              {width < WIDTH_MD && <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra11-open/image.webp'
                altDescription='Um homem, sentado em um sofá enquanto acessa sua conta do Inter.'
                arrayNumbers={[ 300, 300, 300, 300 ]}
                arrayNumbersHeight={[ 150, 150, 150, 150 ]}
                                   />}
            </div>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 mt-3 fw-500'>
              Open Finance: serviços bancários interconectados
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400 mt-md-3'>
              Com o Open Finance, você decide o que é melhor para você. Seus dados e serviços bancários são compartilhados de forma padronizada entre instituições autorizadas pelo Banco Central.
            </p>
            <Link
              title='Quero saber mais'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to='/open-finance/'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_11',
                  element_action: 'click button',
                  element_name: 'Quero saber mais',
                  section_name: 'Open Finance: serviços bancários interconectados',
                  redirect_url: `${window.location.origin}/open-finance/`,
                })
              }}
            >
              Quero saber mais
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OpenFinancy
