import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  a {
    @media ${device.tablet} {
      min-width: 337px;
    }

    @media ${device.desktopLG} {
      min-width: 351px;
    }

    @media ${device.desktopXL} {
      min-width: 457px;
    }
  }
`

export const Button = styled.div`
  border: 1px solid #FFFFFF;
  background-color: ${orange.extra};

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }

  float: left;
  clear: both;

  @media ${device.tablet} {
    width: 336px;
  }

  @media ${device.desktopLG} {
    width: 398px;
  }
`

export const LinkButton = styled.a`
  border: 1px solid #FFFFFF;
  background-color: ${orange.extra};
  width:  100%;
  height: 48px;

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }

  float: left;
  clear: both;

  @media ${device.tablet} {
    width: 336px;
  }

  @media ${device.desktopLG} {
    width: 398px;
  }



`
