import React from 'react'
import ScrollTo from 'src/components/ScrollTo'

import { Button, Section } from './style'

const Hero = () => {
  return (
    <Section
      role='img'
      className='py-5 d-flex align-items-center justify-content-end'
      id='hero-credito'
      aria-label='Homem de camisa laranja e blazer azul usando o celular'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-end'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-28 lh-33 fs-lg-40 lh-lg-44 fs-xl-56 lh-xl-61 text-white fw-500 mb-4'>
              Crédito para o que você precisar
            </h1>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-21 text-white fw-400 mb-4'>
              Encontre a melhor opção de crédito para pagar suas dívidas, reformar sua casa, comprar um imóvel, financiar
              um veículo e até para investir no seu próprio negócio. No Inter, você encontra o crédito que você precisa.
            </p>
            <ScrollTo
              section='dobra_01'
              title='Veja a melhor opção para você'
              sectionName='Crédito para o que você precisar'
              elementName='Veja a melhor opção para você'
              to='#veja-nossas-opcoes'
            >
              <Button>Veja a melhor opção para você</Button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
