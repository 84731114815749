import React from 'react'
import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'

const OQueETaxaBonificada = () => {
  const data = usePageQuery()

  return (
    <div className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-3 mb-md-0'>
            <Img fluid={data.oQueETaxaBonificada.fluid} alt='' />
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500'>O que é a Taxa Bonificada do Inter?</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400'>A Taxa Bonificada é um benefício exclusivo para clientes que contrataram financiamento imobiliário no Inter. Esta taxa oferece condições especiais, com juros reduzidos em comparação com as taxas convencionais do mercado.<br /><br />Oferecemos um desconto de 0,3 p.p. aplicado na taxa anual sem bonificação. Ele é aplicado em ocasiões em que a <strong>taxa bonificada está pré-estabelecida no contrato de crédito imobiliário atrelado à TR </strong>(financiamento imobiliário, portabilidade de financiamento e cessão de crédito imobiliário).<br /><br />Caso seu contrato de crédito imobiliário <strong>já possua a taxa bonificada pré-estabelecida,</strong> mas você ainda não cumpre com as condições de relacionamento, não se preocupe. Você terá três meses para se adequar aos requisitos e continuar aproveitando a sua taxa com desconto.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OQueETaxaBonificada
