import React from 'react'

import AppStorePT from 'inter-frontend-svgs/lib/v2/social/store/app-store'
import PlayStorePT from 'inter-frontend-svgs/lib/v2/social/store/google-play'
import { translate } from 'react-i18next'
import useWidth from 'src/hooks/window/useWidth'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'

type FooterProps = {
  t: (values: string) => string;
}

const DisplayStore = ({ t }: FooterProps) => {
  const [ sendDatalayerFooterEvent ] = useDataLayer()

  const windowWidth = useWidth(360)
  const WIDTH_XS = 359
  const size = windowWidth <= WIDTH_XS ? '130' : '150'

  const AppStoreUrl = 'https://itunes.apple.com/br/app/banco-inter/id839711154'
  const GooglePlayUrl = 'https://play.google.com/store/apps/details?id=br.com.intermedium'

  return (
    <>
      <span className='title-font d-block fs-16 fw-700 mb-3'>{t('get_app')}</span>
      <div className='d-flex d-lg-block justify-content-start flex-lg-column pb-4 pb-md-0'>
        <div className='col-auto col-lg-12 mb-2 mr-1 pl-0'>
          <a
            onClick={() => {
              sendDatalayerFooterEvent({
                section: t('get_app') as string,
                element_action: 'click button',
                element_name: 'App Store',
                redirect_url: AppStoreUrl,
                c_page: window.location.href,
              })
            }}
            href={AppStoreUrl}
            target='_blank' rel='noreferrer' title='Baixe na App Store' aria-label='App Store'
            data-footer='download-app-store'
          >
            <AppStorePT width={size} height='45' />
          </a>
        </div>
        <div className='col-auto col-lg-12 mb-2 pl-0'>
          <a
            onClick={() => {
              sendDatalayerFooterEvent({
                section: t('get_app'),
                element_action: 'click button',
                element_name: 'Google Play',
                redirect_url: GooglePlayUrl,
                c_page: window.location.href,
              })
            }}
            href={GooglePlayUrl}
            target='_blank' rel='noreferrer'
            title='Baixe no Google Play' aria-label='Google Play' data-footer='download-google-play'
          >
            <PlayStorePT width={size} height='45' />
          </a>
        </div>
      </div>
    </>
  )
}

export default translate('Footer')(DisplayStore)
