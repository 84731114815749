import React, { useState } from 'react'

// Hooks
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { widths } from 'src/styles/breakpoints'

// Components
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import { Modal } from 'src/components/Modal'
import ImageWebp from 'src/components/ImageWebp'
import { Section, Button, LinkButton } from './style'
import { SuperAppInterProps } from '../../types'

const Save = ({ setOpenModal }: SuperAppInterProp) => {
  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Conta Digital. Gratuita, simples e completa',
    element_name: 'Abra sua conta',
    element_action: 'click button',
  })

  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const [ open, setOpen ] = useState(false)
  const domReady = useDomReady()

  const openModal = domReady && (
    <Modal isModalOpen={open} setIsModalOpen={setOpen} locationToRender={document.body}>
      <ModalAccount
        closeModal={() => setOpen(false)}
      />
    </Modal>
  )

  return (
    <Section className='py-5'>
      {openModal}
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 order-md-last d-flex justify-content-center mb-3'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-lojas/image.webp'
              altDescription='homem jovem segurando o celular com as duas mãos e marca de lojas ao lado, como centauro, casas bahia, amazon e mais'
              arrayNumbers={[ 288, 336, 448, 572 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 order-md-last'>
            <h2 className='fs-24 fs-lg-40 fs-xl-48 lh-28 lh-lg-48 lh-xl-56 text-grayscale--500 fw-500'>
              <span className='d-md-block d-lg-inline'><span className='text-primary--500'>Economize</span> em mais de</span> 600 lojas e marcas
            </h2>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 mt-3 text-graphite'>
              No Super App do Inter você compra nas melhores lojas e ganha cashback direto na sua conta. Aproveite as promoções da
              <span className='fw-700'>Semana do Consumidor</span> pra zerar sua lista de desejos 🧡
            </p>
            {
              width < widths.md ? (
                <LinkButton
                  href='https://inter-co.onelink.me/Qyu7/5p8n7moc'
                  className='btn btn--lg rounded-2 fs-14 fw-600 text-none text-white mt-3 mb-0 mw-100'
                  target='_blank'
                >Acessar Super App
                </LinkButton>
              ) : (
                <Button
                  className='btn btn--lg rounded-2 fs-14 fw-600 text-none text-white mt-3 mb-0 mw-100'
                  onClick={() => {
                  setOpenModal && setOpenModal(true)
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Semana do Consumidor 2024',
                    element_name: 'Acessar Super App',
                    element_action: 'click button',
                  })
                  } }
                >
                  Acessar Super App
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Save
