import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import { Section } from './style'

const CashBack = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section className='py-5 d-flex align-items-center' role='img' aria-label=''>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-6'>
            <div className='bg rounded-4 px-3 px-lg-4 py-4 py-lg-5'>
              {width < WIDTH_MD &&
                <div className='d-flex justify-content-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra9-web-marketplace/image.webp'
                    altDescription='Uma mulher sentada em um sofá, falando ao celular'
                    arrayNumbers={[ 300, 300, 300, 300 ]}
                  />
                </div>}
              <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mt-3 mt-md-0'>
                Ganhe cashback na compra de passagens e hospedagens
              </h2>
              <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400 mt-3'>
                No Super App tem tudo pra você garantir sua próxima viagem com a economia de receber parte do valor pago de volta.
              </p>
              <Link
                title='Quero viajar'
                className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-3 mt-md-2'
                to='https://inter-co.onelink.me/Qyu7/t06toxte'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    element_action: 'click button',
                    element_name: 'Quero viajar',
                    section_name: 'Ganhe cashback na compra de passagens e hospedagens',
                    redirect_url: 'https://inter-co.onelink.me/Qyu7/t06toxte',
                  })
                }}
              >
                Quero viajar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CashBack
