const publico = [
  {
    value: "AERONÁUTICA",
    label: "AERONÁUTICA",
  },
  {
    value: "EXÉRCITO",
    label: "EXÉRCITO",
  },
  {
    value: "MARINHA",
    label: "MARINHA",
  },
  {
    value: "INSS",
    label: "INSS",
  },
  {
    value: "SIAPE",
    label: "SIAPE",
  },
  {
    value: "DEFENSORIA PÚBLICA DO ESTADO DO RJ",
    label: "DEFENSORIA PÚBLICA DO ESTADO DO RJ",
  },
  {
    value: "FMS - FUNDAÇÃO MUNICIPAL DE SAÚDE DE NITERÓI",
    label: "FMS - FUNDAÇÃO MUNICIPAL DE SAÚDE DE NITERÓI",
  },
  {
    value: "GOVERNO DA BAHIA",
    label: "GOVERNO DA BAHIA",
  },
  {
    value: "GOVERNO DE GOIÁS",
    label: "GOVERNO DE GOIÁS",
  },
  {
    value: "GOVERNO DE MATO GROSSO DO SUL",
    label: "GOVERNO DE MATO GROSSO DO SUL",
  },
  {
    value: "GOVERNO DE MINAS GERAIS",
    label: "GOVERNO DE MINAS GERAIS",
  },
  {
    value: "GOVERNO DE SANTA CATARINA",
    label: "GOVERNO DE SANTA CATARINA",
  },
  {
    value: "GOVERNO DE SÃO PAULO",
    label: "GOVERNO DE SÃO PAULO",
  },
  {
    value: "GOVERNO DO ESPÍRITO SANTO",
    label: "GOVERNO DO ESPÍRITO SANTO",
  },
  {
    value: "GOVERNO DO MATO GROSSO",
    label: "GOVERNO DO MATO GROSSO",
  },
  {
    value: "GOVERNO DO RIO DE JANEIRO",
    label: "GOVERNO DO RIO DE JANEIRO",
  },
  {
    value: "HOSPITAL ODILON BERHENS",
    label: "HOSPITAL ODILON BERHENS",
  },
  {
    value: "IMPCG - Instituto Municipal de Previdência de Campo Grande",
    label: "IMPCG - INSTITUTO MUNICIPAL DE PREVIDÊNCIA DE CAMPO GRANDE",
  },

  {
    value: "IPAMV - INSTITUTO DE PREVIDÊNCIA PREF. VITÓRIA",
    label: "IPAMV - INSTITUTO DE PREVIDÊNCIA PREF. VITÓRIA",
  },
  {
    value: "IPM - INSTITUTO DE PREVIDÊNCIA PREF. RIBEIRÃO PRETO",
    label: "IPM - INSTITUTO DE PREVIDÊNCIA PREF. RIBEIRÃO PRETO",
  },
  {
    value: "IPREMB - Instituto de Previdência Social do Município de Betim",
    label: "IPREMB - Instituto de Previdência Social do Município de Betim",
  },
  {
    value: "IPVV - INSTITUTO DE PREVIDÊNCIA PREF. VILA VELHA",
    label: "IPVV - INSTITUTO DE PREVIDÊNCIA PREF. VILA VELHA",
  },
  {
    value: "ISSA - INSTITUTO DE SEGURIDADE SOCIAL DOS SERVIDORES MUNICIPAIS DE ANÁPOLIS",
    label: "ISSA - INSTITUTO DE SEGURIDADE SOCIAL DOS SERVIDORES MUNICIPAIS DE ANÁPOLIS",
  },
  {
    value: "JUSTIÇA FEDERAL MG",
    label: "JUSTIÇA FEDERAL MG",
  },
  {
    value: "METRÔ SP",
    label: "METRÔ SP",
  },
  {
    value: "MINISTÉRIO PUBLICO MG",
    label: "MINISTÉRIO PUBLICO MG",
  },
  {
    value: "NITERÓI PREV.",
    label: "NITERÓI PREV.",
  },
  {
    value: "PREFEITURA DE ANÁPOLIS",
    label: "PREFEITURA DE ANÁPOLIS",
  },
  {
    value: "PREFEITURA DE BELO HORIZONTE",
    label: "PREFEITURA DE BELO HORIZONTE",
  },
  {
    value: "PREFEITURA DE BETIM",
    label: "PREFEITURA DE BETIM",
  },
  {
    value: "PREFEITURA DE CAMPO GRANDE",
    label: "PREFEITURA DE CAMPO GRANDE",
  },
  {
    value: "PREFEITURA DE CONTAGEM",
    label: "PREFEITURA DE CONTAGEM",
  },
  {
    value: "PREFEITURA DE FLORIANÓPOLIS",
    label: "PREFEITURA DE FLORIANÓPOLIS",
  },
  {
    value: "PREFEITURA DE GOIANIA",
    label: "PREFEITURA DE GOIANIA",
  },
  {
    value: "PREFEITURA DE GUARULHOS",
    label: "PREFEITURA DE GUARULHOS",
  },
  {
    value: "PREFEITURA DE NITERÓI",
    label: "PREFEITURA DE NITERÓI",
  },
  {
    value: "PREFEITURA DE PRAIA GRANDE",
    label: "PREFEITURA DE PRAIA GRANDE",
  },
  {
    value: "PREFEITURA DE RIBEIRÃO PRETO",
    label: "PREFEITURA DE RIBEIRÃO PRETO",
  },
  {
    value: "PREFEITURA DE SALVADOR",
    label: "PREFEITURA DE SALVADOR",
  },
  {
    value: "PREFEITURA DE SANTOS",
    label: "PREFEITURA DE SANTOS",
  },
  {
    value: "PREFEITURA DE SÃO PAULO",
    label: "PREFEITURA DE SÃO PAULO",
  },
  {
    value: "PREFEITURA DE SOROCABA",
    label: "PREFEITURA DE SOROCABA",
  },
  {
    value: "PREFEITURA DE VALPARAÍSO DE GOIÁS",
    label: "PREFEITURA DE VALPARAÍSO DE GOIÁS",
  },
  {
    value: "PREFEITURA DE VILA VELHA",
    label: "PREFEITURA DE VILA VELHA",
  },
  {
    value: "PREFEITURA DE VITÓRIA",
    label: "PREFEITURA DE VITÓRIA",
  },
  {
    value: "SJMG - SEÇÃO JUDICIÁRIA DE MINAS GERAIS",
    label: "SJMG - SEÇÃO JUDICIÁRIA DE MINAS GERAIS",
  },
  {
    value: "SUPREMO TRIBUNAL DE JUSTIÇA",
    label: "SUPREMO TRIBUNAL DE JUSTIÇA",
  },
  {
    value: "SUPREMO TRIBUNAL FEDERAL",
    label: "SUPREMO TRIBUNAL FEDERAL",
  },
  {
    value: "TRIBUNAL DE CONTAS DA UNIÃO - TCU",
    label: "TRIBUNAL DE CONTAS DA UNIÃO - TCU",
  },
  {
    value: "TRIBUNAL DE CONTAS DO ESTADO MG",
    label: "TRIBUNAL DE CONTAS DO ESTADO MG",
  },
  {
    value: "TRIBUNAL DE JUSTIÇA BA",
    label: "TRIBUNAL DE JUSTIÇA BA",
  },
  {
    value: "TRIBUNAL DE JUSTIÇA DF",
    label: "TRIBUNAL DE JUSTIÇA DF",
  },
  {
    value: "TRIBUNAL DE JUSTIÇA MG",
    label: "TRIBUNAL DE JUSTIÇA MG",
  },
  {
    value: "TRIBUNAL DE JUSTIÇA MT",
    label: "TRIBUNAL DE JUSTIÇA MT",
  },
  {
    value: "TRIBUNAL DE JUSTIÇA SP",
    label: "TRIBUNAL DE JUSTIÇA SP",
  },
  {
    value: "TRIBUNAL REGIONAL ELEITORAL MG",
    label: "TRIBUNAL REGIONAL ELEITORAL MG",
  },
  {
    value: "TRIBUNAL REGIONAL ELEITORAL SP",
    label: "TRIBUNAL REGIONAL ELEITORAL SP",
  },
  {
    value: "TRIBUNAL SUPERIOR DO TRABALHO",
    label: "TRIBUNAL SUPERIOR DO TRABALHO",
  },
  {
    value: "OUTROS",
    label: "OUTROS",
  },
]

const privado = [
  {
    value: "CPE - GRUPO MRV",
    label: "GRUPO MRV",
  },
  {
    value: "CPE - ZETRASOFT",
    label: "ZETRASOFT",
  },
  {
    value: "CPE - ASFUG",
    label: "ASFUG",
  },
  {
    value: "CPE - CONCERT TECNHOLIGIES",
    label: "CONCERT TECNHOLIGIES",
  },
  {
    value: "CPE - Copasa",
    label: "Copasa",
  },
  {
    value: "CPE - ELETRO TRANSOL",
    label: "ELETRO TRANSOL",
  },
  {
    value: "CPE - ELETROBRÁS ELETRONORTE",
    label: "ELETROBRÁS ELETRONORTE",
  },
  {
    value: "CPE - GRUPO ALCTEL",
    label: "GRUPO ALCTEL",
  },
  {
    value: "CPE - GRUPO ARIZONA",
    label: "GRUPO ARIZONA",
  },
  {
    value: "CPE - GRUPO COPELI",
    label: "GRUPO COPELI",
  },
  {
    value: "CPE - GRUPO FORNAC",
    label: "GRUPO FORNAC",
  },
  {
    value: "CPE - GRUPO INTER ADUANEIRA",
    label: "GRUPO INTER ADUANEIRA",
  },
  {
    value: "CPE - GRUPO MAGNUS",
    label: "GRUPO MAGNUS",
  },
  {
    value: "CPE - GRUPO PRIMUS",
    label: "GRUPO PRIMUS",
  },
  {
    value: "CPE - GRUPO TAMBASA",
    label: "GRUPO TAMBASA",
  },
  {
    value: "CPE - GRUPO TREVISO",
    label: "GRUPO TREVISO",
  },
  {
    value: "CPE - GRUPO WIZ",
    label: "GRUPO WIZ",
  },
  {
    value: "CPE - Grupo Virtual",
    label: "Grupo Virtual",
  },
  {
    value: "CPE - HANDLE",
    label: "HANDLE",
  },
  {
    value: "CPE - HOSPITAL DA CRIANÇA DE BRASÍLIA",
    label: "HOSPITAL DA CRIANÇA DE BRASÍLIA",
  },
  {
    value: "CPE - LABTEST DIAGNÓSTICO",
    label: "LABTEST DIAGNÓSTICO",
  },
  {
    value: "CPE - LATICINIOS OSCAR SALGADOS",
    label: "LATICINIOS OSCAR SALGADOS",
  },
  {
    value: "CPE - LIBERTY E FACIL ASSIST",
    label: "LIBERTY E FACIL ASSIST",
  },
  {
    value: "CPE - MASTERMAQ",
    label: "MASTERMAQ",
  },
  {
    value: "CPE - Patrol",
    label: "Patrol",
  },
  {
    value: "CPE - PIPE SISTEMA TUBULARES",
    label: "PIPE SISTEMA TUBULARES",
  },
  {
    value: "CPE - REDE BATISTA",
    label: "REDE BATISTA",
  },
  {
    value: "CPE - REDE CIDADÃ",
    label: "REDE CIDADÃ",
  },
  {
    value: "CPE - SÃO PAULO FC",
    label: "SÃO PAULO FC",
  },
  {
    value: "CPE - SEMPER",
    label: "SEMPER",
  },
  {
    value: "CPE - SQUADRA",
    label: "SQUADRA",
  },
  {
    value: "CPE - TRANSPES E NBH ESCOLTA DE VEÍCULOS",
    label: "TRANSPES E NBH ESCOLTA DE VEÍCULOS",
  },
  {
    value: "CPE - USP",
    label: "USP",
  },
  {
    value: "CPE - GRUPO PATRIMAR",
    label: "GRUPO PATRIMAR",
  },
  {
    value: "Horizonte Empreendimentos Imobiliários",
    label: "Horizonte Empreendimentos Imobiliários",
  },
  {
    value: "NOVUSMIDIACNNBRASIL",
    label: "NOVUS MIDIA S.A (CNN BRASIL)",
  },
  {
    value: "RÁDIO ITATIAIA",
    label: "RÁDIO ITATIAIA",
  },
]

export { privado, publico }
