import { device } from 'src/styles/breakpoints'
import { grayscale, red } from 'src/styles/colors'
import styled from 'styled-components'

export const SimulatorWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  
  @media ${device.desktopXXL} {
    margin: 0 0 0 auto;
  }
`

export const Simulator = styled.div`
  background-color: white;
  box-shadow: 0px 5.11px 5.11px 0px #00000040;
  
  padding: 16px;
  border: 1.23px solid ${grayscale[100]};
  border-radius: 24px;

  position: relative;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 238px;

  @media ${device.tablet} {
    width: 240px;
    padding: 17px 18px;
  }

  @media ${device.desktopLG} {
    width: 348px;
    padding: 24px;
  }

  @media ${device.desktopXXL} {
    padding: 32px 34px;
    width: 451px;
  }

  .simulate_app {
    margin-top: 12px; 
    padding: 10px 0;

    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;

    @media ${device.tablet} {
      font-size: 8px;
      line-height: 9px;

      padding: 7px 0;
    }

    @media ${device.desktopLG} {
      font-size: 10px;
      line-height: 12px ;

      padding: 10px 0;
      margin-top: 16px;
    }

    @media ${device.desktopXXL} {
      margin-top: 20px;
    }
  }

  .simulate_site {
    width: 100%;
    max-width: 100%;
    padding: 10px 0;

    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    height: auto !important;

    @media ${device.desktopLG} {
      font-size: 14px;
      line-height: 17px;
      padding: 16px 0;  
    }
  }

  .event-caller {
    width: 100%;
  }
`

export const SimulatorTitle = styled.h3`
  font-family: Sora, sans-serif, arial;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${grayscale[400]};

  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 10px;
  }

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 20px;
  }
`

export const SimulatorPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;

  border-bottom: 1.23px solid #DEDFE4;
  margin-bottom: 16px;
  padding-bottom: 2px;

  @media ${device.tablet} {
    margin-bottom: 12px;
  }

  @media ${device.desktopLG} {
    margin-bottom: 16px;
  }

  @media ${device.desktopXXL} {
    margin-bottom: 20px;
    padding-bottom: 8px;
  }
`

export const SimulatorPriceCurrency = styled.p`
  font-family: Sora, sans-serif, arial;
  font-weight: 400;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.07em;

  color: ${grayscale[500]};

  margin: 0 4px 0 0;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 19px;
  }

  @media ${device.desktopLG} {
    font-size: 34px;
    line-height: 36px;
    margin-right:8px;
  }
`

export const SimulatorPriceInput = styled.input`
  font-family: Sora, sans-serif, arial;
  font-weight: 400;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.07em;

  color: ${grayscale[400]};
  margin: 0;

  width: 3ch;
  max-width: 10ch;

  @media ${device.tablet} {
    font-size: 21px;
    line-height: 22px;
  }

  @media ${device.desktopLG} {
    font-size: 39px;
    line-height: 41px;
  }
`

export const SimulatorDecoration = styled.picture`
  position: absolute;
  user-select: none;
  z-index: 1;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -52%);

  width: 328px;
  height: 220px;

  @media ${device.tablet} {
    width: 324px;
    height: 204px;

    transform: translate(-50%, -50%);
  }

  @media ${device.desktopLG} {
    height: 305px;
    width: 476px;
  }

  @media ${device.desktopXXL} {
    height: 360px;
    width: 610px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`

export const Error = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${red[500]};

  position: absolute;
  bottom: -70%;
  
  width: max-content;
  margin: 0;

  @media ${device.tablet} {
    font-size: 10px;
    bottom: -60%;
  }

  @media ${device.desktopLG} {
    bottom: -35.2%;
  }

  @media ${device.desktopXXL} {
    bottom: -44%;
    font-size: 14px;
  }
`
