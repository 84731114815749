import React from 'react'
import * as S from './_styles'
import { TitleContainerRef } from '../_types'

const Title = ({ titleContainerRef }: TitleContainerRef) => (
  <S.TitleContainer
    className='col-12 col-lg-9'
    ref={titleContainerRef}
  >
    <S.Title>
      Olá, bem-vindo(a) a websérie <b>Cripto 360º</b>
    </S.Title>
    <S.Description>
      Dê um giro completo por esse universo e prepare-se para transformar conhecimento em ação! Explore os módulos e comece agora.
    </S.Description>
  </S.TitleContainer>
)

export default Title
