import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import { Section } from './style'

const PixOnInter = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section className='py-5 bg-gray d-flex align-items-center' role='img' aria-label='Um homem sorridente encostado em uma parede, enquanto segura seu smartphone.'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-7 offset-md-5 col-lg-6 offset-lg-6'>
            <div className='bg rounded-4 px-3 px-lg-4 py-4 py-lg-5'>
              {width < WIDTH_MD &&
                <div className='d-flex justify-content-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-1-a/image.webp'
                    altDescription='um homem sorridente encostado em uma parede, enquanto segura seu smartphone.'
                    arrayNumbers={[ 300, 300, 300, 300 ]}
                  />
                </div>}
              <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mt-3'>
                <span className='d-xl-block'>Pix no Inter:</span> pagamento instantâneo gratuito e seguro
              </h2>
              <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
                Nosso Pix é nota A no Índice de Qualidade de Serviço do Branco Central. Cadastre suas chaves para receber e enviar dinheiro com segurança.
              </p>
              <Link
                title='Saiba mais sobre o Pix'
                className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3 mt-md-2'
                to='/pix/'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Saiba mais sobre o Pix',
                    section_name: 'Pix no Inter: pagamento instantâneo gratuito e seguro',
                    redirect_url: `${window.location.origin}/pix/`,
                  })
                }}
              >
                Saiba mais sobre o Pix
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PixOnInter
