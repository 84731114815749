import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ViewsEnum } from '../../components/_ModalContentFactory'
import { ModalContentDispatchT, useModalContent } from '../../context/_ModalContentContext'
import { SideModalDispatchT, useSideModal } from '../../context/_SideModalContext'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { VerticalAligned } from './styles'
import { SectionWithModal } from '../../styles'
import SectionComponent from 'src/components/SectionComponent'
import { ButtonWithDeepLink } from '../../components/_ButtonWithDeepLink'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

export const TransportRecharge = ({ sectionId }: {sectionId: string}) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { setModalContentView }: ModalContentDispatchT = useModalContent()
  const { setIsModalOpen }: SideModalDispatchT = useSideModal()
  const deepLink = 'https://inter-co.onelink.me/Qyu7/b82p1jk1'

  const dataLayer: IDataLayerParams = {
    section: 'dobra_9',
    section_name: 'Recarga de vale transporte',
    element_action: 'click button',
    element_name: 'Recarregar vale transporte',
  }
  const handleLink = () => {
    sendDatalayerEvent(dataLayer)
    setIsModalOpen(true)
    setModalContentView(ViewsEnum.transportSectionFlow)
  }

  return (
    <SectionComponent minHeight={{ md: '479px', lg: '560px', xl: '657px' }} id='section-component-wrapper'>
      <SectionWithModal as='div' id={sectionId}>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <ImageWebpWithSizes
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/recarga-bilhete-unico-nova/image.png'
              altDescription='Mulher jovem em pé dentro do ônibus com fone de ouvido e o celular na mão fazendo recarga de vale transporte.'
              widths={{ md: 336, lg: 376, xl: 465, xxl: 535 }}
            />
          </div>
          <VerticalAligned className='col-12 col-md-6 col-lg-6 offset-lg-1 col-xl-5 offset-xl-2 mt-2'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 mb-md-3 mt-lg-2'>
              Recarga de vale transporte
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4 mr-n3'>
              Recarregue seu cartão de transporte diretamente pelo Super App, a qualquer hora e em qualquer lugar, de forma simples e segura.
            </p>
            <ButtonWithDeepLink
              className='btn btn--lg btn-orange--extra text-white text-none w-100 mw-100 mt-2 mt-md-3 mt-lg-2 mt-xl-3'
              href={deepLink}
              onLinkClick={() => sendDatalayerEvent({ ...dataLayer, redirect_url: deepLink })}
              onClick={handleLink}
            >
              Recarregar vale transporte
            </ButtonWithDeepLink>
          </VerticalAligned>
        </div>
      </SectionWithModal>
    </SectionComponent>
  )
}
