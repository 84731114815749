import { device } from 'src/styles/breakpoints'
import { brand, grayscale, laranja, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #EBEBEB;
  padding: 22px 0;
  padding: 20px 0 30px 0;

  @media ${device.desktopXL} {
    padding: 30px 0 40px 0
  }

  h2 {
    color: ${laranja.terra};

    .orange {
      color: ${brand.primary};
    }
  }

  .bg-white {
    background-color: ${white};
    padding: 14px 5px 14px 5px;
    border-radius: 16px;

    @media ${device.desktopXL} {
      padding: 20px 5px 20px 5px;
    }

    @media ${device.tablet} {
      background-color: transparent!important;
      padding-bottom: 0;
    }
  }

  a {
    width: 100%;

    @media ${device.tablet} {
      width: 292px;
    }
    @media ${device.desktopXL} {
      width: 488px;
    }
  }

  .bg {

    @media ${device.tablet} {
      background-color: ${white};
      border-radius: 16px;
      height: 100px;
    }
    @media ${device.desktopXL} {
      height: 120px;
    }
  }

  p {
    color: ${brand.grafite};
  }
`

export const Rates = styled.div`
  border-top: 1px dashed ${grayscale[200]};
`

export const Card = styled.div`
  padding: 12px 12px;
  
  @media ${device.tablet} {
    padding: 0 35px 0 15px;
  }
  @media ${device.desktopXL} {
    padding: 0 66px 0 15px;
  }
`
