import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { primary } from 'src/styles/newColors'
import styled, { css } from 'styled-components'
import * as T from './_types'

const titleStyles = css`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 500;
  font-size: 48px;
  line-height: 53px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }

  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;
  }
`

export const Section = styled.section`
  background-color: ${grayscale[500]};
  padding: 40px 0;
  overflow: hidden;
  position: relative;

  @media ${device.tablet} {
    padding: 48px 0;
  }

  @media ${device.desktopLG} {
    padding: 90px 0 49px 0;
  }

  @media ${device.desktopXL} {
    padding: 127px 0;
  }
`

export const Title = styled.h1`
  ${titleStyles}
  color: white;

  margin: 0 0 16px 0;

  @media ${device.tablet} {
    margin-top: 10px;
  }
  @media ${device.desktopLG} {
    width: 90%;
    margin-top: 0;
  }
  @media ${device.desktopXXL} {
    width: 95%;
  }
`

export const TitleHighlighted = styled.span`
  ${titleStyles}
  color: ${primary[500]};
`

export const Subtitle = styled.p`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: white;

  margin-bottom: 32px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 24px;

    margin-bottom: 32px;
  }

  @media ${device.desktopLG} {
    width: 90%;
    letter-spacing: -0.2px;
    margin-bottom: 32px;
  }

  @media ${device.desktopXXL} {
    width: 100%;
    font-size: 24px;
    line-height: 29px;
  }
`

export const Cashback = {
  text: {
    sm: styled.span`
      font-family: Citrina, sans-serif, Arial;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: white;

      margin: 0 0 0 10px;

      @media ${device.desktopLG} {
        font-size: 16px;
        line-height: 20px;
      }
    `,
    md: styled.span`
      font-family: Citrina, sans-serif, Arial;
      font-weight: 500;
      font-size: 40px;
      line-height: 45px;
      color: white;

      margin: 0;

      @media ${device.tablet} {
        font-size: 30px;
        line-height: 33px;
      }

      @media ${device.desktopLG} {
        font-size: 32px;
        line-height: 35px;
      }

      @media ${device.desktopXXL} {
        font-size: 48px;
        line-height: 50px;
      }
    `,
    lg: styled.span<T.HighlightedTextProp>`
      font-family: Citrina, sans-serif, Arial;
      font-weight: 700;
      font-size: 56px;
      line-height: 46px;
      letter-spacing: -0.02em;
      color: ${({ highlighted }: T.HighlightedTextProp) => highlighted ? primary[500] : 'white'};

      margin: 0;

      @media ${device.tablet} {
        font-size: 48px;
        line-height: 38px;
      }

      @media ${device.desktopLG} {
        font-size: 64px;
        line-height: 50px;
      }

      @media ${device.desktopXXL} {
        font-size: 80px;
        line-height: 70px;
      }
    `,
    xl: styled.span`
      font-family: Citrina, sans-serif, Arial;
      font-weight: 700;
      font-size: 54px;
      line-height: 30px;
      letter-spacing: -0.02em;
      color: white;
      position: relative;
      right: 0px;
      top: -5px;

      margin: 0 -10px 0 0;

      @media ${device.desktopLG} {
        font-size: 64px;
        line-height: 50px;
      }

      @media ${device.desktopXXL} {
        font-size: 80px;
      }
    `,
  },
  wrapper: styled.div`
    display: block;
    width: 100%;
    margin: 0 auto;

    @media ${device.tablet} {
      margin: 0;
    }

    @media ${device.desktopLG} {
      margin: 0 auto;
    }

    @media ${device.desktopXXL} {
      margin: 0;
    }
  `,
}

export const Row = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  padding-bottom: 376px;

  @media ${device.tablet} {
    padding-bottom: 0;
  }
`

export const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const OrangeFridayImage = styled.img`
  position: absolute;
  z-index: 0;
  bottom: 40px;

  width: 100vw;
  max-width: 710px;
  right: -12px;

  @media ${device.tablet} {
    width: 48vw;
    bottom: 45%;
    transform: translate(0px, 50%);
  }

  @media ${device.desktopLG} {
    width: 49vw;
    bottom: 0;
    transform: translate(0, 0);
  }

  @media ${device.desktopXXL} {

  }
`

export const Texts = styled.div`
  @media ${device.tablet} {
    padding: 0;
  }

  @media ${device.desktopXXL} {
    max-width: 45%;
  }

  .enjoy_now_button {
    margin-top: 24px;

    &:hover {
      background-color: ${primary[400]};
    }

    @media ${device.tablet} {
      margin-top: 18px;
    }
    @media ${device.desktopXXL} {
      margin-top: 40px;
    }
  }
`
