import React from 'react'

import benefitsData from '../../assets/data/benefits.json'

import useUtms from 'src/hooks/useUtms'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import { widths } from 'src/styles/breakpoints'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import InterCoIcons from 'src/components/UI/MarkdownIcon/InterCoIcons'

import { Section, ButtonLink, Card } from './style'
import { brand } from 'src/styles/colors'

type benefitsProps = {
  icon: string;
  title: string;
  description: string;
}

const Benefits = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: 'LP Pj Pro' })
  const width = useWidth(300)

  return (
    <Section className='py-4' id='benefits'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'>
              Benefícios da Conta PJ PRO para sua empresa
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400'>
              Estamos aqui para simplificar a vida do seu negócio com vantagens que só o PJ PRO tem.
            </p>
            {
              width >= widths.md && (
                <ButtonLink
                  href={utmLink}
                  target='__blank'
                  rel='noreferrer'
                  onClick={() => sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Quero ser PJ Pro',
                    section_name: 'Benefícios da Conta PJ PRO para sua empresa',
                    redirect_url: utmLink,
                  })}
                >
                  Quero ser PJ Pro
                </ButtonLink>
              )
            }
          </div>
          <div className='col-12 col-md-6 cardCarousel'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 30 }}
              md={{ items: 1, partialVisibilityGutter: 100 }}
              lg={{ items: 1, partialVisibilityGutter: 200 }}
              xl={{ items: 2 }}
              itemClass='px-2'
            >
              {
                benefitsData.map((item: benefitsProps) => (
                  <Card key={item.title}>
                    <div className='icon'>
                      <InterCoIcons
                        width={26}
                        height={26}
                        color={brand.primary}
                        icon={item.icon}
                        size='MD'
                      />
                    </div>
                    <h3
                      className='fs-16 lh-20 fs-xl-18 lh-xl-22 text-grascale--500'
                    >
                      {item.title}
                    </h3>
                    <p
                      className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grascale--400 mb-0'
                    >
                      {item.description}
                    </p>
                  </Card>
                ))
              }
            </DefaultCarousel>
            {
              width < widths.md && (
                <ButtonLink
                  href={utmLink}
                  target='__blank'
                  rel='noreferrer'
                  onClick={() => sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Quero ser PJ Pro',
                    section_name: 'Benefícios da Conta PJ PRO para sua empresa',
                    redirect_url: utmLink,
                  })}
                >
                  Quero ser PJ Pro
                </ButtonLink>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Benefits
