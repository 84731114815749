import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'

import { advantagesList } from './_advantagesList'
import { SideModalDispatchT, useSideModal } from '../../context/_SideModalContext'
import { ModalContentDispatchT, useModalContent } from '../../context/_ModalContentContext'
import { ViewsEnum } from '../../components/_ModalContentFactory'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

type VantagensProps = {
  sectionId: string;
}

export const Vantagens = ({ sectionId }: VantagensProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const { setIsModalOpen }: SideModalDispatchT = useSideModal()
  const { setModalContentView }: ModalContentDispatchT = useModalContent()
  const deepLink = 'https://inter-co.onelink.me/Qyu7/f9qebj1r'
  const titleH2 = 'Vantagens de fazer sua recarga online pelo Super App'

  const arrayNumbers: number[] = [
    312, 336, 456, 552,
  ]

  const arrayHeight: number[] = [
    253, 272, 369, 447,
  ]

  const dataLayer: IDataLayerParams = {
    section: 'dobra_3',
    section_name: 'Vantagens de fazer sua recarga online pelo Super App',
    element_action: 'click button',
    element_name: 'Recarregar online',
  }

  const handleClick = () => {
    sendDatalayerEvent(dataLayer)
    setIsModalOpen(true)
    setModalContentView(ViewsEnum.primaryFlow)
  }

  return (
    <Section className='pt-5 pb-4' id={sectionId}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 mb-3'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-1440-recarga/image.webp'
              altDescription='Mulher sorridente segurando o celular e conferindo sua recarga online.'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>

          <div className='col-12 mb-4 pb-md-2 mb-lg-5 pb-lg-3 pb-xl-2 order-md-first text-md-center'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-0'>
              {titleH2}
            </h2>
          </div>

          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 offset-lg-1'>
            {advantagesList?.map((advantages: typeof advantagesList[0]) => (
              <div className='mb-4' key={advantages.title}>
                <div className='d-flex align-items-center mb-2'>
                  <div className='d-flex mr-2'>
                    {advantages.icon}
                  </div>
                  <h3 className='font-sora fw-600 fs-16 lh-20 fs-lg-18 lh-lg-22 fs-xl-20 lh-xl-25 text-grayscale--500 mb-0'>{advantages.title}</h3>
                </div>
                <p
                  className='fs-14 lh-17 fs-xl-18 lh-xl-22 text-grayscale--500 mb-0 mr-n3'
                  dangerouslySetInnerHTML={{ __html: advantages.description }}
                />
              </div>
              ))}
            {
              width < WIDTH_MD
              ? (
                <a
                  href={deepLink}
                  className='btn btn--lg btn-orange--extra text-none w-100 mw-100 mt-2'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      section_name: titleH2,
                      element_name: 'Recarregar online',
                      redirect_url: deepLink,
                     })
                  }}
                >
                  Recarregar online
                </a>
              )
            : (
              <button
                className='btn btn--lg btn-orange--extra text-none w-100 mw-100 mt-md-3 mr-xl-5'
                onClick={handleClick}
              >
                Recarregar online
              </button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}
