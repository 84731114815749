import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Styls
import { Button, Section } from './style'

const GanhePontosLoop = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/investimentos-app-dobra/image.webp'
              altDescription='Várias telas do Super App do Inter falando das comunidades de investimentos'
              arrayNumbers={[]}
              arrayNumbersHeight={[]}
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-47 fw-700 text-grayscale--500 mb-4 mt-3 mt-md-0'>
              Ganhe Pontos Loop com as Comunidades de Investimento
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-4'>
              Participantes de comunidades de investimento, podem ganhar até 40% de cashback nos fundos ao atingir o nível máximo. Além disso, os ganhos em renda fixa também são turbinados.
            </p>
            <Button
              href='https://inter.co/pra-voce/investimentos/comunidade-de-investimentos/'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: 'Ganhe Pontos Loop com as Comunidades de Investimento',
                  element_action: 'click button',
                  element_name: 'Quero conhecer',
                  redirect_url: 'https://inter.co/pra-voce/investimentos/comunidade-de-investimentos/',
                })
              }}
            >
              Quero conhecer
            </Button>
          </div>
        </div>
      </div>
    </Section>

  )
}

export default GanhePontosLoop
