import React from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import IcCreditCard from '@interco/icons/core/finance/ic_credit_card'
import IcTrendingUp from '@interco/icons/core/finance/ic_trending_up'
import IcShoppingBag from '@interco/icons/core/shopping/ic_shopping_bag'
import IcPlaneDeparture from '@interco/icons/core/travel-mobility/ic_plane_departure'
import IcInterLoop from '@interco/icons/core/brands/ic_inter_loop'
import IcGlobe from '@interco/icons/core/home-objects/ic_globe'
import IcCoin from '@interco/icons/core/finance/ic_coin'
import IcAdd from '@interco/icons/core/action-navigation/ic_add'

import * as S from './styles'

type ICard = {
  icon: React.JSX.Element;
  text: string;
}

interface IHeroProps {
  openModal: (sectionOrder: string, dataLayer: IDataLayerParams) => void;
  sectionOrder: string;
}

const dataCard: ICard[] = [
  {
    icon: <IcCreditCard color='#FFFFFF' />,
    text: 'Cartão de crédito e débito',
  },
  {
    icon: <IcTrendingUp color='#FFFFFF' />,
    text: 'Plataforma de investimentos',
  },
  {
    icon: <IcShoppingBag color='#FFFFFF' />,
    text: 'Shopping com cashback',
  },
  {
    icon: <IcPlaneDeparture color='#FFFFFF' />,
    text: 'Seguros e viagens',
  },
  {
    icon: <IcInterLoop color='#FFFFFF' />,
    text: 'Programa de pontos Loop',
  },
  {
    icon: <IcGlobe color='#FFFFFF' />,
    text: 'Conta global em dólar',
  },
  {
    icon: <IcCoin color='#FFFFFF' />,
    text: 'Pix e transferências gratuitos e ilimitados',
  },
  {
    icon: <IcAdd color='#FFFFFF' />,
    text: 'E muito mais',
  },
]

const S1_Hero = ({ openModal, sectionOrder }: IHeroProps) => {
  return (
    <S.Section>

      <S.BoxTop>
        <S.ContentImage>
          <img
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/cartao-socio-furacao/image.png'
            alt='Tela de celular mostrando o Super App Inter, sobreposta por ilustração cartão Athletico Paranaense'
          />
        </S.ContentImage>

        <S.ContentText>
          <h1 className='title'>
            Inter e Furacão
          </h1>
          <p className='subtitle'>
            Pra quem veste a camisa até na hora de cuidar do dinheiro.
          </p>
          <p className='description'>
            Agora você pode apoiar o Furacão e ainda facilitar a sua vida financeira de uma só vez.
            Parceiro do Athletico, Super App Inter está cheio de benefícios pra você, com conta digital 100% grátis.
          </p>
        </S.ContentText>
      </S.BoxTop>

      <S.BoxMid>
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/cap-dobra2-logos/image.webp'
          className='mx-auto'
        />
      </S.BoxMid>

      <S.BoxBot>
        <S.BoxCTA>
          <h2 className='title'>
            Carregue o Furacão no coração. E na carteira.
          </h2>
          <p className='description'>
            <strong>Conta digital gratuita completa e sem anuidade!</strong> O Super App Inter traz todas as soluções para sua vida financeira em um só lugar.
          </p>
          <div className='cta'>
            <button
              onClick={() => openModal(sectionOrder, {
                  section: 'dobra_1',
                  section_name: 'Aproveite benefícios exclusivos no Super App Inter',
                  element_action: 'click button',
                  element_name: 'Abrir conta',
                })}
              title='Abrir conta'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
            >
              Abrir conta
            </button>
          </div>
        </S.BoxCTA>
        <S.BoxCards>
          {dataCard?.map((item: ICard) => (
            <div key={item?.text} className='boxItem'>
              <div className='boxIcon'>{item?.icon}</div>
              <div className='boxText'><p>{item?.text}</p></div>
            </div>
          ))}
        </S.BoxCards>
      </S.BoxBot>
    </S.Section>
  )
}

export default S1_Hero
