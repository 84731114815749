import { breakpoints } from 'src/styles/breakpoints'
import { gray, grayscale, orange, red, white } from 'src/styles/colors'
import { graphiteOne } from 'src/styles/newColors'
import styled from 'styled-components'

export const Container = styled.div`
  background: ${white};
  position: relative;
  z-index: 1;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
  
  input {
    height: 48px;
    border-radius: 8px;
    width: 100%;

    &.error {
      border: 1px solid ${red.base}
    }
  }

  label {
    margin-bottom: 4px;
    color: ${grayscale[400]};
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
  }

  span {

    &.error {
      font-size: 12px;
      line-height: 15px;
      color: ${red.base};
      margin-top: 3px;
      text-align: right;
    }
  }

  button {
    background: ${orange.extra};
    color: ${white};
    font-size: 14px;
    line-height: 17px;
    height: 48px;
    width: 100%;
    max-width: 100%;
    font-weight: 600;
    border-radius: 8px;
  }

  span.select {
    background: ${gray['400']};
    display: block;
    outline: none;
    overflow: hidden;
    width: 100%;
    height: 48px;
    border-radius: 8px;

    select {
      cursor: pointer;
      appearance: none;
      background: ${gray['400']}; 
    }

    &:after {
      content: "";
      border-color: transparent transparent ${gray['600']} ${gray['600']};
      border-style: solid;
      border-width: 2px;
      height: 9px;
      margin-top: -7px;
      padding: 0;
      pointer-events: none;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: rotate(315deg);
      transition: all .2s ease-in-out;
      width: 9px;
    }
  }  
`

export const SetSent = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding: 100px 85px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    padding: 0 40px;
    color: ${graphiteOne};
  }
`

export const SetError = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding: 100px 85px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    font-size: 24px;
    line-height: 26px;
    color: ${grayscale[500]};
    font-family: 'Citrina', Helvetica, sans-serif;
    padding: 0 40px;
    color: ${graphiteOne};
    margin: 24px 0;
  }

  button {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    border-radius: 8px;
    background: ${orange.extra};
    height: 46px;
    color: ${white}
  }
`

export const SetUnderAge = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding: 100px 85px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    color: ${grayscale[500]};
    padding: 0 20px;
  }

  p {
    color: ${graphiteOne};
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 24px;

    @media (min-width: ${breakpoints.md}) {
      font-size: 24px;
      line-height: 28px;
    }

    > span {

      @media (min-width: ${breakpoints.md}) {
        display: block;
      }
    }
  }
`

export const Images = styled.div`
  margin: 0 auto;
  text-align: center;
`
