export const supportContent = [
  {
    text: 'Qual a melhor criptomoeda para investir hoje?',
    link: 'https://blog.inter.co/qual-a-melhor-criptomoeda-para-investir-hoje/',
  },

  {
    text: 'Como comprar criptomoedas a partir de R$ 1,00',
    link: 'https://blog.inter.co/como-comprar-criptomoedas/',
  },

  {
    text: 'O que é halving do Bitcoin? Conheça!',
    link: 'https://blog.inter.co/o-que-e-halving-do-bitcoin/',
  },

  {
    text: 'O investimento em criptoativos é para todo mundo?',
    link: 'https://blog.inter.co/o-investimento-em-criptoativos-e-para-todo-mundo/',
  },
]
