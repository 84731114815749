import React, { useState } from 'react'
import Tab from 'src/components/Tab'
import meuPorquinho from '../../assets/data/meuPorquinho.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Card, Button, Tag, ImageContainer } from './style'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

type BenefitsProps = {
  text: string;
}

type ItemProps = {
  card: string;
  alt: string;
  benefits: BenefitsProps[];
  imageDesk: string;
  imagemMD: string;
  title: string;
  description: string;
  link: string | null;
  tag?: string | null;
}

const MeuPorquinho = () => {
  const tabName = meuPorquinho.map((item: ItemProps) => item.card)
  const width = useWidth(300)
  const [ activeItem, setActiveItem ] = useState(0)
  const [ sendDatalayerEvent ] = useDataLayer()

  const getImage = (card: ItemProps) => {
    if (width < widths.md || width >= widths.xl) {
      return card.imageDesk
    } else {
      return card.imagemMD
    }
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-between'>
          {
            width >= widths.lg && (
              <div className='col-12 col-md-6 d-flex align-items-center'>
                <ImageContainer
                  pathSrc={getImage(meuPorquinho[activeItem])}
                  altDescription={meuPorquinho[activeItem].alt}
                  arrayNumbers={[ ]}
                  arrayNumbersHeight={[ ]}
                  className='w-100'
                />
              </div>
            )
          }
          <div className='col-12 col-lg-6 col-xl-5 d-flex align-items-center'>
            <Tab classDiv='mt-5' items={tabName} setTabActive={setActiveItem} border>
              {meuPorquinho.map((card: ItemProps) => (
                <div className='row' key={card.description}>
                  {
                    width < widths.lg && (
                      <div className='col-12 col-md-5 mb-4 d-flex align-items-center'>
                        <ImageContainer
                          pathSrc={getImage(meuPorquinho[activeItem])}
                          altDescription={card.alt}
                          arrayNumbers={[ ]}
                          arrayNumbersHeight={[ ]}
                          className='w-100'
                        />
                      </div>
                    )
                  }
                  <div className='col-12 col-md-7 col-lg-12'>
                    <div className='d-flex justify-content-between'>
                      <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-33 fs-xl-42 lh-xl-52 fw-700 text-grayscale--500'>{card.title}</h2>
                      {
                        card.tag && (
                          <Tag>{card.tag}</Tag>
                        )
                      }
                    </div>
                    <p className='fs-16 lh-19  text-grayscale--400 font-weight-400'>
                      {card.description}
                    </p>
                    <div className='row'>
                      {card.benefits.map((benefit: BenefitsProps) => (
                        <div className='col-12 col-md-6' key={benefit.text}>
                          <Card>
                            <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-24 text-grayscale--500 mb-0 font-weight-600'>
                              {benefit.text}
                            </h3>
                          </Card>
                        </div>
                      ))}
                    </div>
                    {
                    width >= widths.xl && (
                      <Button
                        href={card.link ?? '#'}
                        className='w-xl-100'
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_06',
                            element_action: 'click button',
                            element_name: `Guardar dinheiro - ${meuPorquinho[activeItem].card}`,
                            section_name: 'Dê um up no seu setup gamer e ganhe cashback',
                            redirect_url: meuPorquinho[activeItem].link,
                          })
                        }
                        }
                      >
                        Guardar dinheiro
                      </Button>
                    )
                    }
                  </div>
                </div>
              ))}
            </Tab>
          </div>
          {
            width < widths.xl && (
              <div className='col-12'>
                <div className='w-100 d-flex justify-content-center'>
                  <Button
                    href={meuPorquinho[activeItem].link ?? '#'}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_06',
                        element_action: 'click button',
                        element_name: `Guardar dinheiro - ${meuPorquinho[activeItem].card}`,
                        section_name: 'Dê um up no seu setup gamer e ganhe cashback',
                        redirect_url: meuPorquinho[activeItem].link,
                      })
                    }
                    }
                  >
                    Guardar dinheiro
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Section>
  )
}

export default MeuPorquinho
