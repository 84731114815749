import React from 'react'

import { Section, Button, ButtonLink } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ModalProps = {
  setIsModal: Function;
}

const MoreConfort = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_5',
      section_name: 'Mais conforto em sua viagem',
      element_action: 'click button',
      element_name: 'Viajar com mais comodidade',
      c_page: window.location.href,
    })
  }
  return (
    <Section
      className='py-5 d-flex align-items-end align-items-md-center'
      role='img'
      aria-label='Carro na estrada no por do sol'
    >
      <div className='container'>
        <div className='row d-flex justify-content-end'>
          <div className='col-12 col-md-6'>
            <span className='fs-12 lh-17 text-grayscale--500 fw-600 mb-4 d-md-block d-none'>ALUGUEL DE CARRO</span>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>Mais conforto em sua viagem</h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400'>Aqui também tem aluguel de carros para você explorar cada cantinho do seu roteiro de viagem com mais comodidade, conforto e segurança.</p>
            <Button onClick={() => handleClick()} className='d-none d-md-block'>Viajar com mais comodidade</Button>
            <ButtonLink
              className='d-md-none'
              href='https://inter-co.onelink.me/Qyu7/53fu0m0i'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_5',
                section_name: 'Mais conforto em sua viagem',
                element_action: 'click button',
                element_name: 'Reserve seu carro com a gente',
                c_page: window.location.href,
              })}
            >
              Reserve seu carro com a gente
            </ButtonLink>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default MoreConfort
