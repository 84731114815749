import React from 'react'
import * as S from './style'

import OpenAccountPFForm from 'src/components/UI/Forms/OpenAccounPFForm'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

const AbrirContaPF = () => {
  return (
    <Layout pageContext={pageContext}>
      <S.Image />
      <S.Wrapper>
        <div className='row mx-0 justify-content-center'>
          <div className='col-12 col-lg-6' />
          <div className='col-12 col-lg-6 col-xl-5 px-0'>
            <OpenAccountPFForm
              customOrigin='LP Conta Digital PF avulsa'
            />
          </div>
        </div>
      </S.Wrapper>
    </Layout>
  )
}

export default AbrirContaPF
