import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const PayByContact = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='digital-accoun' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='d-flex justify-content-center justify-content-lg-start mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra7-contactless/image.webp'
                altDescription='Representação de um cliente Inter pagando por aproximação através do seu smartphone.'
                arrayNumbers={[ 300, 322, 382, 449 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>
              Pague por aproximação com as Carteiras Digitais
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>
              Ative seus cartões nas Carteiras digitais do celular e pague por ae nunca mais passar perrengue, mesmo se o físico ficar em casa!
            </p>
            <Link
              title='Conhecer vantagens'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2 mt-md-4'
              to='/pra-voce/cartoes/carteiras-digitais/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  element_name: 'Conhecer vantagens',
                  section_name: 'Pague por aproximação com as Carteiras Digitais',
                  redirect_url: `${window.location.origin}/pra-voce/cartoes/carteiras-digitais/`,
                })
              }}
            >
              Conhecer vantagens
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PayByContact
