import React, { MouseEvent } from 'react'
import useWidth from 'src/hooks/window/useWidth'

import BaixeOApp from 'src/assets/images/qr-code-open-account-pf-modal.png'
import Success from 'src/assets/images/sucesso.png'

import { WIDTH_LG, WIDTH_MD } from 'src/styles/breakpoints'
import { Container, Images, SetUnderAge } from '../../style'

const ErrorStep = () => {
  const windowWidth = useWidth(200)

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_LG) {
      window.location.href = 'https://intergo.app/7820e737'
    } else {
      window.location.href = '/pra-voce/conta-digital/conta-kids/'
    }
  }

  return (
    <Container>
      <SetUnderAge>
        <h3>
          Vimos que você tem menos de 18 anos.
        </h3>
        <p>
          <span>
            Conheça nossa
            <span
              className='text-orange--extra cursor-pointer'
              onClick={asUnderAge}
            > Conta Kids
            </span>,
          </span> que também é completa, digital e gratuita.
        </p>
        <Images className='col-12'>
          {
            windowWidth < WIDTH_MD
              ? <img
                  src={Success} alt='Sucesso'
                  width={184} height={184}
                />
              : <img
                  src={BaixeOApp} alt='Imagem do QR Code para baixar o app Inter'
                  width={184} height={184}
                />
          }
        </Images>
      </SetUnderAge>
    </Container>
  )
}

export default ErrorStep
