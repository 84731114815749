import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white, laranja } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 685px;
  background-color: ${laranja.terra};
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 40px;
  }

  .list-item + div {
    margin-top: 32px;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 378px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 450px;
    padding: 73px 0;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 536px;
  }
`
export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${orange.extra};
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 40px;

  &:hover {
    color: ${white};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`
export const Circle = styled.div`
  width: 34px;
  height: 34px;
  min-width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  background-color: #F8DAC6;
`
