import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const CreditCard = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='digital-accoun' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 order-md-last'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra/image.webp'
                altDescription='Ilustração do cartão de crédito Inter ao lado de um smartphone com a tela do Super App Inter em destaque.'
                arrayNumbers={[ 286, 286, 372, 457 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>Cartão de Crédito com programa de pontos</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-md-4'>Cartões sem anuidade e com programa de pontos. Usando seu limite de crédito, você pode acumular pontos e trocar por milhas, cashback, descontos na fatura e muito mais.</p>
            <Link
              title='Conheça nossos cartões'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to='/pra-voce/cartoes/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Conheça nossos cartões',
                  section_name: 'Cartão de Crédito com programa de pontos',
                  redirect_url: `${window.location.origin}/pra-voce/cartoes/`,
                })
              }}
            >
              Conheça nossos cartões
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CreditCard
