import React from 'react'
// Components
import Icon from 'src/components/UI/MarkdownIcon'
import ImageWebp from 'src/components/ImageWebp'

// Style
import * as S from './_styles'

// Assets
import ConteudosRelacionadosJSON from './ConteudosRelacionados.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ItensProps = {
  image: string;
  title: string;
  link: string;
  alt: string;
}

const S6ConteudosQuePodemTeAjudar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    360, 200, 250, 355,
  ]

  return (
    <S.Section
      className='py-5'
      id='conteudos-que-podem-te-ajudar'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-40 lh-xl-50 text-grayscale--500 text-md-center'>
              Conteúdos que podem te ajudar
            </h2>
            <S.Description>
              Os melhores conteúdos para te ajudar a investir com segurança e rentabilidade.
            </S.Description>
          </div>
          { ConteudosRelacionadosJSON.map((item: ItensProps, index: number) => (
            <div className='col-12 col-md-4' key={index}>
              <a
                data-blog={item.title}
                href={item.link}
                target='_blank'
                rel='noreferrer'
                title={`Leia mais em ${item.link}`}
                className='fs-14 lh-17 fw-400 text-orange--extra text-left mt-xl-3'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Conteúdos que podem te ajudar',
                      element_action: 'click button',
                      element_name: item.title,
                      redirect_url: item.link,
                    })
                }}
              >
                <S.BlogCarousel className='col-12 p-card'>
                  <div className='col-12 px-0 '>
                    <ImageWebp
                      pathSrc={item.image}
                      altDescription={item.alt}
                      arrayNumbers={arrayNumbers}
                    />
                    <S.CardBottom className='col-12 mt-n5 bg-white w-100'>
                      <S.CardTitle>{item.title}</S.CardTitle>
                      <span className='text-right read_more'>
                        Leia mais
                        <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                      </span>
                    </S.CardBottom>
                  </div>
                </S.BlogCarousel>
              </a>
            </div>
              ))}
        </div>
      </div>
    </S.Section>
  )
}

export default S6ConteudosQuePodemTeAjudar
