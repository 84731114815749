import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react'

import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import { apiForm } from 'src/config/api/axiosRequest'
import { convertFloatToCurrencyString, getParameterByName } from 'src/shared/helpers'

import AcceptTerms from 'src/components/AcceptTerms'
import AcceptTermsNewsLetter from 'src/components/AcceptTerms/NewsLetters'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'

import { ErrorStep, SentStep, UnderAge } from './FormSteps/_index'
import { privado, publico } from './data/type-data-convenio'

import { Container } from './style'
interface IFormValues {
  primeiroNome: string;
  sobrenome: string;
  cpf: string;
  dataNascimento: string;
  celular: string;
  email: string;
  tipo: string;
  convenio: string;
  motivo: string;
  valor: number;
}

type FormProps = {
  section: string;
  sectionName: string;
}

function ContatoConsultoresField ({ section, sectionName }: FormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ underAge, setUnderAge ] = useState(false)
  const [ isValor, setValor ] = useState(0)
  const [ isConvenio, setIsConvenio ] = useState<{label: string; value: string}[]>([])
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', window.document.location.search.substring(1)))
    setUtmMedium(getParameterByName('utm_medium', window.document.location.search.substring(1)))
    setUtmCampaign(getParameterByName('utm_campaign', window.document.location.search.substring(1)))
  }, [])

  function currencyToFloat (currencyNumber: string) {
    const result =
    currencyNumber === ''
    ? 0
    : parseInt(currencyNumber.replace(/\D/g, '')) / 100
    return result
  }

  function changeInput (evt: ChangeEvent<HTMLInputElement>) {
    const inputValue = evt.target.value
    const insertValue = currencyToFloat(inputValue)
    setValor(insertValue)
  }

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      celular: data.celular.replace(/\D/g, ''),
      valor: parseFloat(data.valor.toString().replace('.', '').replace(',', '.')),
      termoAceite: accept,
      utmSource: utmSource?.toString(),
      utmMedium: utmMedium?.toString(),
      utmCampaign: utmCampaign?.toString(),
    }

    try {
      if (result < 18) {
        setUnderAge(true)
        window.location.href = '/consignado/consultores-field/#form-consignado'
      } else {
        await apiForm.post(`${URLS.CONTACT_FORM_POST_V5}/lead-consignado-field`, [ formData ])
        setLoading(false)
        setSent(true)
        window.scroll(0, 0)

        sendDatalayerEvent({
          section: section,
          element_action: 'click button',
          section_name: sectionName,
          element_name: 'Quero receber contato',
          step: 'success',
        })
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      window.scroll(0, 0)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  const handleType = useCallback((evt: ChangeEvent<HTMLSelectElement>): void => {
    const selectedType = evt.target.value

    if (selectedType === 'Crédito Consignado Público') {
      setIsConvenio(publico)
    } else if (selectedType === 'Crédito Consignado Empresa Privada') {
      setIsConvenio(privado)
    } else {
      setIsConvenio([])
    }
  }, [])

  const handleClick = () => {
    const marketingInput = document.querySelector('input[name="campanhas-marketing"]')
    if (marketingInput) {
      (marketingInput as HTMLInputElement).checked = true
    }
  }

  return (
    <>
      { error && (
        <ErrorStep handleReturn={handleReturn} />
      )}
      { sent && (
        <SentStep />
      )}
      { underAge && (
        <UnderAge />
      )}

      <Container className={`${error || sent || underAge ? 'd-none' : 'd-flex'} align-items-center`}>
        <div className='row'>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='row'>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='nome'>Nome</label>
                    <input
                      name='primeiroNome'
                      id='primeiroNome'
                      type='text'
                      maxLength={100}
                      placeholder='Digite seu primeiro nome'
                      className={`${errors.primeiroNome && 'error'}`}
                      ref={register({
                        required: 'Nome obrigatório',
                      })}
                    />
                    {errors.primeiroNome && <span className='error'>{errors.primeiroNome.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='sobrenome'>Sobrenome</label>
                    <input
                      name='sobrenome'
                      id='sobrenome'
                      type='text'
                      maxLength={100}
                      placeholder='Digite seu sobrenome'
                      className={`${errors.sobrenome && 'error'}`}
                      ref={register({
                        required: 'Sobrenome obrigatório',
                      })}
                    />
                    {errors.sobrenome && <span className='error'>{errors.sobrenome.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='cpf'>CPF</label>
                    <input
                      name='cpf'
                      id='cpf'
                      type='tel'
                      placeholder='Digite seu CPF, apenas números'
                      className={`${errors.cpf && 'error'}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                      ref={register({
                        required: 'Digite um CPF válido',
                        validate: {
                          isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                        },
                      })}
                    />
                    {errors.cpf && <span className='error'>{errors.cpf.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='dataNascimento'>Data de Nascimento</label>
                    <input
                      name='dataNascimento'
                      id='dataNascimento'
                      type='tel'
                      placeholder='dd/mm/aaaa'
                      maxLength={10}
                      className={`${errors.dataNascimento && 'error'}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                      ref={register({
                        required: 'Digite uma data válida',
                        validate: {
                          isDate: (value: string) => Validations.dateValid(value) || 'Insira uma data de nascimento válida',
                        },
                      })}
                    />
                    {errors.dataNascimento && <span className='error'>{errors.dataNascimento.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='celular'>Celular</label>
                    <input
                      name='celular'
                      id='celular'
                      type='tel'
                      placeholder='Digite seu telefone'
                      maxLength={15}
                      className={`${errors.celular && 'error'}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                      ref={register({
                        required: 'Digite um telefone válido',
                        pattern: {
                          value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                          message: 'Telefone inválido',
                        },
                      })}
                    />
                    {errors.celular && <span className='error'>{errors.celular.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='email'>E-mail</label>
                    <input
                      name='email'
                      id='email'
                      type='text'
                      placeholder='Digite seu e-mail'
                      className={`${errors.email && 'error'}`}
                      ref={register({
                        required: 'Digite um e-mail válido',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'E-mail inválido',
                        },
                      })}
                    />
                    {errors.email && <span className='error'>{errors.email.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='tipo'>Tipo de convênio</label>
                    <span className='select'>
                      <select
                        name='tipo'
                        id='tipo'
                        onChange={handleType}
                        ref={register({
                          required: 'Selecione o tipo do convênio',
                        })}
                      >
                        <option value=''>Selecione o tipo do convênio</option>
                        <option value='Crédito Consignado Público'>Público</option>
                        <option value='Crédito Consignado Empresa Privada'>Privado</option>
                      </select>
                    </span>
                    {errors.tipo && <span className='error'>{errors.tipo.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='convenio'>Convênio</label>
                    <span className='select'>
                      <select
                        name='convenio'
                        id='convenio'
                        ref={register({
                          required: 'Selecione seu convênio',
                        })}
                      >
                        <option value=''>Selecione seu convênio</option>
                        {
                          isConvenio.map((item: {value: string; label: string}) => (
                            <option key={item.value} value={item.value}>{item.label.toUpperCase()}</option>
                          ))
                        }
                      </select>
                    </span>
                    {errors.convenio && <span className='error'>{errors.convenio.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='motivo'>Motivo</label>
                    <span className='select'>
                      <select
                        name='motivo'
                        id='motivo'
                        ref={register({
                          required: 'Selecione o motivo',
                        })}
                      >
                        <option value=''>Selecione o motivo</option>
                        <option value='Novo Crédito'>Novo contrato</option>
                        <option value='Portabilidade'>Trazer contrato</option>
                        <option value='Refinanciamento'>Refinanciar</option>
                      </select>
                    </span>
                    {errors.motivo && <span className='error'>{errors.motivo.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='valor'>Valor desejado</label>
                    <input
                      name='valor'
                      id='valor'
                      type='tel'
                      maxLength={10}
                      value={convertFloatToCurrencyString(isValor)}
                      placeholder='Digite apenas números'
                      className={`${errors.valor && 'error'}`}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        changeInput(evt)
                      }}
                      ref={register({
                        required: 'Valor obrigatório',
                      })}
                    />
                    {errors.valor && <span className='error'>{errors.valor.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column'>
                    <AcceptTerms
                      accept={accept}
                      setAccept={() => {
                        setAccept(!accept)
                        handleClick()
                      }}
                      name='contato-consignado-field'
                      label='Estou de acordo com o tratamento dos meus dados pessoais para a finalidade de simulação de empréstimo consignado e estou ciente da'
                    />
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <AcceptTermsNewsLetter
                      accept={accept}
                      setAccept={setAccept}
                      name='campanhas-marketing'
                      label='Aceito receber campanhas de marketing sobre consignado.'
                    />
                  </div>
                  <div className='col-12'>
                    <button
                      type='submit'
                      title='Quero receber contato'
                      disabled={!accept || loading}
                    >
                      {loading ? 'Enviando...' : 'Quero receber contato'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ContatoConsultoresField
