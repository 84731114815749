import React, { useRef, useEffect } from 'react'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail } from 'react-spring'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './styles'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import * as newColors from 'src/styles/newColors'
import IconAndTitle from 'src/components/IconAndTitle/index'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

type ItemProps = {
  title: string;
  icon: {
    name: string;
    customPath: string;
    backgroundColor: string;
  };
}

const ItensList: ItemProps[] = [
  {
    title: '1ª conta digital<br>e gratuita do Brasil',
    icon: {
      name: 'ic_smartphone',
      customPath: 'media-communication/',
      backgroundColor: newColors.secundaryBlue,
    },
  },
  {
    title: 'Mais de 3,6 milhões<br>de clientes globais',
    icon: {
      name: 'ic_globe',
      customPath: 'home-objects/',
      backgroundColor: newColors.secundaryGreen,
    },
  },
]

const SuperAppFinanceiroCompleto = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
    }
  }, [ scroll ])

  return (
    <S.Section ref={sectionRef}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 main_container'>
            <animated.div style={animatedSection[0]} className='main_container__titles'>
              <div className='main_container__titles--clary'>
                <IconsSwitch
                  icon='ic_users'
                  size='MD'
                  color='white'
                  lib='interco'
                  customPath='human/'
                  className='main_container__titles--clary__icon'
                />
                <h2 className='main_container__titles--clary__text'>
                  +34,9 milhões de clientes
                </h2>
              </div>
              <div className='main_container__titles--light'>
                <h2 className='main_container__titles--light__text'>
                  <span className='d-block'>O Super App financeiro</span> mais completo da América
                </h2>
                <IconsSwitch
                  icon='ic_smartphone'
                  size='MD'
                  color={newColors.orange.clay}
                  lib='interco'
                  customPath='media-communication/'
                  className='main_container__titles--light__icon'
                />
              </div>
            </animated.div>
            <animated.ul style={animatedSection[1]} className='main_container__qualities_list'>
              {ItensList.map((item: ItemProps, index: number) => (
                <IconAndTitle
                  key={`qualityItem${index}`}
                  title={item.title}
                  iconBackgroundColor={item.icon.backgroundColor}
                >
                  <IconAndTitle.Icon
                    className='main_container__qualities_list__item__icon_wrapper'
                  >
                    <IconsSwitch
                      icon={item.icon.name}
                      size='MD'
                      color='black'
                      lib='interco'
                      customPath={item.icon.customPath}
                      className='main_container__qualities_list__item__icon_wrapper__icon'
                    />
                  </IconAndTitle.Icon>
                  <IconAndTitle.Title
                    className='main_container__qualities_list__item__title'
                  />
                </IconAndTitle>
              ))}
            </animated.ul>
            <animated.a
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_3',
                  section_name: 'O Super App financeiro mais completo da América',
                  element_action: 'click button',
                  element_name: 'Confira nossos resultados',
                  redirect_url: 'https://investors.inter.co/',
                })
              }}
              style={animatedSection[2]}
              target='blank'
              href='https://investors.inter.co/'
              className='btn--lg rounded-2 fs-14 fw-600 text-none text-orange d-flex justify-content-center align-items-center mt-5'
            >
              Confira nossos resultados
            </animated.a>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SuperAppFinanceiroCompleto
