import React from "react"
import { Section, Title, Texts } from "./_style"
import ImageWebp from "src/components/ImageWebp"
import data from "./data.json"
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from "src/hooks/window/useWidth"

export default function PreCadastro () {
  const width = useWidth()

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 order-1 order-md-2 order-xl-1 d-flex justify-content-lg-end justify-content-xl-start'>
            {
              width < 1440 ? (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra2-1024/image.webp'
                  altDescription='Formulário inicial para abertura de conta PJ'
                  arrayNumbers={[ 450, 420, 520, 500 ]}
                />
              ) : (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra2-1440/image.webp'
                  altDescription='Formulário inicial para abertura de conta PJ'
                  arrayNumbers={[ 450, 420, 520, 600, 700 ]}
                />
              )
            }
          </div>
          <div className='col-12 col-md-6 order-2 order-md-1 order-xl-2 d-flex flex-column justify-content-center'>
            <Title>Pré-cadastro</Title>
            {
              data.texts.map((item: string, index: number) => (
                <div key={index}>
                  { index === data.texts.length - 1 && <br /> }
                  <OrangeIcon size='MD' icon='arrow-right' color='#FF7A00' />
                  <Texts dangerouslySetInnerHTML={{ __html: item }} />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Section>
  )
}
