import React from "react"
import { Section, Title, Text } from "./_style"
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"

export default function Faturamento () {
  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center justify-content-md-end'>
          <div className='col-12 col-md-6 col-xl-5'>
            <Title>Faturamento</Title>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' />
              <Text><strong>Faturou nos últimos 12 meses? </strong>Insira o valor no cadastro.</Text>
            </div>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' />
              <Text><strong>Não faturou? </strong>Insira uma prévia de faturamento.</Text>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
