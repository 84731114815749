import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background: #72370E;

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  @media ${device.desktopLG} {
    padding-top: calc(64px + 73px);
    padding-bottom: calc(64px + 73px);
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  button {
    height: 48px;
  }
`

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5A3217;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
  margin-right: 24px;
`
