import styled, { css } from 'styled-components'
import { white, orange, brand, laranja } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

const bgMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/hero-pj-pro/image.webp'
const bgLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/hero-pj-pro/image.webp'
const bgXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/hero-pj-pro/image.webp'

type buttonProps = {
  reverse?: boolean;
}

export const Section = styled.section`
  min-height: 376px;
  display: flex;
  align-items: center;

  p {
    color: ${laranja.terra};
    margin-bottom: 32px;
  }

  @media(min-width: ${breakpoints.md}) {
    background-image: url(${bgMd});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 360px;
    padding: 64px 0 !important;

    .card-pjpro{
      background-color:#FDF8EE;
      padding: 15px;
      border-radius: 1rem;
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    min-height: 471px;
  }

  @media(min-width: ${breakpoints.xl}) {
    background-image: url(${bgXl});
    min-height: 815px;

    p {
      margin-bottom: 40px;
    }

    .card-pjpro{
      padding: 30px;
    }
  }

`
export const Title = styled.h1`
  color: ${laranja.terra};
  margin-bottom: 16px;

  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  
  span {
    color: ${brand.primary};
    margin-bottom: 8px;
    display: block;

    font-size: 28px;
    font-weight: 700;
    line-height: 33.6px;
  }
  
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 8px;

    font-size: 20px;
    line-height: 24px;

    span {
      color: ${laranja.terra};

      font-size: 40px;
      font-weight: 500;
      line-height: 44px;
    }

  }

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;

    span {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: 32px;
    line-height: 40px;

    span {
      font-size: 40px;
      line-height: 50px;
    }
  }

`
export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(buttonProps: buttonProps) => buttonProps.reverse ? 'transparent' : orange.extra};
  color: ${(buttonProps: buttonProps) => buttonProps.reverse ? orange.extra : white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  border-radius: 8px;
  ${(buttonProps: buttonProps) => buttonProps.reverse && css`
    border: 1px solid #FF7A00;
    margin-top: 16px;
  `}

  @media (min-width: ${breakpoints.lg}) {
    font-size: 14px;
    line-height: 18px;
  }

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${(buttonProps: buttonProps) => buttonProps.reverse ? orange.extra : white};
  }
`
