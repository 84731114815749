import React from 'react'

import * as S from './_styles'
import Faq from 'src/components/Faq'
import pageContext from '../../pageContext.json'

const S7FAQ = () => {
  return (
    <S.Section7Faq id='faq'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title>Perguntas Frequentes</S.Title>
            <Faq
              columns={{
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
              }}
              answerData={pageContext.structuredData.faq}
            />
          </div>
        </div>
      </div>
    </S.Section7Faq>
  )
}

export default S7FAQ
