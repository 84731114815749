import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { Section } from './style'

const Renegotiate = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section className='bg-gray py-5 d-flex align-items-center' role='img' aria-label='Cliente Inter acessando seu Super App dentro de um carro.'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='d-flex justify-content-center mt-3'>
              {width < WIDTH_MD &&
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra8-mobile/image.webp'
                  altDescription='Cliente Inter acessando seu Super App dentro de um carro.'
                  arrayNumbers={[ 300, 300, 300, 300 ]}
                  arrayNumbersHeight={[ 150, 150, 150, 150 ]}
                />}
            </div>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 mt-3 fw-500'>
              Renegocie dívidas sem burocracia
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400 mt-md-3'>
              Conte com o Inter para te ajudar a limpar o seu nome com as melhores condições. Negociamos juntos, e você escolhe as parcelas que cabem no seu bolso, sem comprometer o seu orçamento.
            </p>
            <Link
              title='Consulte e negocie'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to='/negocie/'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  element_action: 'click button',
                  element_name: 'Consulte e negocie',
                  section_name: 'Renegocie dívidas sem burocracia',
                  redirect_url: `${window.location.origin}/negocie/`,
                })
              }}
            >
              Consulte e negocie
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Renegotiate
