import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'
import { ModalContentDispatchT, useModalContent } from '../../context/_ModalContentContext'
import { SideModalDispatchT, useSideModal } from '../../context/_SideModalContext'
import { ViewsEnum } from '../../components/_ModalContentFactory'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

type RecargaCelularProps = {
  sectionId: string;
}

export const RecargaCelular = ({ sectionId }: RecargaCelularProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { setModalContentView }: ModalContentDispatchT = useModalContent()
  const { setIsModalOpen }: SideModalDispatchT = useSideModal()
  const deepLink = 'https://inter-co.onelink.me/Qyu7/f9qebj1r'
  const width = useWidth()
  const titleH2 = 'Faça recarga para qualquer número de celular'

  const arrayNumbers: number[] = [
    312, 336, 456, 552,
  ]

  const arrayHeight: number[] = [
    253, 272, 300, 447,
  ]

  const dataLayer: IDataLayerParams = {
    section: 'dobra_4',
    section_name: titleH2,
    element_action: 'click button',
    element_name: 'Recarregar agora',
  }

  const handleClick = () => {
    sendDatalayerEvent(dataLayer)
    setIsModalOpen(true)
    setModalContentView(ViewsEnum.primaryFlow)
  }

  return (
    <Section className='py-5' id={sectionId}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-6 offset-xl-1 mb-3 order-md-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-1440-recarga/image.webp'
              altDescription='Mulher sorridente sentada no sofá enquanto navega pela internet no celular que acabou de fazer recarga.'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
              className='float-md-right'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 mb-4 mb-md-0'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 mb-md-3 mr-md-n3'>
              Faça recarga para qualquer número de celular
            </h2>
            <h3 className='font-inter fw-700 fs-16 lh-19 fs-lg-18 lh-lg-22 fs-xl-20 lh-xl-25 text-grayscale--500 mb-3'>
              Ficou sem internet ou precisa fazer uma ligação urgente?
            </h3>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mr-n3 mr-md-0 mr-lg-n3 mb-4 mb-xl-5'>
              Coloque créditos no seu celular, de algum familiar ou amigo sem pagar nenhuma taxa! No Super App, você faz{' '}
              <span className='fw-700'>
                recarga Vivo, Claro, Tim{' '}
              </span>
              ou pra qualquer outra operadora e volta a ficar  <span className='text-orange--extra fw-700'>ON</span>!
            </p>
            {
              width < WIDTH_MD
              ? (
                <a
                  href={deepLink}
                  className='btn btn--lg btn-orange--extra text-none w-100 mw-100 mt-2 mt-xl-0'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      element_action: 'click button',
                      section_name: titleH2,
                      element_name: 'Recarregar online',
                      redirect_url: deepLink,
                     })
                  }}
                >
                  Recarregar agora
                </a>
              )
              : (
                <button
                  className='btn btn--lg btn-orange--extra text-none w-100 mw-100 mt-2 mt-xl-0'
                  onClick={handleClick}
                >
                  Recarregar agora
                </button>
              )

            }
          </div>
        </div>
      </div>
    </Section>
  )
}
