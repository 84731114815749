import React from 'react'

import { Section } from './styles'
import ImageWebp from 'src/components/ImageWebp'

export const ConteComUmAdvisor = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 mb-3 mb-md-0'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-3/image.webp'
              altDescription='Conta PJ Enterprise'
              arrayNumbers={[]}
              arrayNumbersHeight={[]}
            />
          </div>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-28 fs-lg-40 lh-lg-44 fw-500'>Conte com um Advisor Enterprise exclusivo</h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 fw-400'>Garanta que um de nossos especialistas esteja sempre disponível para te ajudar em decisões de negócios, recomendar Enterprisedutos Inter, auxiliar em dúvidas sobre o Super App e realmente simplificar a sua rotina.</p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ConteComUmAdvisor
