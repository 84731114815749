import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #161616;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mulher-sorrindo2-inter-black/image.webp') ;
  background-repeat: no-repeat;
  min-height: 648px;

  h2, p {
    font-family: 'Citrina' !important;
  }

  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra-2-768/image.webp') ;
    background-repeat: no-repeat;
    min-height: 415px;
    background-position: left;

  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra-4-1024/image.webp') ;
    background-repeat: no-repeat;
    min-height: 514px ;
    background-position: left;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra-2-1440/image.webp') ;
    background-repeat: no-repeat;
    min-height: 724px;
    background-position: left;
  }


`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 8px;
  color: ${white};
  background: ${orange[500]};
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  margin-top: 32px;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-top: 40px;
  }

`
