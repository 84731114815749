import { breakpoints } from 'src/styles/breakpoints'
import { laranja } from 'src/styles/colors'
import styled from 'styled-components'

const bgSm = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-pro-dobra3/image.webp'
const bgMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-pro-dobra3/image.webp'
const bgLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-pro-dobra3/image.webp'
const bgXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-pro-dobra3/image.webp'

export const Section = styled.section`
  min-height: 600px;
  display: flex;
  align-items: flex-end;
  background-image: url(${bgSm});
  background-repeat: no-repeat;
  background-size: contain;

  h2 {
    margin-bottom: 16px;
    color: ${laranja.terra};
    font-weight: 500;
  }

  p {
    color: ${laranja.terra};
  }

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${bgMd});
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    min-height: 339px;
  }
  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 456px;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${bgXl});
    background-repeat: no-repeat;
    min-height: 648px;
    background-size: 35%;
    background-position: 15% center;
  }
`
