import { MachineProps } from '../../types'

export const posMachine: MachineProps[] = [
  {
    installments: '12',
    installmentPayment: '62,60',
    totalPayment: '751,19',
    beneficts: [
      {
        icon: 'broadcast-mobile',
        description: 'Conexão Wi-Fi e 3G Dual Chip',
      },
      {
        icon: 'deposit-by-boleto',
        description: 'Comprovante impresso',
      },
      {
        icon: 'users',
        description: 'Cadastro de até 10 usuários',
      },
      {
        icon: 'nfc',
        description: 'Pagamento por aproximação',
      },
      {
        icon: 'qr-code',
        description: 'QR code para pagamentos instantâneos',
      },
    ],
  },
]
