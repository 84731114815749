import React from 'react'
import { sendWciDatalayerEvent } from '../../_googleTagging'
import { Section, Button } from './style'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

const ConsignadoInss = () => {
  const width = useWidth(300)

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex justify-content-end align-items-center'>
          {
            width < widths.md && (
              <div className='col-12 mb-3'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inss-hero-360/image.png'
                  altDescription=''
                  arrayNumbers={[]}
                  arrayNumbersHeight={[]}
                />
              </div>
            )
          }
          <div className='col-12 col-md-6 text-right'>
            <h1 className='fs-24 lh-28 fs-md-28 lh-md-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-white fw-500 mb-3'>
              <span className='d-block'>Simule e contrate seu Consignado INSS</span> direto pelo WhatsApp
            </h1>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-20 lh-xl-25 text-white fw-400 mt-3'>
              <span className='d-block'>Empréstimo rápido, seguro e 100%</span> <span className='d-block'>digital. Veja o quanto pode receber</span> AGORA!
            </p>
            <Button onClick={sendWciDatalayerEvent} className='whatsapp_simulation'>Simular pelo Whatsapp
              <IconsSwitch
                icon='ic_whatsapp'
                customPath='brands/'
                className='whatsapp_simulation__icon ml-2'
                size='MD'
                color='white'
                lib='interco'
              />
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ConsignadoInss
