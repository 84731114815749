import React from "react"
import { Section, Title, Text } from "./_style"
import ImageWebp from "src/components/ImageWebp"
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"

export default function Cadastro () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 d-flex justify-content-center align-items-center order-1 order-md-2'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra4/image.webp'
              altDescription=''
              arrayNumbers={[ 350, 350, 400, 450 ]}
              arrayNumbersHeight={[ 350, 350, 400, 450 ]}
            />
          </div>
          <div className='col-12 col-md-6 mt-2 order-2 order-md-1 d-flex flex-column justify-content-center mt-4 mt-md-0'>
            <Title>Cadastro</Title>
            <Text>Esta é a principal etapa da criação da sua conta PJ, por isso trouxemos informações importantes, para que você esteja pronto para começar.</Text>
            <br />
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#72370E' className='mr-2' />
              <Text>Confira a lista de documentos exigidos</Text>
            </div>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#72370E' className='mr-2' />
              <Text>Tenha em mãos a documentação da sua empresa</Text>
            </div>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#72370E' className='mr-2' />
              <Text>Documentos dos sócios, diretores e representantes</Text>
            </div>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#72370E' className='mr-2' />
              <Text>A selfie deverá ser feita pelo Administrador da conta</Text>
            </div>
            <br />
            <Text>Caso não seja você o administrador da conta, fique tranquilo! Todas as etapas anteriores a selfie podem ser executadas por você, mas a selfie precisa ser realizada pelo administrador da empresa.</Text>
          </div>
        </div>
      </div>
    </Section>
  )
}
