import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'

import Check from '@interco/icons/build-v4/orangeds/LG/check'
import ChevronDown from '@interco/icons/build-v4/orangeds/LG/chevron-down'

import * as S from './../style'
interface IFooterProps {
  t: (values: string) => string;
  theme?: string;
  ptURL: string;
  enURL: string;
  i18n: { language: string };
}

type ListProps = {
  country: string;
  icon?: React.ReactNode;
  urlPage: string;
}

const Languages = (props: IFooterProps) => {
  const [ openLanguage, setOpenLanguage ] = useState(false)
  const [ activeLanguage, setActiveLanguage ] = useState(true)
  const [ isIndex, setIsIndex ] = useState(props.i18n.language === 'pt' ? 0 : 1)

  const listLanguage = [
    {
      country: props.t('portuguese'),
      urlPage: props.ptURL,
    },
    {
      country: props.t('inglish'),
      urlPage: props.enURL,
    },
  ]

  const [ isListLanguage, setIsListLanguage ] = useState(listLanguage[isIndex])

  const HandleClickLanguage = (index: number) => {
    setIsIndex(index)
    isIndex === index && setActiveLanguage(true)
  }

  useEffect(() => {
    setIsListLanguage(isListLanguage)
  }, [ isIndex, props.i18n.language, isListLanguage ])

  return (
    <S.Country theme={props.theme}>
      <S.SelectCountry
        className='col-12 px-0'
        isOpen={openLanguage}
      >
        <p className='mb-0'>
          {props.t('language')}
        </p>
        <S.DivFlex
          key={isListLanguage.country}
          onClick={() => {
            setOpenLanguage(!openLanguage)
            setActiveLanguage(!activeLanguage)
            HandleClickLanguage(isIndex)
          }}
        >
          <span>
            {isListLanguage.country}
          </span>
          <ChevronDown
            height={24} width={24}
            color={props.theme === 'dark' ? '#FFFFFF' : '#161616'}
            className={openLanguage ? 'active' : ''}
          />
          {openLanguage &&
            <S.OpenLangHome>
              {listLanguage.map((item: ListProps, index: number) => (
                <S.ListFlag
                  key={item.country}
                  className='col-12'
                >
                  <a
                    href={item.urlPage}
                    className={`${activeLanguage && isIndex === index && 'active'} `}
                    onClick={() => {
                      setOpenLanguage(!openLanguage)
                      HandleClickLanguage(index)
                    }}
                  >
                    <span className={`${activeLanguage && isIndex === index && 'active'} `}>
                      {item.country}
                    </span>
                    <Check
                      height={24}
                      width={24}
                      color={activeLanguage && isIndex === index ? '#ff7a00' : '#FFFFFF'}
                    />
                  </a>
                </S.ListFlag>
              ))}
            </S.OpenLangHome>
          }
        </S.DivFlex>
      </S.SelectCountry>
    </S.Country>
  )
}

export default translate('Footer')(Languages)
