import React, { MouseEvent } from 'react'

import errorImg from 'src/assets/images/erro.png'

import { Container, SetError } from '../../style'

type ErrorStepProps = {
  handleReturn: (evt: MouseEvent<HTMLButtonElement>) => void;
}

const ErrorStep = ({ handleReturn }: ErrorStepProps) => {
  return (
    <Container>
      <SetError>
        <img src={errorImg} alt='Imagem que representa um erro no envio do formulário' />
        <p>
          Infelizmente não foi possível coletar seus dados no momento, por favor, tente mais tarde.
        </p>
        <button
          title='Tentar novamente'
          onClick={handleReturn}
        >
          Tentar novamente
        </button>
      </SetError>
    </Container>
  )
}

export default ErrorStep
