import { breakpoints } from 'src/styles/breakpoints'
import { brand, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

  .react-multi-carousel-track {
    padding-top: 10px;
  }

  .react-multi-carousel-dot {
    button {
      background-color: ${orange[200]};
    }
    &--active {
      button {
        background-color: ${orange.extra};
      }
    }
  }
`

export const Card = styled.div`
  background-color: ${brand.sand};
  border-radius: 16px;
  padding: 24px;
  height: 173px;
  width: 100%;
  position: relative;
  margin-bottom: -5px;
  min-height: 78px;
  margin-top: 40px;

  @media (min-width: ${breakpoints.md}) {

    &.heightData {
      height: 173px;
    }
  }
`

export const Box = styled.span`
  background-color: ${orange.extra};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 24px 5px 24px;
  width: 156px;
  height: 33px;
`

export const IconContainer = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: ${brand.sand};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardComponent = styled.div`
  position: absolute;
  top: -15px;
  left:  -2px;
  width: 100%;
`
