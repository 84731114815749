import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const TitleContainer = styled.div``

export const Title = styled.h1`
  font-size: 32px;
  line-height: 39px;
  
  margin-bottom: 32px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 48px;
  }

  @media ${device.desktopXXL} {
    line-height: 44px;
  }
`

export const Description = styled.p`
  display: none;
  font-size: 18px;
  line-height: 22px;

  margin-bottom: 32px;

  @media ${device.desktopLG} {
    display: block;
  }
`
