import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Styles
import { Card, Section } from './style'
import { cards } from './_cards'

export const Economize = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleLink = (url: string, title: string, id: number) => {
    sendDatalayerEvent({
      section: `dobra_8 + p_${id}`,
      section_name: 'Economize ainda mais no seu dia a dia',
      element_action: 'click button',
      element_name: title,
      redirect_url: url,
    })
    window.open(url, '_blank')
  }

  return (
    <Section>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-12'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-0 mx-n1 text-center'>
              Economize ainda mais no seu dia a dia
            </h2>
          </div>
        </div>

        <div className='row pt-2 pt-md-3'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 2, partialVisibilityGutter: 5 }}
              md={{ items: 3, partialVisibilityGutter: 40 }}
              lg={{ items: 3, partialVisibilityGutter: 50 }}
              xl={{ items: 4, partialVisibilityGutter: 20 }}
              autoPlay
              autoPlaySpeed={3000}
              infinite
            >
              {cards.map((item: typeof cards[0]) => (
                <Card key={item.title}>
                  <div>
                    <div className='mb-3'>
                      {item.icon}
                    </div>
                    <h3
                      role='button'
                      className='font-sora fw-700 fs-16 lh-19 fs-md-18 lh-md-22 fs-lg-24 lh-lg-30 text-grayscale--500 mb-3'
                      onClick={() => handleLink(item.cta, item.title, item.id)}
                    >
                      {item.title}
                    </h3>
                    <p className='fs-12 lh-15 fs-md-14 lh-md-17 fs-lg-18 lh-lg-22 text-grayscale--500 mb-3 mr-n2 mr-md-0'>{item.description}</p>
                  </div>
                  <button
                    className='btn btn-orange--extra text-none w-100 mw-100'
                    onClick={() => handleLink(item.cta, item.title, item.id)}
                  >
                    Aproveitar
                  </button>
                </Card>
                ))}
            </DefaultCarousel>
          </div>

        </div>
      </div>
    </Section>
  )
}
