import React from 'react'

import { Section } from './style'

const KnowMore = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 order-md-last order-lg-first'>
            <img
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-pro-dobra6/image.webp'
              alt='Cartão PJ e celular com o aplicativo Inter empresas.'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'>
              Quer saber mais sobre a Conta PJ Pro Inter Empresas?
            </h2>
            <p className='fs-17 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22'>
              Conte com a gente para tirar todas as suas dúvidas através dos nossos canais oficiais de atendimento.
            </p>
            <p className='fs-17 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-600 mb-0'>
              Para sua comodidade, salve em sua agenda: 0800 940 0007
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default KnowMore
