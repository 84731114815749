import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      QRCodePessoaFisicaMEI2: imageSharp(
        fluid: { originalName: { regex: "/LPmaquininhaPF_qr_code/" } }
      ) {
        fluid(maxWidth: 185, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      QRCodePessoaJuridica2: imageSharp(
        fluid: { originalName: { regex: "/LPmaquininhaPJ_qr_code/" } }
      ) {
        fluid(maxWidth: 185, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      HeroBackgroundGranito: imageSharp(
        fluid: { originalName: { regex: "/new-hero-banner-granito/" } }
      ) {
        fluid(maxWidth: 590, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
