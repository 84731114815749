import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import FerramentasJSON from './data.json'

import { SectionWrapper, Button } from './style'

type FerramentasCarouselProps = {
  image: string;
  title: string;
  decription: string;
}

const InvestPro = () => {
  const ferramentas = FerramentasJSON

  return (
    <SectionWrapper id='tenha-as-melhores-ferramentas-com-o-investpro'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, slidesToSlide: 1 }}
              md={{ items: 1, slidesToSlide: 1 }}
              lg={{ items: 1, slidesToSlide: 1 }}
              xl={{ items: 1, slidesToSlide: 1 }}
            >
              {
              ferramentas.map((item: FerramentasCarouselProps, index: number) => (
                <div key={index} className='carousel-card bg-white rounded-3 py-3'>
                  <div className='row align-items-center'>
                    <div className='col-12 col-md-6 col-lg-5 offset-lg-1 pb-4 mr-xl-5 d-flex justify-content-center'>
                      <ImageWebp
                        arrayNumbers={[ 312, 336, 376, 456 ]}
                        pathSrc={item.image}
                        altDescription={item.title}
                      />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                      <h3 className='fs-24 fs-md-40 lh-25 lh-md-30 lh-md-44 text-grayscale--500 fw-700' dangerouslySetInnerHTML={{ __html: item.title }} />
                      <p className='fs-14 fs-md-18 lh-17 lh-md-22 lh-lg-19 text-grayscale--400 fw-400' dangerouslySetInnerHTML={{ __html: item.decription }} />
                    </div>
                  </div>
                </div>
              ))
            }
            </DefaultCarousel>
            <Button href='https://inter.co/pra-voce/investimentos/renda-variavel/' target='_blank'>Conhecer ferramentas</Button>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default InvestPro
