import React from 'react'

import SuccessImage from 'src/assets/images/sucess-signal-green.svg'
import { Container, SetSent } from '../../style'

type FormProps = {
  page: string;
}

const SentStep = ({ page }: FormProps) => {
  return (
    <Container page={page}>
      <SetSent page={page}>
        <div className='mb-4'>
          <img
            src={SuccessImage}
            alt='Imagem representando sucesso de um envio de formulário'
            width={64}
            height={64}
          />
        </div>
        <p>
          Prontinho! Recebemos os seus dados. Em breve entraremos em contato!
        </p>
      </SetSent>
    </Container>
  )
}

export default SentStep
