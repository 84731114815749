import { device } from 'src/styles/breakpoints';
import { gray, grayscale, orange, white } from 'src/styles/colors';
import styled from 'styled-components';

type SelectLanguages = {
  isOpen?: boolean;
  theme?: string;
}

export const Country = styled.div<SelectLanguages>`

  @keyframes animatedLang {
    from {
      top: 25px;
    }
    to {
      bottom: -20px;
    }
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  @media ${device.tablet} {
    margin-top: 0;
  }
  @media ${device.desktopLG} {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  @media ${device.desktopXL} {
    justify-content: flex-start;
  }

  a, span {
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: ${({ theme }: SelectLanguages) => (theme === 'dark' ? white : grayscale[500])};
    
    &.active {
      color: ${orange.extra} !important;
    }
  }

  p {
    font-size: 14px;
    line-height: 17px;

    @media ${device.desktopLG} {
      margin-bottom: 0;
    }
  }

  svg {
    fill: none !important;

    &:hover {
      fill: none;
    }   
  } 
`

export const SelectCountry = styled.div<SelectLanguages>`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin: 0 12px;
  }

  svg {

    &.active {
      transition: transform 0.3s;
      transform: ${({ isOpen }: SelectLanguages) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }
`

export const DivFlex = styled.div`
  display: flex;
  align-items: center; 
  justify-content: start;
`

export const LangFlag = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const OpenLang = styled.div`
  position: absolute;
  border-radius: 8px;
  width: 140px;
  border: 1px solid ${gray[200]};
  padding: 8px;
  top: 30px;
  background: ${white};
  z-index: 1;
  left: -9px;
  min-height: 80px;
  box-shadow: 0px 24px 64px -8px rgba(22, 22, 22, 0.16);
  transition: opacity 0.3s ease 0s;
  animation: animatedLang 0.3s ease forwards;
`

export const OpenLangHome = styled(OpenLang)`
  left: 108px;
  width: 150px;
`

export const ListFlag = styled.div`
  cursor: pointer;
  margin-bottom: 16px;
  padding: 0;
  display: flex;
  align-items: center;

  a, span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${grayscale[500]};
  }

  &:last-child {
    margin-bottom: 0;
  }
`
