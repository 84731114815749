import React from "react"
import { Section, Texts, Title, Table } from "./_style"

export default function DocumentoCliente () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Title>Documentação do cliente</Title>
            <Texts>Confira aqui a lista de documentos exigida para cada tipo de empresa.</Texts>
          </div>
          <div className='col-12'>
            <Table>
              <tr>
                <th colSpan={2}>LISTA DE DOCUMENTOS</th>
              </tr>
              <tr>
                <td>CONSTITUIÇÃO</td>
                <td>DOCUMENTOS</td>
              </tr>
              <tr>
                <td>Empresas LTDA</td>
                <td>Contrato Social (reconhecimento em cartório)</td>
              </tr>
              <tr>
                <td>Empresários Individuais (EI, MEI, EIRELI)</td>
                <td>Requerimento de Empresário Individual ou Ato Constitutivo</td>
              </tr>
              <tr>
                <td>Condomínios</td>
                <td>Convenção e Ata de Eleição do Síndico</td>
              </tr>
              <tr>
                <td>Empresa SA</td>
                <td>Estatuto e Ata de Eleição da Diretoria</td>
              </tr>
            </Table>
          </div>
          <div className='col-12 mt-4'>
            <Texts>
              <strong>Para o administrador da conta</strong>, também é exigido documento de identificação (RG, CNH física dentro do prazo de validade, 
              RNE/RNM permanente). <strong>Importante:</strong> não é aceita CNH digital.
            </Texts>
            <br />
            <br />
            <Texts>
              <strong>Para empresas que possuem procurador, </strong>, também é exigida procuração em nome da empresa dando plenos poderes de representação a terceiros (registrada em cartório ou com reconhecimento de firma).
            </Texts>
            <br />
            <br />
            <Texts>São aceitos documentos em formato PDF em arquivos de <strong>no máximo 5MB.</strong></Texts>
          </div>
        </div>
      </div>
    </Section>
  )
}
