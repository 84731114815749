import React from "react"
import { Section, Text, Title } from "./_style"
import ImageWebp from "src/components/ImageWebp"
import useWidth from "src/hooks/window/useWidth"
import data from './data.json'
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"

export default function PrimeiroAcesso () {
  const width = useWidth()

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            {
              width < 768 && (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-dobra5-360/image.webp'
                  altDescription=''
                />
            )
            }
            {
              width >= 768 && width < 992 && (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-dobra5-768/image.webp'
                  altDescription=''
                />
            )
            }
            {
              width >= 992 && (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-dobra5/image.webp'
                  altDescription=''
                />
            )
            }
          </div>
          <div className='col-12 col-md-6'>
            <Title>Faça o seu primeiro acesso para ativar a sua conta</Title>
            {
              data.map((item: string, index: number) => (
                <div key={index}>
                  <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' />
                  <Text>{item}</Text>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Section>
  )
}
