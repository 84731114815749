import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { ViewsEnum } from '../../components/_ModalContentFactory'
import { ButtonWithDeepLink } from '../../components/_ButtonWithDeepLink'
import { ModalContentDispatchT, useModalContent } from '../../context/_ModalContentContext'
import { SideModalDispatchT, useSideModal } from '../../context/_SideModalContext'

export const Hero = ({ sectionId }: {sectionId: string}) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { setModalContentView }: ModalContentDispatchT = useModalContent()
  const { setIsModalOpen }: SideModalDispatchT = useSideModal()
  const deepLinkRecarga = 'https://inter-co.onelink.me/Qyu7/bf410g69'
  const deepLinkOutrasRecargas = 'https://inter-co.onelink.me/Qyu7/u6ilg5p0'

  const dataLayerBtnRecharge: IDataLayerParams = {
    section: 'dobra_1',
    section_name: 'Recarga de celular com super vantagens',
    element_action: 'click button',
    element_name: 'Fazer recarga de celular',
  }

  const dataLayerBtnOthers: IDataLayerParams = {
    section: 'dobra_1',
    section_name: 'Recarga de celular com super vantagens',
    element_action: 'click button',
    element_name: 'Fazer outras recargas',
  }

  const handleClick = (dataLayer: IDataLayerParams, view: ViewsEnum) => {
    sendDatalayerEvent(dataLayer)
    setModalContentView(view)
    setIsModalOpen(true)
  }

  return (
    <Section
      className='py-5 d-flex align-items-end align-items-md-center'
      id={sectionId}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 mb-md-3'>
              Recarga de celular com super vantagens
            </h1>
            <div className='mb-3 pb-2 pb-xl-3'>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
                Faça sua <strong>recarga online</strong> para celular de qualquer operadora pelo Super App e ganhe GBs de bônus.
                <br /><br />
                Aproveite vantagens também na recarga de TV e de transporte.
              </p>
            </div>
            <ButtonWithDeepLink
              href={deepLinkRecarga}
              className='btn btn--lg btn-orange--extra text-white text-none w-100 mw-100'
              onClick={() => handleClick(dataLayerBtnRecharge, ViewsEnum.primaryFlow)}
              onLinkClick={() => {
                sendDatalayerEvent({
                  ...dataLayerBtnRecharge,
                  redirect_url: deepLinkRecarga,
                })
              }}
            >
              Fazer recarga de celular
            </ButtonWithDeepLink>

            <ButtonWithDeepLink
              href={deepLinkOutrasRecargas}
              onLinkClick={() => {
                sendDatalayerEvent({
                  ...dataLayerBtnOthers,
                  redirect_url: deepLinkOutrasRecargas,
                })
              }}
              className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 text-none mt-3 w-100 mw-100'
              onClick={() => handleClick(dataLayerBtnOthers, ViewsEnum.heroSectionSecondaryFlow)}
            >
              Fazer outras recargas
            </ButtonWithDeepLink>
          </div>
        </div>
      </div>
    </Section>
  )
}
