import React from 'react'

import LinkTo from 'src/components/LinkTo/LinkTo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'
import { Button, ButtonReverse, CardBox, Section } from './styles'

export const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_1',
      section_name: 'Conta PJ Enterprise: Pra sua empresa ir além',
      element_action: 'click button',
      element_name: 'Abrir conta PJ',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
      '_blank',
    )
  }
  const breakpoint = useBreakpoint()

  const titleColor = breakpoint.up('md') ? '#72370E' : '#EA7100'

  return (
    <Section
      className='d-flex align-items-center justify-content-between'
      id='conta-pj-enterprise'
      role='img'
      aria-label='Empreendedor sorridente, cliente PJ Enterprise, acessando o Super App Inter Empresas no celular.'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <CardBox>
              <h1
                className={`fw-500 font-sora fs-28 lh-33 fs-md-40 lh-md-44 fs-lg-48 lh-lg-52 fs-xl-72 lh-xl-70 ${titleColor} mb-3`}
              >
                Conta PJ Enterprise

                <span className='fs-20 lh-24 fs-lg-24 lh-lg-28 fs-xl-40 lh-xl-44 fw-500 mt-2 mt-md-2 mb-3 d-block'>
                  Pra sua empresa ir além
                </span>
              </h1>
              <p
                className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22  mb-4 pb-2 pb-xl-3 mr-md-n2 mt-3'
              >
                Simplifique ainda mais a rotina do seu negócio com um Advisor exclusivo no Super App e mais benefícios Inter Empresas.
              </p>

              <Button
                className='fs-10 lh-12  w-100 '
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name:
                      'Abrir Conta PJ',
                    element_action: 'click button',
                    element_name: 'Abrir Conta PJ',
                  })
                  handleLink()
                }}
              >
                Abrir Conta Enterprise
              </Button>
              <LinkTo to='#beneficios'>
                <ButtonReverse
                  className='fs-10 lh-12 d-xl-block w-100'
                  onClick={() =>
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name:
                      'Conta PJ Enterprise: Pra sua empresa ir além',
                    element_action: 'click scroll',
                    element_name: 'Conheça a conta PJ Enterprise',
                  })
                }
                >
                  Conheça a Conta PJ Enterprise
                </ButtonReverse>
              </LinkTo>
            </CardBox>
          </div>
        </div>
      </div>
    </Section>
  )
}
