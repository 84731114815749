import React from 'react'

import { PosInterPag } from './_PosGranito'
import { MPosInterPag } from './_MPosGranito'

import { Section } from './style'

type MachinesProps = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const Machines = ({ setIsModalOpen }: MachinesProps) => {
  return (
    <Section id='cartao-inter'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4 mb-xl-5'>
            <h2
              className='fs-24 lh-30 fs-lg-28 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center mb-4 mb-lg-0'
            >
              Sua empresa com a <span className='d-block d-md-inline'>maquininha perfeita!</span>
            </h2>
          </div>
          <div className='d-xl-flex justify-content-between'>
            <PosInterPag setIsModalOpen={setIsModalOpen} />
            <MPosInterPag setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </div>
    </Section>
  )
}
