import { breakpoints } from "src/styles/breakpoints"
import { grayscale, orange, white } from "src/styles/colors"
import styled from "styled-components"

type ImageProps = {
  image?: string;
  bgColor?: string;
}

export const SectionExperiences = styled.div`
  background: ${grayscale[500]};
  padding: 40px 0;
  min-height: 907px;
  background: ${white};

  @media (min-width: ${breakpoints.md}){
    background: ${grayscale[100]};
    min-height: 938px;
  }
  @media (min-width: ${breakpoints.lg}){
    min-height: 1113px;
    padding-top: 64px;
  }
  @media (min-width: ${breakpoints.xl}){
    min-height: 1185px;
    padding-top: 96px;
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background: ${orange.extra};
      }
    }
  }

  .react-multiple-carousel__arrow--left {
    left: calc(20% + 1px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc(20% + 1px)
  }
`

export const CardCarouselExperiences = styled.div`
  height: 679px;
  border-radius: 12px 16px;
  margin-right: 16px;
  background-color: ${grayscale[100]};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}){
    justify-content: start;
    height: 730px;
    background: ${white} url(${(props: ImageProps) => props.image});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    padding-top: 160px;
  }
  @media (min-width: ${breakpoints.lg}){
    height: 790px;
    padding-top: 190px;
  }
  @media (min-width: ${breakpoints.xl}){
    padding-top: 180px;
  }
  @media (min-width: ${breakpoints.xxxl}){
    padding-top: 220px;
  }

  button, a {
    margin-bottom: 32px;
    background: ${orange[500]};

    @media (min-width: ${breakpoints.md}){
      margin-bottom: 24px;
    }
    @media (min-width: ${breakpoints.lg}){
      margin-bottom: 30px;
    }
    @media (min-width: ${breakpoints.xl}){
      margin-bottom: 40px;
    }
    @media (min-width: ${breakpoints.xxxl}){
      margin-bottom: 24px;
    }
  }

  .content-text {
    height: 590px;

    @media (min-width: ${breakpoints.md}){
      height: 480px;
    }
    @media (min-width: ${breakpoints.lg}){
      height: 500px;
    }
  }
`

export const RadiusBgColor = styled.div`
  width: 100%;
  background: ${(props: ImageProps) => props.bgColor};
  height: 26px;
  border-radius: 12px 12px 0 0;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.md}){
    display: none;
  }
`
