import React from 'react'

import ShoppingBagIcon from '@interco/icons/build-v4/orangeds/MD/shopping-bag'
import GiftIcon from '@interco/icons/build-v4/orangeds/MD/gift'
import TravelIcon from '@interco/icons/build-v4/orangeds/MD/travel'
import ShellBoxIcon from '@interco/icons/build-v4/orangeds/MD/shell-box'
import DuoIcon from '@interco/icons/build-v4/orangeds/MD/duo-gourmet'
import InterTagIcon from '@interco/icons/build-v4/orangeds/MD/inter-tag'
import { grayscale } from 'src/styles/colors'

export const cards = [
  {
    id: 1,
    title: 'Shopping',
    description: 'Ganhe cashback na Amazon e em outras centenas de lojas.',
    icon: <ShoppingBagIcon width={24} height={24} color={grayscale[500]} />,
    cta: 'https://inter-co.onelink.me/Qyu7/qft6slaj',
  },
  {
    id: 2,
    title: 'Gift Cards',
    description: 'Economize no Ifood, Uber, jogos, streamings e mais.',
    icon: <GiftIcon width={24} height={24} color={grayscale[500]} />,
    cta: '  https://inter.co/pra-voce/gift-card/',
  },
  {
    id: 3,
    title: 'Viagens',
    description: 'Aproveite cashback em passagens, hospedagens e mais.',
    icon: <TravelIcon width={24} height={24} color={grayscale[500]} />,
    cta: 'https://intergo.app/5af968c3',
  },
  {
    id: 4,
    title: 'Shell Box',
    description: 'Economize ganhando dinheiro de volta na hora de abastecer.',
    icon: <ShellBoxIcon width={24} height={24} color={grayscale[500]} />,
    cta: 'https://inter.co/pra-voce/shell-box/',
  },
  {
    id: 5,
    title: 'Duo Gourmet',
    description: 'Peça 2 pratos e pague só 1 nos melhores restaurantes.',
    icon: <DuoIcon width={24} height={24} color={grayscale[500]} />,
    cta: 'https://inter.co/pra-voce/duo-gourmet/',
  },
  {
    id: 6,
    title: 'Tag',
    description: 'A etiqueta que libera automaticamente a cancela em pedágios.',
    icon: <InterTagIcon width={24} height={24} color={grayscale[500]} />,
    cta: 'https://blog.inter.co/inter-tag-sua-cobranca-automatica-de-pedagio',
  },
]
