import React from "react"
import { Section, Title, Text } from "./_style"
import ImageWebp from "src/components/ImageWebp"
import data from './data.json'
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"

export default function Participantes () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 order-md-1'>
            <Title>Participantes</Title>
          </div>
          <div className='col-12 col-md-6 order-md-4 order-xl-3 d-flex align-items-center justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra8/image.webp'
              altDescription=''
              arrayNumbers={[ 350, 320, 406 ]}
              arrayNumbersHeight={[ 250, 220, 289 ]}
            />
          </div>
          <div className='col-12 col-xl-6 order-md-2'>
            <Text>Hora de cadastrar <strong>os participantes e sua porcentagem de participação na empresa</strong>
              -Para empresário individual, a porcentagem do sócio será sempre de 100%. <br /><br />
              Os dados pedidos de cada participante da empresa são:
            </Text>
            <div className='d-none d-xl-block'>
              {
              data.map((item: string, index: number) => (
                <div key={index}>
                  <OrangeIcon icon='arrow-right' size='SM' color='#EA7100' />
                  <Text dangerouslySetInnerHTML={{ __html: item }} />
                </div>
              ))
            }
            </div>
          </div>
          <div className='col-12 col-md-6 order-md-3 order-xl-4 d-xl-none'>
            {
              data.map((item: string, index: number) => (
                <div key={index}>
                  <OrangeIcon icon='arrow-right' size='SM' color='#EA7100' />
                  <Text dangerouslySetInnerHTML={{ __html: item }} />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Section>
  )
}
