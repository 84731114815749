import React from 'react'

import useUtms from 'src/hooks/useUtms'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ScrollTo from 'src/components/ScrollTo'

import { Section, Title, ButtonLink } from './style'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: 'LP Pj Pro' })

  return (
    <Section
      className='py-4'
      role='img'
      aria-label='Empreendedora sorridente, cliente PJ Pro, em seu ambiente de trabalho.'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 card-pjpro'>
            <Title>
              <span>Conta <strong>PJ PRO:</strong></span>
              Tudo que sua empresa precisa
            </Title>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-12 lh-xl-22'>
              Leve o relacionamento empresarial com Inter para o próximo nível. Tenha um Advisor exclusivo sempre que precisar e aproveite todas as vantagens de ser Pro!
            </p>
            <ButtonLink
              href={utmLink}
              target='__blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_01',
                element_action: 'click button',
                element_name: 'Abrir Conta PJ',
                section_name: 'Conta PJ PRO: Tudo que sua empresa precisa',
                redirect_url: utmLink,
              })}
            >
              Abrir Conta PJ
            </ButtonLink>
            <ScrollTo
              to='#benefits'
              section='dobra_01'
              sectionName='Conta PJ PRO: Tudo que sua empresa precisa'
              elementName='Conheça a Conta PJ Pro'
            >
              <ButtonLink reverse>
                Conheça a Conta PJ Pro
              </ButtonLink>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
