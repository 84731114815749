import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled, { css } from 'styled-components'

const skeleton = css`
  @keyframes skeleton-loading {
    0% {
      background: rgb(120,119,119);
      background: linear-gradient(79deg, rgba(120,119,119,1) 0%, rgba(172,172,172,1) 19%, rgba(255,255,255,1) 100%);
    }
    100% {
      background: rgb(120,119,119);
      background: linear-gradient(216deg, rgba(120,119,119,1) 0%, rgba(172,172,172,1) 19%, rgba(255,255,255,1) 100%);
    }
  }
`

export const Section2OportunidadeParaComprarCripto = styled.section`
  ${skeleton}

  background-color: ${grayscale[100]};
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 35.5px 0;  
  }
  
  @media ${device.desktopLG} {
    padding: 60px 0;
  }

  @media ${device.desktopXXL} {
    padding: 78.5px 0; 
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${device.tablet} {
    gap: 0;
    flex-direction: row;
    align-items: center;
  }
`

export const VideoThumbnail = styled.img`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 12px;
  color: transparent;


  @media ${device.desktopLG} {
    width: 98%;
  }

  @media ${device.desktopXXL} {
    width: 92%;
  }
`

export const TextsContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, arial;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  color: ${grayscale[500]};

  margin-bottom: 32px;

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 48px;
  }
`

export const CourseAdvantagesList = styled.ul`
  gap: 8px;
  margin-bottom: 0;
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  
  @media ${device.tablet} {
    justify-content: space-between;
    width: 88%;
  }

  @media ${device.desktopLG} {
    width: 93%;
  }
`

export const CourseAdvantage = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 30%;
`

export const CourseAdvantageDescription = styled.p`
  font-family: Inter, sans-serif, arial;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${grayscale[400]};

  margin-bottom: 0;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const CourseAdvantageIcon = styled.div`
  margin-bottom: 16px;

  .icon {
    width: 32px;
    height: 32px;

    @media ${device.desktopLG} {
      width: 48px;
      height: 48px;
    }
  }
`
