import React from 'react'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ScrollTo from 'src/components/ScrollTo'

import DataAws from 'src/assets/data/solicitar-inter-pag/document.json'

import * as S from './style'

type WantToKnowMoreProps = {
  icon: string;
  text: string;
}

const CardAbout = (item: WantToKnowMoreProps) => (
  <div className='col-12 col-md-6 col-xl-4 mb-1 mb-md-4' key={item.text}>
    <S.Card className='bg d-flex align-items-center'>
      <div className='mr-3'>
        <OrangeDsIcon icon={item.icon} size='MD' color='#FF7A00' />
      </div>
      <p className='fs-14 fs-xl-16 lh-16 lh-xl-20 mb-0'>{item.text}</p>
    </S.Card>
  </div>
)

const WantToKnowMore = () => {
  return (
    <S.Section className='d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-28 fs-xl-40 lh-28 lh-md-33 lh-xl-44 fw-500 font-citrina mt-3 mb-3 text-center'>
              <span className='d-md-block d-xl-inline'><span className='orange'>Quer saber mais?</span> Conheça algumas </span>das nossas vantagens:
            </h2>
          </div>
          <div className='bg-white col-lg-10 col-xl-12 offset-lg-1 offset-xl-0'>
            <div className='row'>
              <CardAbout icon='sun' text={DataAws?.firstDescription} />
              <CardAbout icon='shopping-bag' text={DataAws?.thirdDescription} />
              <CardAbout icon='credit' text={DataAws?.fifthDescription} />
              <CardAbout icon='smartphones' text={DataAws?.secondDescription} />
              <CardAbout icon='users' text={DataAws?.fourthDescription} />
              <CardAbout icon='machine' text={DataAws?.sixthDescription} />
            </div>
          </div>
          <div className='d-flex justify-content-center w-100'>
            <ScrollTo
              styles='fs-14 fw-600 btn btn-orange--extra mx-auto text-white text-none mt-3'
              to='#hero'
              section='dobra_02'
              sectionName='Inter Pag: A solução completa para suas vendas'
              elementName='Solicitar maquininha'
              title='Solicitar maquininha'
            >
              Solicitar maquininha
            </ScrollTo>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default WantToKnowMore
