import { graphql, useStaticQuery } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
  query {
    winLogoWhite: imageSharp(fluid: {originalName: { regex: "/win-logo-white/" }}) {
      fluid(maxWidth: 498, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
    winLogoDark: imageSharp(fluid: {originalName: { regex: "/win-logo-dark/" }}) {
      fluid(maxWidth: 208, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
    especialistasTrabalhando: imageSharp(fluid: {originalName: { regex: "/bg-especialistas-trabalhando/" }}) {
      fluid(maxWidth: 312, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
    especialistasTrabalhandoMd: file(relativePath: { eq: "bg-especialistas-trabalhandoMd.png" }) {
      childImageSharp {
        fluid(maxWidth: 619, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }           
  }
`)

  return pageQuery
}

export default PageQuery
