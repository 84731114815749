import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import { data } from './_data'
import { Circle, Section } from './styles'

export const ComoMinhaEmpresa = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_6',
      section_name: 'Como minha empresa pode se tornar PJ Enterprise',
      element_action: 'click button',
      element_name: 'Abrir conta PJ',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
      '_blank',
    )
  }
  const breakpoint = useBreakpoint()

  const enterpriseLabel = breakpoint.up('md') ? 'Enterprise' : 'ENTERPRISE'

  return (
    <Section id='criterios'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-5 mb-4 pb-3 mb-md-0 pb-md-0'>
            <h2 className='fw-600 text-white fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3'>
              Como minha empresa pode se tornar PJ {enterpriseLabel}?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-0 mb-md-4 pb-md-3 mr-md-n2'>
              Se sua empresa se encaixar em pelo menos um dos critérios ao lado, passa a usufruir das vantagens de ser PJ Enterprise, sem custos ou necessidade de cadastro.
            </p>
            <button className='btn btn-orange--extra text-none rounded-3 w-100 mw-100 d-none d-md-block' onClick={handleLink}>
              Abrir conta PJ
            </button>
          </div>

          <div className='col-12 col-md-6 col-xl-5 offset-xl-2'>
            <div className='mb-4 mb-md-0 pb-3 pb-md-0'>
              {data?.map((item: typeof data[0], index: number) => (
                <div
                  key={item.title}
                  className={`d-flex align-items-center ${index !== 0 &&
                    'mt-4 pt-2'}`}
                >
                  <Circle>
                    {item.icon.sm}
                  </Circle>
                  <p
                    className='font-sora fs-16 lh-20 fs-md-14 lh-md-17 fs-lg-16 lh-lg-20 text-white mb-0 mr-n2 mr-md-n4 mr-lg-n1'
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                </div>
              ))}
            </div>
            <button
              className='btn btn-orange--extra text-none rounded-3 w-100 mw-100 d-md-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_6',
                  section_name:
                    'Abrir conta PJ',
                  element_action: 'click scroll',
                  element_name: 'Abrir conta PJ',
                })
                handleLink()
              }}
            >
              Abrir conta PJ
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
