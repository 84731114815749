import React from 'react'

import bandeirasCartoes from '../../assets/images/bandeirasCartoes.png'
import { ImgWebp } from 'src/styles/imgWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { posMachine } from '../../assets/data/_posMachine'
import { MachineBenefictsProps, MachineProps } from '../../types'
import { Button, POSGranito } from './style'

type PosProps = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const PosInterPag = ({ setIsModalOpen }: PosProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <POSGranito className='col-12 col-xl-6 mt-xl-5'>
      <div className='row align-items-md-center align-items-xl-start'>
        <div className='order-xl-first col-md-4 col-lg-3 offset-lg-2 col-xl-4 offset-xl-1 mb-4 mb-md-0 d-flex flex-column align-items-center pl-xl-0'>
          <ImgWebp
            breakPointsSize={{ sm: '70%', md: '100%', xl: '230px' }}
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/pos-inter-pag/image.webp'
            alt='Imagem de uma maquininha de cartão POS Inter Pag'
          />
          <span className='fs-10 lh-12 fw-400 text-grayscale--400'>
            imagem ilustrativa
          </span>
        </div>

        <div className='col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-7 offset-xl-0 pr-lg-0 pl-lg-2'>
          <div>
            <h3 className='fs-24 lh-30 fw-600 text-grayscale--500 mb-4'>
              POS Inter Pag
            </h3>
            <img src={bandeirasCartoes} className='mb-3 imgCartoes' />
          </div>
          <div className='mr-lg-n4 mr-xl-0'>

            {posMachine.map((data: MachineProps, index: number) => (
              <div key={index}>
                {data.beneficts.map((item: MachineBenefictsProps) => (
                  <div
                    className='py-2 d-flex align-items-center'
                    key={item.icon}
                  >
                    <span className='d-block'>
                      <OrangeIcon
                        size='MD'
                        color='#FF7A00'
                        icon={item.icon}
                      />
                    </span>
                    <span className='fs-16 lh-19 text-grayscale--500 ml-3 d-block'>
                      {item.description}
                    </span>
                  </div>
                    ))}

                <Button className='d-flex justify-content-start justify-content-lg-start'>
                  <button
                    className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0'
                    title='Solicite orçamento'
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        section_name: 'Sua empresa com a maquininha perfeita! - POS Inter Pag',
                        element_action: 'click button',
                        element_name: 'Solicite orçamento',
                      })
                    }}
                  >
                    Solicite orçamento
                  </button>
                </Button>
              </div>
              ))}
          </div>
        </div>
      </div>
    </POSGranito>
  )
}
