import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }
`

export const DomineOMundoDeCriptoAgoraSection = styled.section`
  background-color: white;
  padding: 40px 0;

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL} {
    padding: 96px 0;
  }
`

export const Content = styled.div``

export const ExclusiveBonusTag = styled.img`
  height: 29px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    height: 32px;
  }

  @media ${device.desktopLG} {
    height: 40px;
  }

  @media ${device.desktopXXL} {
    height: 48px;
  }
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, arial;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: ${grayscale[500]};

  margin-bottom: 24px;

  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 53px;
  }
`

export const Description = styled.p`
  font-family: Inter, sans-serif, sans-serif, arial ;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[400]};

  margin: 0;

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${grayscale[200]};
  background-color: white;
  border-radius: 16px;



  .input {
    label {
      color: ${grayscale[500]};
    }
  }

  .accept_terms {
    font-family: Inter, sans-serif, arial;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: ${grayscale[500]};

    u {
      color: ${orange[500]};
      text-decoration: underline;
    }
  }
`
