import { brand, grayscale, laranja, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${grayscale[100]};

  .react-multi-carousel-dot {
    button {
      background-color: ${orange[200]};
    }
    &--active {
      button {
        background-color: ${orange.extra};
      }
    }
  }

  h3 {
    color: ${laranja.terra};
  }
`

export const LinkButton = styled.a`
  background: ${brand.primary};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  color: ${white};
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    color: ${white};
  }
`

export const Button = styled(LinkButton.withComponent('button'))`

`
