import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import { graphiteOne } from 'src/styles/newColors'
import styled from 'styled-components'

export const Container = styled.div`
  width: auto;
`

export const UL = styled.ul`
  list-style: none;
`

type LiProps = {
  activeItem: boolean;
  activeColor?: string;
  border?: boolean;
  bgColorRadius?: boolean;
}

type ButtonProps = {
  color: string;
}

export const LI = styled.li<LiProps>`
  position: relative;
  margin: 0 10px;

  @media ${device.tablet} {
    margin: 0 20px;
  }
  @media ${device.desktopLG} {
    margin: 0 20px;
  }

  &:first-child {
    hr {
      border-radius: 25px 0 0 0;
    }
  }

  &:last-child  {
    hr {
      border-radius: 0 25px 0 0;
    }
  }

  Button {
    position: relative;
    z-index: 1;
    
    ${(props: LiProps) => props.bgColorRadius
    ? ` 
      border-radius: 20px; 
      background: #F5F6FA;
      width: auto;
      height: 40px;
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;
      word-break: break-all;
      white-space: nowrap;

      h4,h3 {
        color: #161616;
        font-weight: 400;
      }
    ` : '' }

    ${(props: LiProps) => props.activeItem && props.bgColorRadius ? `
      background: ${graphiteOne};
      
      h4, h3 {
        color: ${props.activeColor || orange.extra};
        font-weight: 600;
      }
      ` : ''
    }
    
    ${(props: LiProps) => props.activeItem && `
  
    h4, h3 {
      color: ${props.activeColor || orange.extra} !important;
    }

    ${!props.border ? `border-bottom: 4px solid ${props.activeColor || orange.extra};` : '' }
    &::after {
      content: "";
      ${props.border ? 'display: block;' : '' }
      ${props.border ? 'height: 4px;' : '' }
      ${props.border ? 'width: 100%;' : '' }
      ${props.border ? 'border-radius: 25px 25px 0 0;' : '' }
      ${props.border ? `background: ${props.activeColor};` : '' }
      ${props.border ? 'margin-top: 10px;' : '' }
     `}
    }
  `

export const Button = styled.button<ButtonProps>`
  border: none;
  background: transparent;
  padding: 0;
  font-weight: 600;
  text-align: left;
  width: auto;
  color: ${(props: ButtonProps) => props.color};

  &:focus {
    outline: 0px;
  }
`

export const SlideBar = styled.hr`
  border: none;
  position: absolute;
  z-index: 0;
  height: 4px;
  background: ${grayscale[100]};
  top: 17px;
  width: 100%;
`
