import React, { Dispatch, SetStateAction } from 'react'

import * as S from './_styles'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import { Button } from '@interco/inter-ui/components/Button'
import { formatStringAsCurrency } from 'src/shared/helpers'

type SimulatorProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const MIN_SIMULATION_VALUE = 100

export const Simulator = ({ setIsOpen }: SimulatorProps) => {
  const [ value, setValue ] = React.useState<string>('')
  const [ activeInput, setActiveInput ] = React.useState<boolean>(false)
  const [ sendDatalayerEvent ] = useDataLayer()
  const widh = useWidth()

  const floatValue = (Number(value.replace(/\D/g, '')) / 100) || 0
  const BelowMinValue = floatValue < MIN_SIMULATION_VALUE

  const handleInputSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    const treatedValue = event.target.value.replaceAll(/\D/g, '')
    const inputLenght = treatedValue.length
    if (inputLenght > 3) {
      event.target.style.width = inputLenght + 'ch'
      return
    }
    event.target.style.width = '3ch'
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!activeInput) setActiveInput(true)

    handleInputSize(event)

    const currencyValue = formatStringAsCurrency(event.target.value)
    setValue(currencyValue)
  }

  const simulateAppButton = widh > 768
    ? (
      <Button
        fullWidth
        variant='link'
        className='simulate_app'
        onClick={() => {
          setIsOpen(true)
          sendDatalayerEvent({
            section: 'dobra_01',
            section_name: 'Bora desapertar',
            element_action: 'click button',
            element_name: 'Já sou correntista, quero simular no app',
          })
        }}
      >
        Já sou correntista, quero simular no app
      </Button>
    ) : (
      <Button
        fullWidth
        as='a'
        href='https://intergo.app/3c628462'
        target='_blank'
        variant='link'
        className='simulate_app'
        onClick={() => sendDatalayerEvent({
          section: 'dobra_01',
          section_name: 'Bora desapertar',
          element_action: 'click button',
          element_name: 'Já sou correntista, quero simular no app',
          redirect_url: 'https://intergo.app/3c628462',
        })}
      >
        Já sou correntista, quero simular no app
      </Button>
    )

  return (
    <S.SimulatorWrapper>
      <S.Simulator>
        <S.SimulatorTitle>
          Qual é seu saldo no FGTS?
        </S.SimulatorTitle>
        <S.SimulatorPrice>
          <S.SimulatorPriceCurrency>R$</S.SimulatorPriceCurrency>
          <S.SimulatorPriceInput
            type='text'
            placeholder='0,00'
            onChange={handleInputChange}
            value={value}
            maxLength={13}
          />
          {(BelowMinValue && activeInput) && <S.Error>valor mínimo R${MIN_SIMULATION_VALUE},00</S.Error>}
        </S.SimulatorPrice>
        <UTMLink
          customParameter={`saldo=${value.replace(/\./g, '')}`}
          link='SIMULADOR_ANTECIPACAO_FGTS'
          text='Simular minha antecipação'
          btncolor={`${(BelowMinValue) && 'disabled'} simulate_site btn text-white btn--lg btn-orange--extra text-none rounded-3`}
          sectionName='Bora desapertar'
          dobra='dobra_01'
        />
        {simulateAppButton}
      </S.Simulator>
      <S.SimulatorDecoration>
        <source
          media='(min-width: 1440px)'
          srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo-1440/image.webp'
        />
        <source
          media='(min-width: 1024px)'
          srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo-1024/image.webp'
        />
        <source
          media='(min-width: 768px)'
          srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo-768/image.webp'
        />
        <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/afismo-360/image.webp' />
      </S.SimulatorDecoration>
    </S.SimulatorWrapper>
  )
}

export default Simulator
