import React from "react"
import { Section, Title, Texts } from "./_style"
import ImageWebp from "src/components/ImageWebp"
import useWidth from "src/hooks/window/useWidth"

export default function Administrador () {
  const width = useWidth()

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 d-flex justify-content-center justify-content-lg-start'>
            {
              width < 768 ? (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-dobra10-mobile/image.webp'
                  altDescription=''
                />
              ) : (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-dobra10-web/image.webp'
                  altDescription=''
                />
              )
            }
          </div>
          <div className='col-12 col-md-6 d-flex flex-column justify-content-center'>
            <Title>Administrador da conta</Title>
            <Texts>Inclua quantos participantes foremnecessários na <strong>lista de cadastro</strong> e escolha o administrador da conta, apessoa que terá plenos poderes sobre agestão da sua <strong>Conta Digital PJ do Inter.</strong></Texts>
          </div>
        </div>
      </div>
    </Section>
  )
}
