import React, { useEffect, useState } from 'react'
import { InjectedTranslateProps, translate } from 'react-i18next'
import Languages from './languages/_index'

import Check from '@interco/icons/build-v4/orangeds/LG/check'
import ChevronDown from '@interco/icons/build-v4/orangeds/LG/chevron-down'

import iconBrazil from './images/icon-brazil.svg'
import iconUSA from './images/icon-usa.svg'

import * as S from './style'
interface IFooterProps extends InjectedTranslateProps {
  t: (values: string) => string;
  theme?: string;
  ptURL: string;
  enURL: string;
  i18n: { language: string };
}

type ListProps = {
  country: string;
  icon?: React.ReactNode;
  urlPage: string;
}

const listLang = [
  {
    country: 'Brasil',
    icon: <img src={iconBrazil} alt='Brazil flag' />,
    urlPage: '/',
  },
  {
    country: 'USA',
    icon: <img src={iconUSA} alt='USA flag' />,
    urlPage: 'https://us.inter.co/',
  },
]

const PagesLanguages = (props: IFooterProps) => {
  const [ openLang, setOpenLang ] = useState(false)
  const [ active, setActive ] = useState(true)
  const [ isIndex, setIsIndex ] = useState(props.i18n.language === 'pt' ? 0 : 1)
  const [ isList, setIsList ] = useState(listLang[isIndex])

  const HandleClick = (index: number) => {
    setIsIndex(index)
    isIndex === index && setActive(true)
  }

  useEffect(() => {
    setIsList(isList)
  }, [ isIndex, props.i18n.language, isList ])

  const [ pageHaveEnglishContent, setPageHaveEnglishContent ] = useState(true)

  useEffect(() => {
    if (!window.location.href.includes('/en/') && !props.enURL) {
      setPageHaveEnglishContent(false)
    }
  }, [])

  return (
    <>
      <S.Country theme={props.theme}>
        <div className='col-12 col-lg-8 col-xl-7 pl-0'>
          <p>
            {props.t('country')}
          </p>
        </div>
        <S.SelectCountry
          isOpen={openLang}
          className='col-12 col-lg-4 pl-0'
        >
          <S.DivFlex
            key={isList.country}
            onClick={() => {
              setOpenLang(!openLang)
              setActive(!active)
              HandleClick(isIndex)
            }}
          >
            <S.LangFlag>
              {isList.icon}
            </S.LangFlag>
            <span>
              {isList.country}
            </span>
            <ChevronDown
              height={24} width={24}
              color={props.theme === 'dark' ? '#FFFFFF' : '#161616'}
              className={openLang ? 'active' : ''}
            />
          </S.DivFlex>
          {openLang &&
            <S.OpenLang>
              {listLang.map((item: ListProps, index: number) => (
                <S.ListFlag
                  key={item.country}
                  className='col-12'
                >
                  <div>
                    {item.icon}
                  </div>
                  <a
                    href={item.urlPage}
                    className={`${active && isIndex === index && 'active'} `}
                    onClick={() => {
                      setOpenLang(!openLang)
                      HandleClick(index)
                    }}
                  >
                    <span className={`${active && isIndex === index && 'active'} `}>
                      {item.country}
                    </span>
                  </a>
                  <Check
                    height={24}
                    width={24}
                    color={active && isIndex === index ? '#ff7a00' : '#FFFFFF'}
                  />
                </S.ListFlag>
                ))}
            </S.OpenLang>
          }
        </S.SelectCountry>
      </S.Country>
      {pageHaveEnglishContent && (
        <Languages
          theme={props.theme}
          ptURL={props.ptURL}
          enURL={props.enURL}
        />)
      }
    </>
  )
}

export default translate('Footer')(PagesLanguages)
