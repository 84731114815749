import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import { Section, Title } from './_styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Button } from '@interco/inter-ui/components/Button'

const S5ESimplesRapidoSeguro = () => {
  const [ sendDataLayer ] = useDataLayer()
  return (
    <Section
      id='e-simples-rapido-e-seguro-ter-bitcoin-no-inter'
    >
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-6 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-tudo-sobre-cripto-section-5-image/image.webp'
              arrayNumbers={[ 250, 300, 300, 400, 500 ]}
              altDescription='Imagem de celular mostrando o Super App do Inter com várias criptomoedas e ao lado, o logo da B3.'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 d-flex flex-column justify-content-center'>
            <Title>É simples, rápido e seguro ter Bitcoin no Inter.</Title>
            <p className='text-grayscale--400 fs-16 lh-22 fs-md-18 lh-md-24'>
              Conte com a custódia da B3, a Bolsa de Valores do Brasil para garantir a segurança e a integridade dos seus investimentos.
            </p>
            <Button
              as='a'
              fullWidth
              href='#aprenda-tudo-sobre-criptomoedas'
              onClick={() => {
                sendDataLayer({
                  section: 'dobra_05',
                  section_name: 'É simples, rápido e seguro ter Bitcoin no Inter.',
                  element_action: 'click button',
                  element_name: 'Inscreva-se agora',
                  redirect_url: '#aprenda-tudo-sobre-criptomoedas',
                })
              }}
            >
              Inscreva-se agora
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default S5ESimplesRapidoSeguro
