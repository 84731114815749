import React from 'react'
import * as S from './styles'

const GlobalAccountAdvantages = () => {
  return (
    <S.Wrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-32 fs-lg-40 fs-xl-48 lh-30 lh-md-38 fw-400 text-grayscale--500 mb-5'>Vantagens da Global Account</h2>
          </div>
        </div>
        <S.WrapperGrid>
          <S.DigitalAccount>
            <p className='text-white fs-24 fs-md-32 lh-30 lh-md-38 fs-xl-40 lh-xl-50 fw-400'>
              Conta digital 100% <span className='d-block'>gratuita, sem taxas extras.</span>
            </p>
          </S.DigitalAccount>
          <S.DebitCard>
            <p className='text-grayscale--500 fs-24 fs-md-32 lh-30 lh-md-38 fs-xl-40 lh-xl-50 fw-400'>
              Cartão de <span className='d-lg-block d-xl-inline'>débito</span> <span className='d-block'>internacional</span> exclusivo.
            </p>
          </S.DebitCard>
          <S.Transfer>
            <p className='text-grayscale--500 fs-24 fs-md-32 lh-30 lh-md-38 fs-xl-40 lh-xl-50 fw-400'>
              Transferência <span className='d-block'>para contas</span> <span className='d-block'>dos Estados</span> Unidos.
            </p>
            <div className='image' />
          </S.Transfer>
          <S.GiftCard>
            <p className='text-grayscale--500 fs-24 fs-md-32 lh-30 lh-md-38 fs-xl-40 lh-xl-50 fw-400'>
              <span className='d-md-block'>Gift Cards das</span> <span className='d-md-block d-xl-inline'>melhores</span> marcas <span className='d-md-block'>internacionais.</span>
            </p>
          </S.GiftCard>
          <S.Investment>
            <p className='text-grayscale--500 fs-24 fs-md-32 lh-30 lh-md-38 fs-xl-40 lh-xl-50 fw-400'>
              <span className='d-md-block d-lg-inline'>Investimentos direto</span> <span className='d-lg-inline'>em</span><span className='d-md-inline d-lg-block'> dólares.</span>
            </p>
          </S.Investment>
        </S.WrapperGrid>
      </div>
    </S.Wrapper>
  )
}

export default GlobalAccountAdvantages
