import React from "react"
import { Section, Title, Text } from "./_style"
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"
import data from './data.json'
import DefaultCarousel from "src/components/UI/Carousels/DefaultCarousel"
import ImageWebp from "src/components/ImageWebp"

export default function DadosDaEmpresa () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 order-1 order-md-2'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 30 }}
              md={{ items: 1, partialVisibilityGutter: 30 }}
              lg={{ items: 1, partialVisibilityGutter: 30 }}
              xl={{ items: 1, partialVisibilityGutter: 30 }}
            >
              {
                data.map((item: string, index: number) => (
                  <ImageWebp
                    key={index}
                    pathSrc={item}
                    altDescription=''
                    arrayNumbers={[ 330, 300, 402, 500, 550 ]}
                    arrayNumbersHeight={[ 230, 200, 272, 340, 360 ]}
                  />
                ))
              }
            </DefaultCarousel>
          </div>
          <div className='col-12 col-md-6 order-2 order-md-1 d-flex flex-column justify-content-center'>
            <Title>Dados da empresa e endereço</Title>
            <Text>Preencha o CNPJ da sua empresa e nós atualizaremos os demais dados.</Text>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' />
              <Text>Caso sua empresa não seja encontrada na busca automática, será necessário preencher os dados automaticamente;</Text>
            </div>
            <div>
              <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' />
              <Text>Se sua empresa for condomínio, preencha o campo complemento no cadastro de endereço.</Text>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
