import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'

import { getParameterByName, sendDataLayerEvent } from 'src/shared/helpers'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Container, Checkbox } from './style'
import { IFormValues, UtmStateType, FormFields } from './types'

import {
  Error,
  Sent,
} from './status/_index'

type OpenAccountFormProps = {
  className?: string;
};

const ExperienciaForm = ({
  className,
}: OpenAccountFormProps) => {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
  }: UseFormMethods<IFormValues> = useForm<IFormValues>()

  const formStatus = {
    idle: 'idle',
    loading: 'loading',
    sent: 'sent',
    error: 'error',
    filled: 'filled',
    underAge: 'underAge',
  }

  const [ accept, setAccept ] = useState(false)
  const [ status, setStatus ] = useState(formStatus.idle)

  const [ , setUtmSource ] = useState<UtmStateType>(null)
  const [ , setUtmMedium ] = useState<UtmStateType>(null)
  const [ , setUtmCampaign ] = useState<UtmStateType>(null)
  const [ , setUtmContent ] = useState<UtmStateType>(null)
  const [ , setUtmTerm ] = useState<UtmStateType>(null)

  const [ sendDatalayerEvents ] = useDataLayer()

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setStatus(formStatus.loading)

    const formData = {
      campanha: 'Branding Experience',
      nome: data.name,
      email: data.email,
      CPFCNPJ: data.cpf.replace(/\D/g, ''),
      telefone: data.celular.replace(/\D/g, ''),
      aceite: accept,
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ])
      sendDataLayerEvent('form_submitted')
      sendDatalayerEvents({
        section: 'dobra_01',
        section_name: 'Curtir pode ser simples com o Inter',
        element_action: 'submit',
        element_name: 'Enviar',
        redirect_url: 'null',
        step: 'success',
      })

        setStatus(formStatus.sent)
    } catch (e) {
      setStatus(formStatus.error)
      sendDatalayerEvents({
        section: 'dobra_01',
        section_name: 'Curtir pode ser simples com o Inter',
        element_action: 'submit',
        element_name: 'Enviar',
        redirect_url: 'null',
        step: 'error',
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setStatus(formStatus.idle)
  }

  return (
    <>
      {status === formStatus.error && (
        <Error handleReturn={handleReturn} />
      )}
      {status === formStatus.sent && (
        <Sent />
      )}
      <Container
        className={`${
          status === formStatus.idle || status === formStatus.loading
            ? 'd-flex'
            : 'd-none'
        } align-items-center ${className}`}
      >
        <div className='row mx-0'>
          <div className='col-12 px-0'>
            <h2 className='fs-20 lh-26 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 font-sora'>Preencha o formulário e participe da ação:</h2>
          </div>
          <div className='col-12'>
            <form
              className='form--default'
              name='open_account'
              onSubmit={handleSubmit(sendForm)}
            >
              <div className='row mt-3'>
                <div
                  className={`col-12 pb-3 px-0 ${errors[FormFields.name] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.name} className='fs-14 lh-18 d-block'>
                    Nome
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) =>
                          Validations.name(value) ||
                          'Por favor, digite seu nome completo',
                      },
                    })}
                    name={FormFields.name}
                    id={FormFields.name}
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome completo'
                  />
                  {errors[FormFields.name] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.name].message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 ${errors[FormFields.cpf] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.cpf} className='fs-14 lh-18 d-block'>
                    CPF
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite seu CPF, apenas números',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name={FormFields.cpf}
                    id={FormFields.cpf}
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu CPF, apenas números'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(FormFields.cpf, Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors[FormFields.cpf] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.cpf].message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 ${errors[FormFields.celular] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.celular} className='fs-14 lh-18 d-block'>
                    Celular
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um numero de celular válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name={FormFields.celular}
                    id={FormFields.celular}
                    type='tel'
                    placeholder='(XX) XXXXX-XXXX'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue(
                        FormFields.celular,
                        Masks.MaskPHONE(event.currentTarget.value),
                    )}
                  />
                  {errors[FormFields.celular] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.celular].message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 ${errors[FormFields.email] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.email} className='fs-14 lh-18 d-block'>
                    E-mail
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name={FormFields.email}
                    id={FormFields.email}
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors[FormFields.email] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.email].message}
                    </p>
                  )}
                </div>
                <div className='col-12 d-flex flex-column pt-2 pt-md-0 px-0 mt-md-n2 mt-lg-n1 mt-xl-2'>
                  <Checkbox className='m-0'>
                    <input
                      name='aceite'
                      id='aceite'
                      type='checkbox'
                      className='form-input-check'
                      onChange={() => setAccept(!accept)}
                    />
                    <label className='form-label-check text-grayscale--500 fs-14 lh-17 fw-400' htmlFor='aceite'>
                      Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a
                      <a
                        className='fw-700'
                        title='Acessar Política de Privacidade do Inter'
                        href='https://inter.co/politica-de-privacidade/privacidade/'
                        target='_blank' rel='noreferrer'
                      > Política de Privacidade
                      </a>
                    </label>
                  </Checkbox>
                </div>
                <div className='col-12 text-center mt-2 pt-lg-2 px-0'>
                  <button
                    type='submit'
                    title='Enviar'
                    disabled={!accept || status === formStatus.loading}
                    className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                  >
                    {status === formStatus.loading
                      ? 'Enviando...'
                      : 'Enviar'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ExperienciaForm
