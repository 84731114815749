import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Section, Button, ButtonLink } from './style'

type ModalProps = {
  setIsModal: Function;
}

const AccommodationForEveryone = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_4',
      section_name: 'Hospedagens para todos os roteiros',
      element_action: 'click button',
      element_name: 'Buscar as melhores hospedagens',
      c_page: window.location.href,
    })
  }
  return (
    <Section
      className='py-5 d-flex align-items-md-center align-items-end'
      role='img'
      aria-label='Resort em uma praia paradisíaca'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 pr-xl-0'>
            <span className='fs-12 lh-17 text-grayscale--500 fw-600 mb-4 d-md-block d-none'>HOSPEDAGENS</span>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>Hospedagens para todos os roteiros</h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400'>Seja sozinho, com a família ou a trabalho, viajando com o Inter você encontra os melhores hotéis, resorts, pousadas e muitas outras opções de acomodações.</p>
            <Button onClick={() => handleClick() } className='d-none d-md-block'>Buscar as melhores hospedagens</Button>
            <ButtonLink
              className='d-md-none'
              href='https://inter-co.onelink.me/Qyu7/53fu0m0i'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_4',
                section_name: 'Hospedagens para todos os roteiros',
                element_action: 'click button',
                element_name: 'Buscar as melhores hospedagens',
                c_page: window.location.href,
              })}
            >
              Buscar as melhores hospedagens
            </ButtonLink>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AccommodationForEveryone
