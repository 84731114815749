import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  background: #F5F6FA;
  min-height: 565px ;


  @media (min-width: ${breakpoints.md}){
    min-height: 351px;
  }

`
export const Card = styled.div`
  width: 100%;
  height: 170px;
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  margin-bottom: 24px;

  p {
    min-height:  76px;
  }




  @media (min-width: ${breakpoints.lg}){
    width: 296px;
    height: 264px;
    padding: 16px 24px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 360px;
    height: 245px;
    padding: 16px 24px;
  }

 .title {
  min-height: 40px;

    @media (min-width: ${breakpoints.lg}) {
      min-height: 40px;

    }
  }

`

export const Box = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  background: #ff7a00;
  border-radius: 4px;
  padding: 2px 4px;
  width: 144px;
  height: 19px;
  font-weight: 600;
  margin-right: 14px;
`

export const BoxInvest = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #161616;
  background: #F5F6FA;
  border-radius: 4px;
  padding: 2px 4px;
  width: 79px;
  height: 19px;
  font-weight: 600;

`
