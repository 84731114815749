import { MachineProps } from '../../types'

export const mPosMachine: MachineProps[] = [
  {
    installments: '12',
    installmentPayment: '37,76',
    totalPayment: '453,11',
    beneficts: [
      {
        icon: 'broadcast-mobile',
        description: 'Conexão Wi-Fi e 2G',
      },
      {
        icon: 'deposit-by-boleto',
        description: 'Comprovante via SMS',
      },
      {
        icon: 'users',
        description: 'Cadastro de até 10 usuários',
      },
      {
        icon: 'nfc',
        description: 'Pagamento por aproximação',
      },
      {
        icon: 'truck',
        description: 'Leve para qualquer lugar',
      },
    ],
  },
]
