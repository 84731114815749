/* eslint-disable no-console */
export function sendWciDatalayerEvent () {
  /** FILL THE VALUES BELOW */
  var CONVERSION_IMPORT_URL = process.env.BSP_WCI_WHATSAPP
  var WHATSAPP_NUMBER = "553140427791"
  var STARTER_MESSAGE = "Olá, quero simular um empréstimo consignado!"
  /** DO NOT CHANGE BELOW */
  function encodeMessage (message) {
    return encodeURIComponent(message)
  }
  function getMessageWithProtocol (protocol_number) {
    return "[Chat ID: " + protocol_number + "] " + STARTER_MESSAGE
  }
  function getURL (message) {
    return "https://wa.me/" + WHATSAPP_NUMBER + "?text=" + message
  }
  function getProtocolNumber () {
    return window.crypto.randomUUID()
      .split("")
      .filter(function (value, index, self) {
        return self.indexOf(value) === index
      })
      .join("")
      .slice(-6)
  }
  function getGclid () {
    var params = new URLSearchParams(window.location.search)
    var gclid = params.get("gclid")
    if (!gclid) {
      let gcookie = document.cookie.split(";").map(function (cookie) {
        return cookie.split("=")
      })
      gcookie = gcookie.find(function (cookie) {
        return cookie[0].trim() === "_gcl_aw"
      })
      if (gcookie) gclid = gcookie[1].split(".")[2]
    }
    return gclid
  }
  function sendToConversionImportUrl (protocol_number, gclid) {
  var payload = {}
    var url = new URL(CONVERSION_IMPORT_URL)
    var params = new URLSearchParams(url)
    params.set("chatid", protocol_number)
    params.set("gclid", getGclid())

    fetch(CONVERSION_IMPORT_URL + "?" + params.toString(), {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        return response.json()
      })
      .catch(function (err) {
        console.error(err)
      })
      .then(function () {
        window.location.href = getURL(message)
      })
  }

  const protocol_number = getProtocolNumber()
  const message = encodeMessage(getMessageWithProtocol(protocol_number))
  const gclid = getGclid()

  window.dataLayer.push({
    protocol_number: protocol_number,
    event: 'click_on_wci',
  })

  if (!gclid) {
    window.location.href = getURL(message)
  } else {
    sendToConversionImportUrl(protocol_number, gclid)
  }
}
