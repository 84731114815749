import { device } from 'src/styles/breakpoints'
import { gray, grayscale, orange, white } from 'src/styles/colors'
import * as newColors from 'src/styles/newColors'
import styled from 'styled-components'

export const Checkbox = styled.div<{$invertColors?: boolean }>`
  margin: 10px 0;

  input {
    display: contents;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: 400;
    
    &::before, &::after {
      display: block;
      position: absolute;
      content: '';
    }

    &::before {
      border: 2px solid ${gray['500']};
      border-radius: 3px;
      height: 20px;
      left: 0;
      width: 20px;
      top: 50%;
      margin-top: calc(-20px / 2);
    }

    a {
      &:hover {
        color: ${gray['600']};
      }
    }

    &.ebook {
      color: ${grayscale['500']}
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
      &::before {
        background: ${(props: { $invertColors?: boolean }) => props.$invertColors ? '#fff' : `${newColors.primary[500]}` };
        border: ${(props: { $invertColors?: boolean }) => props.$invertColors ? '#fff' : `1px solid ${orange.dark}`};
      }
      &::after {
        left: 7px;
        width: 6px;
        height: 10px;
        border: ${(props: { $invertColors?: boolean }) => props.$invertColors ? 'solid #FF7A00' : `solid ${white}`};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        top: 50%;
        margin-top: calc(-13px / 2);
      }
    }
  }
`
