import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  min-height: 640px;
  padding: 40px 0;

  @media ${device.tablet} {
    min-height: 415px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media ${device.desktopLG} {
    min-height: 500px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;
  }
  @media ${device.desktopXXXL} {
    min-height: calc(100vh - 73px);
  }

  h1 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 16px;
    font-family: 'Sora', Helvetica, sans-serif;
    font-weight: 600;
    color: ${grayscale[500]};

    @media ${device.tablet} {
      font-size: 40px;
      line-height: 44px;
    }
    @media ${device.desktopLG} {
      font-size: 48px;
      line-height: 52px;
    }
    @media ${device.desktopXXXL} {
      font-size: 58px;
      line-height: 62px;
    }
  }

  p {
    font-size: 14px;
    line-height: 17px;
    color: ${grayscale[500]};
    margin-bottom: 32px;

    @media ${device.desktopLG} {
      font-size: 16px;
      line-height: 20px;
    }
    @media ${device.desktopXXL} {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 40px;
    }
    @media ${device.desktopXXXL} {
      margin-bottom: 53.33px;
    }
  }
`

export const LinkButton = styled.a`
  max-width: 100%;
  background: ${orange[500]};
  border-radius: 8px;
  color: ${white};
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 48px;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    color: ${white};
  }

  @media ${device.desktopXXXL} {
    max-width: 700px;
  }
`

export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`

export const SpanButton = styled.span`
  width: 100%;
  height: 46px;

  @media ${device.tablet} {
    width: 260px;
  }
  @media ${device.desktopLG} {
    width: 360px;
  }
  @media ${device.desktopXL} {
    width: 426px;
  }
`

export const Box = styled.div`
  border-radius: 4px;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) ;
  width: 100%;
  padding: 30px;

  ul li {
    list-style: none;
  }
`
