import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale, laranja, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #FDF8EE;

  h2 , p {
    color: ${laranja.terra};
  }

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  @media ${device.desktopLG} {
      padding-top: 64px;
      padding-bottom: 64px;
  }
  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  ul {
      padding-top: 0!important;
      margin-top: 0!important;
  }

  button {
    color: ${white};
    background: ${orange.base};
    height: 48px;
    font-family: 'Sora', sans-serif;

    &:focus, &:hover {
      outline: none;
      color: ${white};
    }
  }
`

export const Card = styled.div`
  background: ${white};
  padding: 24px;
  border: 1px solid ${grayscale[200]};
  border-radius: 16px;
  width: 264px;
  height: 272 px;

  @media ${device.tablet} {
    height: 331px;
  }
  @media ${device.desktopXL} {
      margin-right: 24px;
      width: auto;
  }

  .custom-height {
      height: 40px;
  }
`
