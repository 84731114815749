import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import OQueETaxaBonificada from './sections/o-que-taxa-bonificada/_index'
import Comparativo from './sections/comparativo/_index'
// import QuaisRequisitos from './sections/quais-requisitos/_index'
import ComoManter from './sections/como-manter/_index'
import JurosReduzidos from './sections/juros-reduzidos/_index'

const TaxaBonificada = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <OQueETaxaBonificada />
        <Comparativo />
        {/* <QuaisRequisitos /> */}
        <ComoManter />
        <JurosReduzidos />
      </Layout>
    </Wrapper>
  )
}

export default TaxaBonificada
