import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

const ComoManter = () => {
  const data = usePageQuery()

  return (
    <div className='py-5'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <Img fluid={data.comoManterATaxa.fluid} alt='Imagem de como manter a taxa bonificada' />
          </div>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500 mb-4'>Como manter a Taxa bonificada durante todo o contrato?</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400'>
              A taxa bonificada do Inter exige que o cliente seja usuário de pelo menos um dos produtos listados: investimentos, portabilidade de salário ou cartão de crédito. <br /><br />A melhor maneira de aproveitar a taxa bonificada ao longo de todo o financiamento imobiliário é estar adimplente com o contrato, realizar pagamento das parcelas via débito em conta e manter constantemente os critérios de relacionamento estabelecidos.<br /><br />Para garantir que os clientes estão em conformidade com o que foi estabelecido e continuem aproveitando o desconto da taxa bonificada, <strong>serão realizadas revisões trimestrais nos termos de relacionamento acordados para desconto na taxa de juros.</strong><br /><br />Caso haja alguma alteração nos critérios de relacionamento para adesão da taxa bonificada, os clientes serão comunicados previamente.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComoManter
