import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

export const Cashback = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const mobileLink = 'https://inter.co/pra-voce/plano-de-celular/'

  const handleLink = () => {
    window.open(mobileLink, '_blank')
    sendDatalayerEvent({
      section: 'dobra_7',
      section_name: 'Quer cashback no seu plano de celular?',
      element_action: 'click button',
      element_name: 'Conhecer plano de celular',
      redirect_url: mobileLink,
    })
  }

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 offset-md-6 offset-xl-7'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 mb-md-3 mt-lg-2'>
              Plano de celular sem fidelidade a partir de R$ 15
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4 mr-n3'>
              Com o plano de celular do Inter, você tem muuuita internet, bônus, cashback e muito mais!
            </p>
            <button
              className='btn btn--lg btn-orange--extra text-white text-none w-100 mw-100 mt-2 mt-md-3 mt-lg-2 mt-xl-3'
              onClick={handleLink}
            >
              Mostrar planos
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
