import React from 'react'
import * as T from './_types'
import { modulesList } from './_modulesList'

const context = React.createContext<null | T.VideoContextType>(null)

export const useVideoContext = (): T.VideoContextType => {
  const createdContext = React.useContext<null | T.VideoContextType>(context)
  if (!createdContext) {
    throw new Error('This component must be wrapped in a QualityItem component')
  }
  return createdContext as T.VideoContextType
}

const VideoContextProvider = ({ children }: React.PropsWithChildren) => {
  const [ module, setModule ] = React.useState<T.ModuleType>(modulesList[0])

  function setCurrentModule (moduleId: number) {
    const module = modulesList.filter((module: T.ModuleType) => module.id === moduleId)
    setModule(module[0])
  }

  function getCurrentModule () {
    return module
  }

  return (
    <context.Provider value={{ getCurrentModule, setCurrentModule }}>
      { children }
    </context.Provider>
  )
}

export default VideoContextProvider
