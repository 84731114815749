import React from 'react'
import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import Canceled from '@interco/icons/build-v4/orangeds/MD/canceled'

type Circle = {
  circleColor: string;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 643px;
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    min-height: 240px;
  }

  @media ${device.desktopLG} {
    min-height: 275px;
  }
`
const Circle = styled.div<Circle>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${(props: Circle) => props.circleColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

export const ModalErrorView = () => (
  <Container>
    <div className='text-center'>
      <Circle className='mx-auto' circleColor='#F56A50'>
        <Canceled height={32} width={32} color='#ffffff' />
      </Circle>
      <h3 className='font-sora fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-2 mt-4 text-grayscale--500'>
        Não foi possível concluir a solicitação
      </h3>
      <p className='fs-14 fs-lg-18 lh-16 lh-lg-18 text-grayscale--500'>
        Tente novamente mais tarde
      </p>
    </div>
  </Container>
)

type SuccessViewProps = {
  title: string;
  subTitle: string;
}

const ErrorView = ({ title, subTitle }: SuccessViewProps) => (
  <Container>
    <div className='text-center'>
      <Circle className='mx-auto' circleColor='#F56A50'>
        <Canceled height={32} width={32} color='#ffffff' />
      </Circle>
      <h3 className='font-sora fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-2 mt-4 text-grayscale--500'>
        {title}
      </h3>
      <p className='fs-14 fs-lg-18 lh-16 lh-lg-18 text-grayscale--500'>
        {subTitle}
      </p>
    </div>
  </Container>
)

export default ErrorView
