import React from 'react'

import CoinArrowUpOut from '@interco/icons/core/finance/ic_coin_arrow_up_out'
import MoneyFrame from '@interco/icons/core/finance/ic_money_frame'
import TrendingUp from '@interco/icons/core/finance/ic_trending_up'
import Wallet from '@interco/icons/core/finance/ic_wallet'

import { orange } from 'src/styles/colors'

const iconProps = {
  width: 16,
  height: 16,
  color: orange.extra,
}

export const data = [
  {
    icon: { sm: <MoneyFrame {...iconProps} /> },
    title: 'Faturamento anual entre R$ 15MM a R$30MM',
  },
  {
    icon: {
      sm: <TrendingUp {...iconProps} />,
    },
    title: 'Carteira de investimentos entre R$ 1 milhão e R$ 5 milhões',
  },
  {
    icon: {
      sm: <Wallet {...iconProps} />,
    },
    title: 'Empréstimo contratado acima de R$100 mil',
  },
  {
    icon: {
      sm: <CoinArrowUpOut {...iconProps} />,
    },
    title: 'Movimentação média de no mínimo R$200 mil nos últimos três meses',
  },
]
