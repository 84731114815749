/* eslint-disable no-console */
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { SimpleFlag, useFeatureFlagService } from 'src/services/feature-flags/FeatureFlagsService'
import { FEATURE_FLAGS } from './constants'

export type FeatureFlagsContext = {
  isFlagEnabled: (name: FEATURE_FLAGS) => boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContext | undefined>(undefined)

export const FeatureFlagsProvider = ({ children }: {children: ReactNode }) => {
  const [ featureFlags, setFeatureFlags ] = useState<SimpleFlag[]>()
  const service = useFeatureFlagService()

  useEffect(() => {
    service.getAll().then((flags: SimpleFlag[] | undefined) => { setFeatureFlags(flags) })
  }, [])

  const isFlagEnabled = useMemo(() => {
    return (name: FEATURE_FLAGS) => {
      if (!featureFlags) return false
      const [ matchedFlag ] = featureFlags.filter((flag: SimpleFlag) => flag.name === name)
      return matchedFlag.active
    }
  }, [ featureFlags ])

  return (
    <FeatureFlagsContext.Provider value={ { isFlagEnabled } }>
      { children }
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext)

  if (!context) {
    throw new Error('must be within a FeatureFlagProvider')
  }

  return context
}
