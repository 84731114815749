import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { brand, grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: ${grayscale[500]};
    margin-bottom: 20px;

    @media ${device.desktopLG} {
      font-size: 28px;
    }
    @media ${device.desktopXL} {
      font-size: 40px;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: ${grayscale[400]};
    margin-bottom: 20px;

    @media ${device.desktopLG} {
      font-size: 14px;
    }
    @media ${device.desktopXL} {
      font-size: 18px;
      line-height: 24px;
    }
  }
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const Card = styled.div`
  background: ${white};
  border-radius: 8px;
  height: fit-content;

  div {
    h3 {
      font-size: 16px;
      line-height: 20px;
      color: ${brand.grafite};
      font-weight: 600;
      margin-left: 10px;
      margin-top: 3px;
      @media ${device.tablet} {
        margin-left: 0;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 16px;
    color: ${brand.grafite};
  }
`
