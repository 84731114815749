import React from 'react'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import OpenModal from 'src/components/OpenModal'

import { Section, Button } from './style'

const ComoSerClienteInterBlack = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <Section className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row d-flex  justify-content-end'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-28 lh-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-white fw-700'>
              <span className='d-block'>
                Como ser cliente
              </span> Inter Black
            </h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white fw-400'>Evolua seu perfil de relacionamento com uma carteira de investimentos a partir de R$250 mil, seja com novos aportes ou aqueles que estão em outros bancos e corretoras.</p>
            <OpenModal to='modal-open-inter-black-contact-form' className='d-flex justify-content-md-center justify-content-lg-start'>
              <Button
                onClick={() => {
                sendDatalayerEvent({
                section: 'dobra_02',
                section_name: 'Como ser cliente Inter Black',
                element_action: 'click button',
                element_name: 'Saber mais',
              })
                }}
              >Saber mais
              </Button>
            </OpenModal>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoSerClienteInterBlack
