import { laranja } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

  h2, p {
    color: ${laranja.terra};
  }

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }
`
