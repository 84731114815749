import axios from 'axios'
import { useMemo } from 'react'

type GitlabFeatureFlagsApiResponse = Feature[]

export type SimpleFlag = {
  name: string;
  active: boolean;
}

export class FeatureFlagsService {
  private url: string = process.env.GITLAB_FEATURE_FLAGS_API as string
  private token: string = process.env.GITLAB_INSTITUCIONAL_PRIVATE_TOKEN as string

  async getAll (): Promise< SimpleFlag[] | undefined> {
    const response = await axios.get<GitlabFeatureFlagsApiResponse>(this.url, {
      headers: {
        'PRIVATE-TOKEN': this.token,
      },
    })
    return response.data.map((flag: Feature) => ({ name: flag.name, active: flag.active }))
  }
}

export const useFeatureFlagService = () => {
  const service = useMemo(() => new FeatureFlagsService(), [])
  return service
}
