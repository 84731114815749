import React from 'react'
import layoutContext from './pageContext.json'
import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/hero/_index'
import Correspondente from './sections/correspondente/_index'
import { CorrespondenteBancarioContextProps, CorrespondenteBancarioProvider } from '../../context/CorrespondenteBancarioContext/CorrespondenteBancarioContext'

const Credito = ({ pageContext }: {pageContext: {data: CorrespondenteBancarioContextProps}}) => {
  return (
    <CorrespondenteBancarioProvider data={pageContext.data}>
      <Wrapper>
        <Layout pageContext={layoutContext}>
          <Hero />
          <Correspondente />
        </Layout>
      </Wrapper>
    </CorrespondenteBancarioProvider>
  )
}

export default Credito
