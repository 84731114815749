import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ContentHeader = styled.section`

  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/black-hero-360/image.webp') no-repeat ;
  background-size: cover;
  min-height:  640px;

  h1, p {
    font-family: 'Citrina', sans-serif !important;
  }

  @media ${device.tablet} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/black-hero-768/image.webp') no-repeat ;
    min-height: 515px;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/black-hero-1024/image.webp') no-repeat ;
    min-height: 576px;
    background-size: cover;

  }

  @media ${device.desktopXL} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-inter-black-reb/image.webp') no-repeat ;
    min-height: 712px;
    background-size: cover;
  }

  button {
    width: 100%;


  }
`
