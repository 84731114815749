import React, { ReactNode, useEffect, useState } from 'react'
import { Button, Container, LI, SlideBar, UL } from './style'

type TabProps = {
  classDiv?: string;
  items: string[];
  children: ReactNode[];
  setTabActive?: Function;
  styles?: string;
  color: string;
  border?: boolean;
  slideBar?: boolean;
  position?: number;
  activeColor?: string;
  tabDifColor?: boolean;
  setTabPosition?: (state: number) => void;
  bgColorRadius?: boolean;
  handleTabClick?: () => void;
  isH3?: boolean;
}

interface ITabIndex {
  [index: number]: string;
}

const Tab = ({ classDiv, items, children, setTabActive, styles, color, border, slideBar, activeColor, position, tabDifColor, setTabPosition, bgColorRadius, handleTabClick, isH3 }: TabProps) => {
  const [ activeItem, setActiveItem ] = useState(position || 0)
  const [ tabColor, setTabColor ] = useState(activeColor)

  const indexColor: ITabIndex = {
    0: '#FF7A00',
    1: '#B6B7BB',
    2: '#161616',
    3: '#6A6C72',
  }

  useEffect(() => {
    tabDifColor && setTabColor(indexColor[activeItem])
  }, [ activeItem ])

  function handleClick (index: number) {
    setTabPosition && setTabPosition(index)
    setActiveItem(index)
    if (setTabActive) {
      setTabActive(index)
    }
  }

  useEffect(() => {
    if (position !== 0) {
      position && handleClick(position)
    }
  }, [ position ])

  return (
    <Container className={classDiv}>
      <nav>
        <UL className='pl-0 d-flex tab-ul'>
          {items.map((item: string, index: number) => (
            <LI
              activeItem={activeItem === index} key={item}
              activeColor={tabColor} border={border}
              bgColorRadius={bgColorRadius}
            >
              <Button
                className={activeItem === index ? 'active' : ''}
                color={color}
                onClick={() => {
                  handleClick(index)
                  handleTabClick && handleTabClick()
                }}
              >
                { isH3 ? (<h3 className={styles}>{item}</h3>) : (<h4 className={styles}>{item}</h4>) }
              </Button>
              {slideBar && <SlideBar />}
            </LI>
          ))}
        </UL>
      </nav>
      <div className='mt-3'>
        {
          children.map((item: ReactNode, index: number) => (
            <div className={`${activeItem === index ? 'd-block' : 'd-none'}`} key={index}>{item}</div>
          ))
        }
      </div>
    </Container>
  )
}

Tab.defaultProps = {
  styles: 'fs-16 fs-lg-24 lh-20 lh-lg-30 mb-0',
  color: '#6A6C72',
  activeColor: '#FF7A00',
  position: 0,
}

export default Tab
