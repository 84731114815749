import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

import ImageWebp from 'src/components/ImageWebp'

import cardJSON from './data/data.json'

import IcArrowRight from '@interco/icons/core/action-navigation/ic_arrow_right'
import IcSwapDots from '@interco/icons/core/action-navigation/ic_swap_dots'
import IcHouseCheck from '@interco/icons/core/finance/ic_house_check'
import IcHouseHalf from '@interco/icons/core/finance/ic_house_half'
import IcGlobe from '@interco/icons/core/home-objects/ic_globe'
import IcHandHouse from '@interco/icons/core/human/ic_hand_house'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Box, Card, CardComponent, IconContainer, Section } from './style'

type CardProps = {
  icon: string;
  title: string;
  description: string;
  colunagem: string;
  iconLeft: boolean;
  alignRight: boolean;
  heightData?: string | null;
  link: string;
}

const CreditoPessoal = () => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const icons = {
    houseCheck: <IcHouseCheck height={24} width={24} color='#ff7a00' />,
    houseFinancial: <IcHandHouse height={24} width={24} color='#ff7a00' />,
    portability: <IcSwapDots height={24} width={24} color='#ff7a00' />,
    construcasa: <IcHouseHalf height={24} width={24} color='#ff7a00' />,
    globe: <IcGlobe height={24} width={24} color='#ff7a00' />,
  }

  const handleIcon = (icon: string) => {
    switch (icon) {
      case 'houseCheck':
        return icons.houseCheck
      case 'houseFinancial':
        return icons.houseFinancial
      case 'portability':
        return icons.portability
      case 'construcasa':
        return icons.construcasa
      case 'globe':
        return icons.globe
      default:
        return icons.globe
    }
  }

  return (
    <Section className='py-5' id='credito-pessoal'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12'>
            {
              width < widths.md && (
                <ImageWebp
                  altDescription='Homem usando o celular'
                  arrayNumbers={[ ]}
                  arrayNumbersHeight={[ ]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra3-360/image.webp'
                />
              )
            }
          </div>
          <div className='col-12 col-md-6'>
            <h3 className='fs-28 lh-33 text-grayscale--500 fw-500 mt-3 mb-md-3'>Crédito Pessoal</h3>
          </div>
          <div className='col-12 col-lg-8 mt-md-4'>
            <div className='row'>
              {
                width < widths.md ? (
                  <div className='col-12'>
                    <DefaultCarousel
                      sm={{ items: 1 }}
                      md={{ items: 2 }}
                      lg={{ items: 3 }}
                      xl={{ items: 3, partialVisibilityGutter: 65 }}
                      itemClass='px-1'
                    >
                      {
                      cardJSON.map((item: CardProps) => (
                        <div key={item.title} className={`col-12 ${item.colunagem}`}>
                          <Card className={`${item.heightData}`}>
                            <CardComponent className={`d-flex align-items-center ${item.alignRight && 'justify-content-end'}`}>
                              {
                                !item.iconLeft && (
                                  <IconContainer>
                                    {handleIcon(item.icon)}
                                  </IconContainer>
                                )
                              }
                              <Box>
                                <h4 className='fs-18 lh-21 text-white fw-400 mb-0'>{item.title}</h4>
                              </Box>
                              {
                                item.iconLeft && (
                                  <IconContainer>
                                    {handleIcon(item.icon)}
                                  </IconContainer>
                                )
                              }
                            </CardComponent>
                            <p className='fs-16 lh-19 text-grayscale--500 fw-400 mt-3'>{item.description}</p>
                            <div className='d-flex align-items-center justify-content-end'>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={item.link}
                                title={`Saiba mais sobre ${item.title}`}
                                className='fs-14 lh-16 text-orange--extra fw-700 mb-0 mr-2'
                                onClick={() => {
                                  sendDatalayerEvent({
                                    section: 'dobra_03',
                                    element_action: 'click button',
                                    element_name: 'Saiba mais',
                                    section_name: 'Crédito Pessoal',
                                  })
                                }}
                              > Saiba mais
                              </a>
                              <IcArrowRight height={24} width={24} color='#FF7A00' />
                            </div>
                          </Card>
                        </div>
                    ))}
                    </DefaultCarousel>

                  </div>
                ) : (
                    cardJSON.map((item: CardProps) => (
                      <div key={item.title} className={`col-12 ${item.colunagem}`}>
                        <Card className={`${item.heightData}`}>
                          <CardComponent className={`d-flex align-items-center ${item.alignRight && 'justify-content-end'}`}>
                            {
                              !item.iconLeft && (
                                <IconContainer>
                                  {handleIcon(item.icon)}
                                </IconContainer>
                              )
                            }
                            <Box>
                              <h4 className='fs-18 lh-21 text-white fw-400 mb-0'>{item.title}</h4>
                            </Box>
                            {
                              item.iconLeft && (
                                <IconContainer>
                                  {handleIcon(item.icon)}
                                </IconContainer>
                              )
                            }
                          </CardComponent>
                          <p className='fs-16 lh-19 text-grayscale--500 fw-400 mt-3'>{item.description}</p>
                          <div className='d-flex align-items-center justify-content-end'>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={item.link}
                              title={`Saiba mais sobre ${item.title}`}
                              className='fs-14 lh-16 text-orange--extra fw-700 mb-0 mr-2'
                              onClick={() => {
                                sendDatalayerEvent({
                                  section: 'dobra_03',
                                  element_action: 'click button',
                                  element_name: 'Saiba mais',
                                  section_name: 'Crédito Pessoal',
                                })
                              }}
                            >Saiba mais
                            </a>
                            <IcArrowRight height={24} width={24} color='#FF7A00' />
                          </div>
                        </Card>
                      </div>
                   ))
                )
              }
            </div>
          </div>
          <div className='col-lg-4'>
            {
              width > widths.md && (
                <ImageWebp
                  altDescription='Homem sorrindo e usando o celular'
                  arrayNumbers={[]}
                  arrayNumbersHeight={[]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra3-web/image.webp'
                />
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CreditoPessoal
