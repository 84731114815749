import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'
import { white, grayscale, red, orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${primary[500]};

  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-hero-faixa/image.webp');
    background-position: 260px 0px;
    background-size: 590px;
    background-repeat: no-repeat;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-hero-faixa/image.webp');
    background-position: 468px 0px;
    background-size: 570px;
    background-repeat: no-repeat;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1440-hero-faixa/image.webp');
    background-position: 668px 13px;
    background-size: 736px;
  }

  @media (min-width: ${breakpoints.xxxl}){
    background-position: top right;
    background-size: 786px;
  }

  .link {
    float: left;
  }

  .ate {
    width: 296px;

    @media (min-width: ${breakpoints.md}){
      width: 202px;
      float: left;
    }

    @media (min-width: ${breakpoints.lg}){
      width: 258px;
      float: left;
    }

    @media (min-width: ${breakpoints.xl}){
      width: 313px;
      float: left;
    }
  }

  .logo-inter {
    max-width: 95px;

    @media (min-width: ${breakpoints.xl}){
      max-width: 127px;
    }
  }

  a, .btn-hero {
    clear: both;

    @media (min-width: ${breakpoints.md}){
      width: 218px;
    }

    @media (min-width: ${breakpoints.lg}){
      width: 296px;
    }

    @media (min-width: ${breakpoints.xl}){
      width: 264px;
    }
  }
`

export const Button = styled.div`
  border: 1px solid #FFFFFF;

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }

  float: left;
  clear: both;

  @media (min-width: ${breakpoints.md}){
    width: 218px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 296px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 264px;
  }
`

export const Card = styled.div`
  width: 100%;
  /* height: 548px; */
  background: ${white};
  border-radius: 8px ;
  padding: 24px;

  .select-area {
    z-index: 1;
  }

  input {
    height: 48px;
    width: 100%;
    background: ${grayscale[100]};
    border: none;
    border-radius: 8px;
    padding: 0 16px;

    &:focus{
      outline:none ;
    }

    &::placeholder{
      font-size: 14px;
      line-height: 18px;
      color: ${grayscale[300]} ;
    }
  }

  .error {
    label, p{
      color: ${red[500]};
    }
    input, details{
      background: #FEF0ED ;
    }
  }
`

export const ButtonForm = styled.button`
   background: ${orange.extra};
   height: 48px;
   width: 100%;
   border-radius: 8px;
   color: ${white};
   font-size: 14px;
   line-height: 17px;
   font-weight: 600;
   border: none;
   margin-top: 8px ;

   &:focus {
    outline: none;
   }

   &:disabled{
    background: ${grayscale[200]};
    color: #B6B7BB;
   }
`

export const Select = styled.details`
  min-height: 48px;
  width: 100%;
  background: ${grayscale[100]};
  border-radius: 8px ;
  overflow: hidden ;
  padding: 16px;

  summary {
    list-style: none;
    width: 100%;
    margin-bottom: 0 ;
    position: relative;
  }

  .chevron{
    position: absolute ;
    top: 50%;
    transform: translateY(-50%) ;
    right: 10px ;
  }
`

export const Check = styled.button`
  cursor: pointer;
  background: none;
  padding: none;
  border: none;

  &:focus {
    outline: none;
  }
`
export const LinkButton = styled.a`
   border: 1px solid #FFFFFF;

&:focus, &:hover {
  outline: none;
  text-decoration: none;
  color: #fff;
}

float: left;
clear: both;

@media (min-width: ${breakpoints.md}){
  width: 218px;
}

@media (min-width: ${breakpoints.lg}){
  width: 296px;
}

@media (min-width: ${breakpoints.xl}){
  width: 264px;
}



`
