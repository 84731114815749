import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Section, CTA, Step, Steps } from './style'
import ArrowRight from 'inter-frontend-svgs/lib/orangeds/MD/arrow-right'

type HeroProps = {
  setIsModalOpen: (isOpen: boolean) => void;
};

export const HowToGetInterPag = ({ setIsModalOpen }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-md-between'>
          <div className='col-12 col-md-6 pt-xl-4'>
            <h2>
              Como solicitar a maquininha de cartão Inter Pag
            </h2>
            <Steps>
              <div className='d-flex'>
                <Step>
                  1
                </Step>
                <p>Se você ainda não tiver uma conta, abra a sua no Super App.</p>
              </div>
              <div className='d-flex'>
                <Step>
                  2
                </Step>
                <p>Se você já tiver uma conta, é só fazer login no Super App.</p>
              </div>
              <div className='d-flex'>
                <Step>
                  3
                </Step>
                <p>Depois, nas funcionalidades do app, toque em "Maquininha" e preencha os dados solicitados.</p>
              </div>
            </Steps>
            <CTA
              onClick={() => {
                  setIsModalOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    section_name: 'Como solicitar a maquininha de cartão Inter Pag',
                    element_action: 'click button',
                    element_name: 'Solicitar maquininha',
                  })
              }}
            >
              Solicitar maquininha
              <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
            </CTA>
          </div>
        </div>
      </div>
    </Section>
  )
}
