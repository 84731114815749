import styled from 'styled-components'

import { brand, grayscale, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: ${brand[100]};
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;

  h2 {
    font-family: 'Citrina', sans-serif;
    font-size: 24px;
    color: ${grayscale[500]};
    font-weight: 600;

    @media ${device.tablet} {
      font-size: 28px
    }

    @media ${device.desktopXL} {
      font-size: 40px
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;

    @media ${device.tablet} {
      font-size: 14px;
    }

    @media ${device.desktopXL} {
      font-size: 20px;
    }
  }

  .image {
    margin-left: -12%;
    @media ${device.desktopXL} {
      margin-left: -35%;
    }
    img {
      width: 100%;
    }
  }
`

export const Button = styled.a`
  background: #ff7a00;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: ${white} ;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`
