import React from 'react'

import SuccessImage from 'src/assets/images/success-image.png'
import { Container, SetSent } from '../../style'

const SentStep = () => {
  return (
    <Container>
      <SetSent>
        <div className='mb-4'>
          <img
            src={SuccessImage}
            alt='Imagem representando sucesso de um envio de formulário'
            width={64} height={64}
          />
        </div>
        <p className='font-citrina fs-24 lh-26 text-grayscale--500 mb-4'>
          Prontinho! Recebemos os seus dados. Em breve entraremos em contato!
        </p>
      </SetSent>
    </Container>
  )
}

export default SentStep
