import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  .react-multi-carousel-dot, .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }
  section {
    padding: 40px 0;

    @media ${device.desktopLG} {
        padding: 56px 0;
      }

    @media ${device.desktopLG} {
        padding: 80px 0;
      }

    @media ${device.desktopXL} {
        padding: 96px 0;
      }
  }
`
