import React from 'react'
import * as S from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IHeroProps {
  openModal: (sectionOrder: string, dataLayer: IDataLayerParams) => void;
  sectionOrder: string;
}

const S2_CartoesSocioTorcedor = ({ openModal, sectionOrder }: IHeroProps) => {
  return (
    <S.Section>
      <S.ContentImage>
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/cap-dobra3-cartoes/image.webp'
          alt='ilustração dos cartões Athletico Paranaense'
        />
      </S.ContentImage>
      <S.ContentInfo>
        <h2 className='title'>
          Um cartão sócio torcedor diferente de tudo que você já viu.
        </h2>
        <p className='description'>
          O Super App do Inter tem tudo em um único aplicativo.
          E com o Cartão Inter Sócio Furacão também é assim: cartão de crédito e débito,
          que funciona como um cartão de acesso ao estádio.
        </p>
        <div className='box-cta'>
          <button
            className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
            title='Abrir conta'
            onClick={() => openModal(sectionOrder, {
              section: 'dobra_3',
              section_name: 'Ainda mais vantagens para o Sócio Furacão em dias de jogos!',
              element_action: 'click button',
              element_name: 'Abrir conta',
            })}
          >
            Quero meu cartão
          </button>
        </div>
      </S.ContentInfo>

    </S.Section>
  )
}

export default S2_CartoesSocioTorcedor
