import React from 'react'
import { Title, BG } from './_styles'

const S4FacaParteDaComunidade = () => {
  return (
    <BG
      id='faca-parte-da-comunidade-com-35-milhoes-de-pessoas'
      aria-label='Mensagens de pessoas parabenizando a websérie'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Title className='text-center text-grayscale--400 fs-14 lh-16 fs-md-24 lh-md-28 fs-lg-28 lh-lg-32 mb-0'>Faça parte da comunidade com
              <span className='d-block text-orange--extra fs-18 lh-22 fs-md-28 lh-md-32 fs-lg-40 lh-lg-44 fs-xl-55 lh-xl-66 mt-2 mt-lg-3'> +35 milhões de pessoas</span>
            </Title>
          </div>
        </div>
      </div>
    </BG>
  )
}

export default S4FacaParteDaComunidade
