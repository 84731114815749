import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { HeroContainer } from './style'
import Breadcrumb from '../../components/_Breadcrumb'

const Hero = () => {
  return (
    <HeroContainer>
      <div className='breadcrumb d-md-none'>
        <Breadcrumb
          sectionName='Financie no Inter: taxa bonificada com juros reduzidos'
          pageLink='https://inter.co/pra-voce/financiamento-imobiliario/residencial/'
          textLink='Financiamento Imobiliário'
          pageName='Taxa Bonificada'
        />
      </div>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-6'>
            <div className='d-none d-md-flex'>
              <Breadcrumb
                sectionName='Financie no Inter: taxa bonificada com juros reduzidos'
                pageLink='https://inter.co/pra-voce/financiamento-imobiliario/residencial/'
                textLink='Financiamento Imobiliário'
                pageName='Taxa Bonificada'
              />
            </div>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 mt-4 fw-600'>
              Informações sobre sua Taxa Bonificada com juros reduzidos
            </h1>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400 mb-3 mb-md-5'>
              Feita especialmente para clientes com contrato de financiamento ativo e que se enquadram nas condições de relacionamento do Inter.
            </p>
            <div className='d-flex align-items-center'>
              <div>
                <OrangeIcon size='LG' icon='coupon' color='#00AA4F' />
              </div>
              <h4 className='fs-16 lh-20 fs-xl-20 lh-xl-25 mb-0 ml-2 textgreen fw-600'>
                Desconto de 0,3 p.p. na taxa anual sem bonificação
              </h4>
            </div>
          </div>
        </div>
      </div>
    </HeroContainer>
  )
}

export default Hero
