import React from 'react'
import { useVideoContext } from 'src/pages/tudo-sobre-criptomoedas/1/context/videosContext'
import * as contextTypes from '../../../../../context/_types'
import { modulesList } from '../../../../../context/_modulesList'
import * as S from './_styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { TitleContainerPosition } from '../_types'

const WebserieModules = ({
  height = 'auto',
  titleContainerPosition,
}: { height?: string } & TitleContainerPosition) => {
  const { setCurrentModule }: contextTypes.VideoContextType = useVideoContext()
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.ModulesCarouselContainer
      height={height}
      className='col-12 col-md-5 col-lg-4 col-xl-3'
    >
      {modulesList.map((module: contextTypes.ModuleType) => (
        <S.Module
          key={module.name}
          onClick={() => {
            window.scroll({ top: titleContainerPosition, behavior: 'smooth' })
            sendDataLayer({
              section: 'dobra_01',
              section_name: 'Olá, bem-vindo(a) à websérie Tudo sobre Cripto!',
              element_name: `${module.name} ${module.title}`,
              element_action: 'click banner',
            })
            setCurrentModule(module.id)
          }}
        >
          <S.ModuleThumbnail src={module.image} />
          <h3 className='p-0'>
            <S.ModuleNumber>{module.name}</S.ModuleNumber>
            <S.ModuleTitle>{module.title}</S.ModuleTitle>
          </h3>
        </S.Module>
      ))}
    </S.ModulesCarouselContainer>
  )
}

export default WebserieModules
