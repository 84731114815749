import React, { ChangeEvent } from 'react'
import axios from 'axios'

import { useForm, UseFormMethods } from 'react-hook-form'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { getParameterByName } from 'src/shared/helpers'

import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as URLS from 'src/config/api/Urls'
import * as S from './_styles'

import { Input } from '@interco/inter-ui/components/Input'
import { Button } from '@interco/inter-ui/components/Button'
import AcceptTerms from 'src/components/AcceptTerms'
import CriptoPopups from 'src/pages/tudo-sobre-criptomoedas/components/popups/_popups'

interface IFormData {
  nome: string;
  email: string;
  cpf: string;
}

function downloadFile (url: string) {
  const link = window.document.createElement('a')
  link.href = url
  link.download = ''
  link.target = '_blank'
  window.document.body.appendChild(link)
  link.click()
  window.document.body.removeChild(link)
}

const S2DomineOMundoDeCriptoAgora = () => {
  return (
    <S.DomineOMundoDeCriptoAgoraSection
      id='domine-o-mundo-de-cripto-agora'
    >
      <div className='container'>
        <S.Row className='row'>
          <S.Content className='col-12 col-md-6 col-xl-5'>
            <S.ExclusiveBonusTag src='https://central-imagens.bancointer.com.br/images-without-small-versions/tag-cripto/image.webp' />
            <S.Title>
              Domine o mundo de cripto agora!
            </S.Title>
            <S.Description>
              Com este conteúdo gratuito, você vai dominar o mundo das criptomoedas. Aproveite!
            </S.Description>
          </S.Content>
          <S2DomineOMundoDeCriptoAgora.Form />
        </S.Row>
      </div>
    </S.DomineOMundoDeCriptoAgoraSection>
  )
}

S2DomineOMundoDeCriptoAgora.Form = () => {
  const [ openErrorModal, setOpenErrorModal ] = React.useState<boolean>(false)
  const [ openSuccessModal, setOpenSuccessModal ] = React.useState<boolean>(false)
  const [ loading, setLoading ] = React.useState<boolean>(false)

  const [ sendDataLayer ] = useDataLayer()

  const [ aceite, setAceite ] = React.useState<boolean>(false)
  const {
    handleSubmit,
    errors,
    register,
    setValue,
    reset,
  }: UseFormMethods<IFormData> = useForm<IFormData>({
    defaultValues: {
      nome: '',
      email: '',
      cpf: '',
    },
  })

  function resetFormState () {
    setOpenErrorModal(false)
    setOpenSuccessModal(false)
    setAceite(false)
    setLoading(false)
    reset()
  }

  function onSubmit (data: IFormData) {
    setLoading(true)

    const formData = {
      nome: data.nome,
      email: data.email,
      CpfCnpj: data.cpf.replace(/\D/g, ''),
      aceite: aceite,
      campanha: 'Tudo Sobre Cripto',
      descricao: 'Ebook',
      conteudo01: getParameterByName('utm_source', ''),
      conteudo02: getParameterByName('utm_medium', ''),
      conteudo03: getParameterByName('utm_campaign', ''),
    }

    axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setOpenSuccessModal(true)
        setLoading(false)
      })
      .catch(() => {
        setOpenErrorModal(true)
        setLoading(false)
      })
  }

  return (
    <>
      <CriptoPopups
        successVariant='success'
        colorVariant='white'
        isModalOpen={openSuccessModal}
        setIsModalOpen={() => resetFormState()}
      >
        <CriptoPopups.Title>
          Cadastro realizado com sucesso!
        </CriptoPopups.Title>
        <CriptoPopups.Description>
          Faça o download agora.
        </CriptoPopups.Description>
        <Button
          fullWidth
          onClick={() => {
            downloadFile('https://image.comunicacao.bancointer.com.br/lib/fe2a117170640475761c77/m/1/e-book-tudo-sobre-cripto-investimentos-inter.pdf')
            sendDataLayer({
              section: 'dobra_02',
              section_name: 'Domine o mundo de cripto agora!',
              element_action: 'click button',
              element_name: 'Baixar ebook',
              redirect_url: 'https://image.comunicacao.bancointer.com.br/lib/fe2a117170640475761c77/m/1/e-book-tudo-sobre-cripto-investimentos-inter.pdf',
            })
            resetFormState()
          }}
        >
          Baixar ebook

        </Button>
      </CriptoPopups>
      <CriptoPopups
        successVariant='error'
        colorVariant='white'
        isModalOpen={openErrorModal}
        setIsModalOpen={() => resetFormState()}
      >
        <CriptoPopups.Title>
          Houve um erro por aqui
        </CriptoPopups.Title>
        <CriptoPopups.Description>
          Não foi possível criar o seu cadastro. Experimente voltar dentro de alguns instantes.
        </CriptoPopups.Description>
        <Button
          fullWidth
          onClick={() => {
            sendDataLayer({
              section: 'dobra_02',
              section_name: 'Domine o mundo de cripto agora!',
              element_action: 'click button',
              element_name: 'Entendi',
            })
            resetFormState()
          }}
        >
          Entendi
        </Button>
      </CriptoPopups>
      <S.SubscriptionForm
        className='col-12 col-md-6 col-xl-5'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          className='input'
          id='nome'
          name='nome'
          label='Nome'
          placeholder='Digite seu nome completo'
          ref={register({
            required: 'Digite seu nome completo',
            validate: {
              isFullName: (name: string) => name.split(' ').length > 1 || 'Por favor, digite seu nome completo',
            },
          })}
          error={!!errors.nome}
          infoText={errors.nome?.message}
        />

        <Input
          className='input'
          id='email'
          name='email'
          label='E-mail'
          placeholder='Digite seu e-mail'
          ref={register({
            required: 'Digite seu e-mail',
            validate: {
              isValidEmail: (email: string) => email.includes('@') || 'Por favor, digite um e-mail válido',
              haveDomain: (email: string) => {
                const domain = email.split('@')[1]
                return domain.includes('.') || 'Por favor, digite um e-mail válido'
              },
            },
          })}
          error={!!errors.email}
          infoText={errors.email?.message}
        />

        <Input
          className='input'
          id='cpf'
          name='cpf'
          label='CPF'
          placeholder='Digite seu CPF'
          ref={register({
            required: 'Digite seu CPF',
            validate: {
              isValidCPF: (cpf: string) => Validations.cpf(cpf) || 'Por favor, digite um CPF válido',
            },
          })}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
          error={!!errors.cpf}
          infoText={errors.cpf?.message}
        />

        <AcceptTerms
          accept={aceite}
          setAccept={setAceite}
          styles='accept_terms'
          dataLayer={{
            section: 'dobra_02',
            section_name: 'Domine o mundo de cripto agora!',
            element_name: 'Política de privacidade',
            element_action: 'click button',
          }}
        />

        <Button
          fullWidth
          type='submit'
          disabled={!aceite}
          isLoading={loading}
          onClick={() => sendDataLayer({
            section: 'dobra_02',
            section_name: 'Domine o mundo de cripto agora!',
            element_name: 'Baixe grátis',
            element_action: 'submit',
          })}
        >
          Baixe grátis
        </Button>
      </S.SubscriptionForm>
    </>
  )
}

export default S2DomineOMundoDeCriptoAgora
