import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'

const PJAccount = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='working-capital' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center'>
            <ImageWebp
              arrayNumbers={[ 261, 261, 374, 480 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/creditopj-dobra2/image.webp'
              altDescription='Uma mulher e um homem sorrindo enquanto olhar para um notebook'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 mt-3'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Capital de Giro sem burocracias: Pronampe e FGI Peac
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              <a
                href='https://inter.co/empresas/emprestimos/pronampe/'
                target='blanck' rel='noreferrer'
              >Pronampe
              </a>: até 5 anos pra pagar (60 meses) e taxas a partir de 0,45% ao mês + Selic
            </p>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              FGI Peac: com carência de 6 meses do valor principal e contratação 100% digital em poucos cliques
            </p>
            <a
              title='Saber mais'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3'
              href='/empresas/emprestimos/capital-de-giro/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Saber mais',
                  section_name: 'Capital de Giro sem burocracias: conheça o FGI Peac  ',
                  redirect_url: `${window.location.origin}/empresas/emprestimos/capital-de-giro/`,
                })
              }}
            >
              Saber mais
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PJAccount
