import React from 'react'
import { translate } from 'react-i18next'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'
import useWidth from 'src/hooks/window/useWidth'

import SocialNetwork from 'components/SocialNetwork'
import Link from 'src/components/GatsbyLinkWrapper/index'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { WIDTH_MD } from 'src/styles/breakpoints'
import DisplayStore from './DisplayStore'
import Pagelanguages from './Pagelanguages/_index'
import PrivacyCertification from './PrivacyCertification'

import { IFooterProps } from './types'

const FooterEN = (props: IFooterProps) => {
  const [ sendDatalayerFooterEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <div id='footer-en' className='container pt-5 pt-md-0'>
      <div className='row mb-md-4'>
        <div className='col-12 d-none d-lg-block'>
          <div className='col-12 px-lg-0 pb-lg-4'>
            <PrivacyCertification />
          </div>
          <div className='col-12 px-lg-0'>
            <DisplayStore />
          </div>
          <div className='col mt-lg-4 px-lg-0'>
            <SocialNetwork />
          </div>
          { width <= WIDTH_MD &&
            <div className='col mt-lg-4 px-lg-0 language'>
              <Pagelanguages
                theme={props.theme}
                slug={props.slug}
                pagePT={props.pagePT}
                pageEN={props.pageEN}
                isHeaderUS={props.isHeaderUS}
                ptURL={props.ptURL}
                enURL={props.enURL}
              />
            </div>
            }
        </div>
        <div className='col-12 mb-3'>
          <span className='title-font d-block fs-16 fw-700 mb-3'>{props.t('quick_access.title')}</span>
          <ul className='list-unstyled'>
            <li>
              <a
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: props.t('quick_access.title'),
                    element_action: 'click button',
                    element_name: props.t('quick_access.investor_relations'),
                    redirect_url: 'https://investors.inter.co/en/',
                    c_page: window.location.href,
                  })
                }}
                href='https://investors.inter.co/en/'
                target='_blank' rel='noreferrer' title={props.t('quick_access.investor_relations')}
              >{props.t('quick_access.investor_relations')}
              </a>
            </li>
          </ul>
        </div>
        <div className='col-12 contact'>
          <div className='col-12 mb-3 pr-3 pl-0'>
            <span className='title-font d-block fs-16 fw-700 mb-3'>{props.t('help_center.contact')}</span>
            <ul className='list-unstyled list-phones'>
              <li>
                <strong>
                  {props.t('help_center.capital_cities')}
                </strong>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${props.t('help_center.contact')} - +55 3003 4070`,
                      redirect_url: 'tel:+5530034070',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5530034070'
                >
                  +55 3003 4070
                </a>
              </li>
              <li>
                <strong>
                  {props.t('help_center.other_cities')}
                </strong>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.other_cities'),
                      element_action: 'click button',
                      element_name: `${props.t('help_center.other_cities')} - +55 0800 940 0007`,
                      redirect_url: 'tel:+5508009400007',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009400007'
                >+55 0800 940 0007
                </a>
              </li>
              <li>
                <strong>
                  {props.t('help_center.sac')}
                </strong>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.sac'),
                      element_action: 'click button',
                      element_name: `${props.t('help_center.sac')} - +55 0800 940 9999`,
                      redirect_url: 'tel:+5508009409999',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009409999'
                >+55 0800 940 9999
                </a>
              </li>
            </ul>
          </div>
          <div className='col-12 mb-3 pl-0'>
            <ul className='list-unstyled list-phones'>
              <li>
                <strong>
                  {props.t('help_center.ombudsman')}
                </strong>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.ombudsman'),
                      element_action: 'click button',
                      element_name: `${props.t('help_center.ombudsman')} - +55 0800 940 7772`,
                      redirect_url: 'tel:+5508009407772',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009407772'
                >+55 0800 940 7772
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12'>
          <span className='title-font d-block fs-16 fw-700 mb-3'>{props.t('help_center.products.title')}</span>
          <ul className='list-unstyled'>
            <li>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: props.t('help_center.products.title'),
                    element_action: 'click button',
                    element_name: props.t('help_center.products.digital_account'),
                    redirect_url: `${window.location.origin}/en/digital-account/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/digital-account/'
                title={props.t('help_center.products.digital_account')}
              >{props.t('help_center.products.digital_account')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mb-4 mb-lg-0'>
          <div className='row d-md-flex'>
            {width <= WIDTH_MD &&
              <div className='col-12 col-md-6 pt-4 pt-md-0'>
                <PrivacyCertification />
              </div>
            }
            <div className='col-12 col-md-6 language'>
              <Pagelanguages
                theme={props.theme}
                slug={props.slug}
                pagePT={props.pagePT}
                pageEN={props.pageEN}
                isHeaderUS={props.isHeaderUS}
                ptURL={props.ptURL}
                enURL={props.enURL}
              />
            </div>
          </div>
        </div>
        {width <= WIDTH_MD &&
          <div className='col-12'>
            <div className='row d-md-flex'>
              <div className='col-12 col-md-6'>
                <DisplayStore />
              </div>
              <div className='col-12 col-md-6'>
                <SocialNetwork />
              </div>
            </div>
          </div>
        }
      </div>
      <div className='col-12 pb-md-4 pb-lg-2 border-md-bottom' />
      <div className='row sub-footer'>
        <div className='col-12 text-left'>
          <ul className='list-inline'>
            <li className='list-inline-item fs-12'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('privacy_policy'),
                    redirect_url: `${window.location.origin}/en/privacy-policy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/privacy-policy/'
                title={props.t('privacy_policy')}
              >
                {props.t('privacy_policy')}
              </Link>
            </li>
            <li className='list-inline-item fs-12'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('data_privacy'),
                    redirect_url: `${window.location.origin}/en/data-privacy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/data-privacy/'
                title={props.t('data_privacy')}
              >
                {props.t('data_privacy')}
              </Link>
            </li>
            <li className='list-inline-item fs-12'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('security'),
                    redirect_url: `${window.location.origin}/en/security/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/security/'
                title={props.t('security')}
              >
                {props.t('security')}
              </Link>
            </li>
            <li className='list-inline-item fs-12'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('security_policy'),
                    redirect_url: `${window.location.origin}/en/cybersecurity-policy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/cybersecurity-policy/'
                title={props.t('security_policy')}
              >
                {props.t('security_policy')}
              </Link>
            </li>
            <li className='list-inline-item fs-12'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('security_policy_for_suppliers'),
                    redirect_url: `${window.location.origin}/en/security-policy-for-suppliers/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/security-policy-for-suppliers/'
                title={props.t('security_policy_for_suppliers')}
              >
                {props.t('security_policy_for_suppliers')}
              </Link>
            </li>
          </ul>
          <address>
            <div className='d-flex align-items-center mb-3'>
              &copy; <span className='fs-10 ml-1 text-left' dangerouslySetInnerHTML={{ __html: props.t('cnpj') }} />
            </div>
            <div className='d-xl-flex'>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'null',
                      element_action: 'click button',
                      element_name: props.t('address_bh'),
                      redirect_url: 'https://goo.gl/maps/6ELbHe2AsaN6VzsE7',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/6ELbHe2AsaN6VzsE7'
                  className='address d-flex'
                  target='_blank'
                  rel='noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: props.t('address_bh') }} />
                </a>
              </div>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'null',
                      element_action: 'click button',
                      element_name: props.t('address_sp'),
                      redirect_url: 'https://goo.gl/maps/dhFZGQQuaAsU8fuy9',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/dhFZGQQuaAsU8fuy9'
                  className='address d-flex'
                  target='_blank'
                  rel='noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: props.t('address_sp') }} />
                </a>
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  )
}

  export default translate('Footer')(FooterEN)
