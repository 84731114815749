import { device } from 'src/styles/breakpoints'
import { graphite, orange, sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${sand};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra7/image.webp');
  background-size: 95vw;
  background-position: right 40px;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
  align-items: end;

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra7-768/image.webp');
    align-items: center;
    background-size: 420px;
    background-position: -40px 40px;
    height: 300px;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra7-1024/image.webp');
    background-size: contain;
    background-position: -10px 40px;
    height: 340px;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/pj-dobra7-web/image.webp');
    background-size: contain;
    background-position: -10px 40px;
    height: 440px;
  }
`

export const Title = styled.h2`
  color: ${orange.extra};
  font-family: 'Citrina', sans-serif;
  font-size: 28px;
  line-height: 32px;


  @media ${device.desktopXL} {
    font-size: 48px;
    line-height: 52px;
  }
`
export const Text = styled.p`
  color: ${graphite};
  font-size: 18px;
  line-height: 22px;
  display: inline;
  margin-bottom: 0;
  margin-left: 10px;

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`
