import React from "react"
import { Section, Title, Text } from "./_style"
import ImageWebp from "src/components/ImageWebp"
import OrangeIcon from "src/components/UI/MarkdownIcon/OrangeDsIcon"
import data from './data.json'

export default function Liveness () {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 d-flex justify-content-center align-items-center order-1 order-md-2'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-dobra10/image.webp'
              altDescription=''
              arrayNumbers={[ 350, 350, 400, 450 ]}
              arrayNumbersHeight={[ 350, 350, 400, 450 ]}
            />
          </div>
          <div className='col-12 col-md-6 mt-2 order-2 order-md-1 d-flex flex-column justify-content-center mt-4 mt-md-0'>
            <Title>Liveness (selfie)</Title>
            <Text>Esta etapa é essencial para a segurança da sua conta e deve ser feita pelo administrador da conta. Siga o passo-a-passo abaixo e simplifique essa etapa:</Text>
            <br />
            {
              data.map((item: string, index: number) => (
                <div key={index}>
                  <OrangeIcon size='SM' icon='arrow-right' color='#EA7100' className='mr-2' />
                  <Text>{item}</Text>
                </div>
              ))
            }
            <br />
            <Text>Boas-práticas: Fique em local bem iluminado e evite acessórios próximo ao rosto.</Text>
          </div>
        </div>
      </div>
    </Section>
  )
}
