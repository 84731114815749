import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import AcceptTerms from 'src/components/AcceptTerms'
import { apiForm } from 'src/config/api/axiosRequest'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { getParameterByName } from 'src/shared/helpers'

import { ErrorStep, SentStep } from './FormSteps/_index'

import { Container } from './style'

interface IFormValues {
  nome: string;
  sobrenome: string;
  email: string;
  celular: string;
  cpf: string;
  Campanha: string;
}

type FormProps = {
  customOrigin: string;
  page: string;
  sectionName: string;
  lead: string;
}

function ContatoEspecialistaInterOneAndBlack ({ customOrigin, page, lead, sectionName }: FormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ utmTerm, setUtmTerm ] = useState<string | string[] | null | undefined>(null)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  useEffect(() => {
    setUtmTerm(getParameterByName('utm_term', ''))
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      termoAceito: accept,
      Campanha: customOrigin,
      timeOrigem: 'SDR',
      utmTerm: utmTerm?.toString(),
      utmSource: utmSource?.toString(),
      utmMedium: utmMedium?.toString(),
      utmContent: utmContent?.toString(),
      utmCampaign: utmCampaign?.toString(),
    }

    try {
      await apiForm.post(`${URLS.CONTACT_FORM_POST_V5}/${lead}`, [ formData ])
      setLoading(false)
      setSent(true)
      sendDatalayerEvent({
        section: 'dobra_01',
        element_action: 'click button',
        section_name: sectionName,
        element_name: 'Quero receber contato',
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  return (
    <>
      { error && (
        <ErrorStep handleReturn={handleReturn} page={page} />
      )}
      { sent && (
        <SentStep page={page} />
      )}
      <Container
        page={page}
        className={`${error || sent ? 'd-none' : 'd-flex'} align-items-center`}
      >
        <div className='row'>
          <div className='col-12 text-center px-0'>
            <h2 className='font-citrina fs-24 lh-26'>
              Preencha seus dados para receber o contato de um especialista
            </h2>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-12 d-flex flex-column mb-3'>
                    <label htmlFor='nome'>Nome</label>
                    <input
                      name='nome'
                      id='nome'
                      type='text'
                      maxLength={100}
                      placeholder='Digite seu nome'
                      className={`${errors.nome && 'error'}`}
                      ref={register({
                        required: 'Nome obrigatório',
                      })}
                    />
                    {errors.nome && <span className='error'>{errors.nome.message}</span>}
                  </div>
                  <div className='col-12 col-md-6 col-lg-12 d-flex flex-column mb-3'>
                    <label htmlFor='sobrenome'>Sobrenome</label>
                    <input
                      name='sobrenome'
                      id='sobrenome'
                      type='text'
                      maxLength={100}
                      placeholder='Digite seu sobrenome'
                      className={`${errors.sobrenome && 'error'}`}
                      ref={register({
                        required: 'Sobrenome obrigatório',
                      })}
                    />
                    {errors.sobrenome && <span className='error'>{errors.sobrenome.message}</span>}
                  </div>
                  <div className='col-12 col-md-6 col-lg-12 d-flex flex-column mb-3'>
                    <label htmlFor='cpf'>CPF</label>
                    <input
                      name='cpf'
                      id='cpf'
                      type='tel'
                      placeholder='Digite seu CPF'
                      className={`${errors.cpf && 'error'}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                      ref={register({
                        required: 'Digite um CPF válido',
                        validate: {
                          isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                        },
                      })}
                    />
                    {errors.cpf && <span className='error'>{errors.cpf.message}</span>}
                  </div>
                  <div className='col-12 col-md-6 col-lg-12 d-flex flex-column mb-3'>
                    <label htmlFor='email'>E-mail</label>
                    <input
                      name='email'
                      id='email'
                      type='text'
                      placeholder='Digite seu e-mail'
                      className={`${errors.email && 'error'}`}
                      ref={register({
                        required: 'Digite um e-mail válido',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'E-mail inválido',
                        },
                      })}
                    />
                    {errors.email && <span className='error'>{errors.email.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label htmlFor='celular'>Celular</label>
                    <input
                      ref={register({
                        required: 'Digite um telefone válido',
                        pattern: {
                          value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                          message: 'Telefone inválido',
                        },
                      })}
                      name='celular'
                      id='celular'
                      type='tel'
                      placeholder='Digite seu telefone'
                      maxLength={15}
                      className={`${errors.celular && 'error'}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                    />
                    {errors.celular && <span className='error'>{errors.celular.message}</span>}
                  </div>
                  <div className='col-12 d-flex flex-column pb-3'>
                    <AcceptTerms
                      accept={accept}
                      setAccept={setAccept}
                      name='receber-contato-inter-one'
                      label='Autorizo o Inter a me enviar comunicações. Estou de acordo com a'
                    />
                  </div>
                  <div className='col-12'>
                    <button
                      type='submit'
                      title='Quero receber contato'
                      disabled={!accept || loading}
                    >
                      {loading ? 'Enviando...' : 'Quero receber contato'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

ContatoEspecialistaInterOneAndBlack.defaultProps = {
  type: 'interOne',
}

export default ContatoEspecialistaInterOneAndBlack
