import * as T from './_types'

export const modulesList: T.ModuleType[] = [
  {
    id: 1,
    name: 'Módulo 1',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula1/image.png',
    title: 'O que é Bitcoin?',
    link: 'https://www.youtube.com/embed/Yv-syBMvDpI',
    topics: [
      'Introdução ao conceito de bitcoin',
      'História e origem',
      'Principais características: digital, descentralizado, quantidade limitada',
      'Usos do bitcoin: investimento e meio de pagamento',
      'Fatores de segurança e riscos',
    ],
  },
  {
    id: 2,
    name: 'Módulo 2',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula2/image.png',
    title: 'Quem criou o Bitcoin e por que é importante saber?',
    link: 'https://www.youtube.com/embed/LTatcqY5BLM',
    topics: [
      'O enigma de Satoshi Nakamoto',
      'Contexto da criação do Bitcoin (2008)',
      'Importância de descentralização',
      'Debate sobre o anonimato de Satoshi',
      'Impacto de Satoshi no desenvolvimento da rede',
    ],
  },
  {
    id: 3,
    name: 'Módulo 3',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula3/image.png',
    title: 'Por que o Bitcoin é chamado de “ouro digital”?',
    link: 'https://www.youtube.com/embed/1lFOsycFfgk',
    topics: [
      'Comparação entre Bitcoin e ouro',
      'Valor como reserva de valor',
      'Quantidade limitada e escassez',
      'Proteção contra inflação',
      'Perspectivas de longo prazo',
    ],
  },
  {
    id: 4,
    name: 'Módulo 4',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula4/image.png',
    title: 'O que faz o preço do Bitcoin subir ou descer?',
    link: 'https://www.youtube.com/embed/jGy3sg22yi4',
    topics: [
      'Fatores que influenciam o preço: oferta e demanda',
      'Notícias e eventos globais',
      'Regulação e adoção institucional (ETFs)',
      'Sentimento do mercado e especulação',
      'Influência do halving e escassez',
    ],
  },
  {
    id: 5,
    name: 'Módulo 5',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula5/image.png',
    title: 'Bitcoin é legal? Regulamentações no Brasil e no mundo',
    link: 'https://www.youtube.com/embed/EXqPSt1yINA',
    topics: [
      'Situação jurídica do Bitcoin globalmente',
      'Regulamentação do Bitcoin no Brasil',
      'Países onde o Bitcoin é aceito e restrições',
      'Impostos e regulamentações financeiras',
      'Perspectivas para o futuro da regulamentação',
    ],
  },
  {
    id: 6,
    name: 'Módulo 6',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula6/image.png',
    title: 'O que significa o Bitcoin ser deflacionário?',
    link: 'https://www.youtube.com/embed/010RflPaTBg',
    topics: [
      'Conceito de deflação e quantidade limitada',
      'Comparação com moedas fiduciárias',
      'Impacto na valorização ao longo do tempo',
      'Importância da escassez programada',
      'Efeitos sobre investidores e o mercado',
    ],
  },
  {
    id: 7,
    name: 'Módulo 7',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula7/image.png',
    title: 'Por que comprar criptomoedas pelo Inter?',
    link: 'https://www.youtube.com/embed/edeKIo4hR-A',
    topics: [
      'Benefícios de comprar pelo Inter',
      'Segurança e regulamentação da plataforma',
      'Facilidade de uso',
      'Consolidação de investimentos',
      'Opções de criptomoedas disponíveis',
    ],
  },
  {
    id: 8,
    name: 'Módulo 8',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/cripto-aula8/image.png',
    title: 'Passo a passo para comprar Bitcoin no Super App',
    link: 'https://www.youtube.com/embed/KLCL4snzFkM',
    topics: [
      'Como abrir uma conta e acessar a plataforma',
      'Escolher o valor e completar a transação',
      'Segurança no armazenamento do Bitcoin',
      'Como acompanhar o saldo e transações no Super App',
    ],
  },
]
