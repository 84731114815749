import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const SupportContent = styled.div`
  width: 100%;
`

export const SupportContentCardsList = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: auto;
  gap: 8px;

  @media ${device.tablet} {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }

  @media ${device.desktopXXL} {
    grid-template-columns: repeat(4, 140px);
    gap: 24px;
  }
`

export const SupportContentTitle = styled.h3`
  font-family: Citrina, sans-serif, arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;

  color: ${grayscale[500]};

  margin-bottom: 8px;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 34px;

    margin-bottom: 40px;
  }

  @media ${device.desktopLG} {
    margin-bottom: 24px;
  }
`

export const SupportContentCard = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 16px 0;
  margin: 0;

  border-bottom: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    border-radius: 8px;
    flex-direction: column;
    
    width: 100%;
    padding: 8px;

    background-color: white;
    outline: 1px solid ${grayscale[300]};
  }

  @media ${device.desktopLG} {
    height: 136px;
    width: 136px;

    justify-content: center;
    padding: 16px 8px;
  }

  @media ${device.desktopXXL} {
    width: 140px;
    height: 140px;
  }

  .right_chevron {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    min-width: 24px;
  }

  &:focus, &:focus-visible, &:active {
    border: none !important;
  }
`

export const SupportContentCardText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: ${grayscale[500]};

  margin: 0;

  @media ${device.tablet} {
    font-size: 10px;
    line-height: 12px;

    letter-spacing: 0;
    margin-bottom: 8px;
  }

  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 17px;

    margin-bottom: 16px;
  }
`

export const SupportContentCardLink = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  text-decoration-line: underline;
  color: ${orange[500]};

  margin: 0;

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
  }
`
