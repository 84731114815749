import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { Wrapper } from './style'
import Hero from './sections/S1-hero/_index'
import PreCadastro from './sections/S2-pre-cadastro/_index'
import DuasEtapas from './sections/S3-duas-etapas/_index'
import Cadastro from './sections/S4-cadastro/_index'
import PrimeiroAcesso from './sections/S5-primeiro-acesso/_index'
import DadosDaEmpresa from './sections/S6-dados-da-empresa/_index'
import Faturamento from './sections/S7-faturamento/_index'
import Participantes from './sections/S8-participantes/_index'
import Administrador from './sections/S9-administrador/_index'
import DocumentoCliente from './sections/S10-documentacao-cliente/_index'
import Liveness from './sections/S11-liveness/_index'
import Conclusao from './sections/S12-conclusao/_index'
import End from './sections/S13-end/_index'

export default function AberturaContaPJ () {
  return (
    <Layout pageContext={pageContext}>
      <Wrapper>
        <Hero />
        <PreCadastro />
        <DuasEtapas />
        <Cadastro />
        <PrimeiroAcesso />
        <DadosDaEmpresa />
        <Faturamento />
        <Participantes />
        <Administrador />
        <DocumentoCliente />
        <Liveness />
        <Conclusao />
        <End />
      </Wrapper>
    </Layout>
  )
}
