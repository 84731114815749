import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }
`

export const Description = styled.p`
  font-family: Inter, sans-serif, arial;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
  color: ${grayscale[400]};

  margin-top: 16px;
  margin-bottom: 0;

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;
  }

`

export const BlogCarousel = styled.div`
  img {
    width: 100%;
  }

  .primary-tag, .secundary-tag {
    display: inline;
  }

  &.p-card {
    padding: 0;    
  }

  margin-top: 24px;

  @media ${device.tablet} { 
    margin-top: 40px;
  }

  .react-multi-carousel-track {
    padding: 30px 0 10px!important;
  }
`

export const CardBottom = styled.div`
  border-radius: 16px 16px 0 0;
  border: 1px solid ${grayscale[200]};
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 70px;

  @media ${device.tablet} {
    min-height: 108px;
  }
  
  @media ${device.desktopLG} {
    min-height: 188px;
  } 

  @media ${device.desktopXXL} {
    min-height: 130px;
  }

  .read_more {
    display: none;

    @media ${device.desktopLG} {
      display: block;
    }
  }
`

export const CardTitle = styled.h3`
  font-family: Citrina, sans-serif, arial;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: left;
  color: ${grayscale[500]};

  margin: 0;
  
  @media ${device.desktopLG} {
    font-size: 24px;
    line-height: 29px;
  }
`
