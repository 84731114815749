import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Card, TitleCard } from './style'

const MelhoresBeneficiosSuperApp = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

return (
  <section className='container pt-5 pb-4 pb-md-0'>
    <div className='row justify-content-center py-xl-4'>
      <div className='col-12 col-lg-10 col-xl-8 text-md-center px-xl-0'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-700 mb-3'>Os melhores benefícios reunidos em um único Super App</h2>
        <p className='text-grayscale--400 fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 mb-0'>Os melhores benefícios para day traders que operam minicontratos em mercado futuro acessíveis na palma da sua mão. Ative o RLP e aproveite todos para operar em seu máximo potencial.</p>
      </div>
      <div className='row d-flex justify-content-xl-center pl-2 pl-xl-0 pt-3 py-md-5 pl-lg-4'>
        <Card className='col-12 col-md-4 mt-4 mt-md-0'>
          <TitleCard className='d-flex align-items-center d-block d-md-none'>
            <OrangeIcon size='MD' color='#161616' icon='savings' />
            <h2 className='fs-20 lh-20 text-grayscale--500 mb-0 pl-3'>Margem reduzida</h2>
          </TitleCard>
          <div className='d-none d-md-block mt-md-4'>
            <OrangeIcon size='MD' color='#6A6C72' icon='savings' />
            <h2 className='fs-20 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mt-md-4'>Margem reduzida</h2>
          </div>
          <p className='fs-14 fs-lg-16 lh-17 lh-lg-20 text-grayscale--400 mb-4'>Negocie sem a necessidade de disponibilizar o valor total do contrato</p>
          <hr className='d-block d-md-none' />
        </Card>
        <Card className='col-12 col-md-4'>
          <TitleCard className='d-flex align-items-center d-block d-md-none'>
            <OrangeIcon size='MD' color='#161616' icon='spending' />
            <h2 className='fs-20 lh-20 text-grayscale--500 mb-0 pl-3'>Alta performance</h2>
          </TitleCard>
          <div className='d-none d-md-block mt-md-4'>
            <OrangeIcon size='MD' color='#6A6C72' icon='spending' />
            <h2 className='fs-20 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mt-md-4'>Alta performance</h2>
          </div>
          <p className='fs-14 fs-lg-16 lh-17 lh-lg-20 text-grayscale--400 mb-4'>As melhores ferramentas do mercado para atender todos os perfis de traders </p>
          <hr className='d-block d-md-none' />
        </Card>
        <Card className='col-12 col-md-4 mr-md-0'>
          <TitleCard className='d-flex align-items-center d-block d-md-none'>
            <OrangeIcon size='MD' color='#161616' icon='goal' />
            <h2 className='fs-20 lh-20 text-grayscale--500 mb-0 pl-3'>Facilidade</h2>
          </TitleCard>
          <div className='d-none d-md-block mt-md-4'>
            <OrangeIcon size='MD' color='#6A6C72' icon='goal' />
            <h2 className='fs-20 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mt-md-4'>Facilidade</h2>
          </div>
          <p className='fs-14 fs-lg-16 lh-17 lh-lg-20 text-grayscale--400 mb-4'>Investir em mercado futuro é tão simples quanto negociar ações, e tudo em um único lugar</p>
          <hr className='d-block d-md-none' />
        </Card>
        <Card className='col-12 col-md-4'>
          <TitleCard className='d-flex align-items-center d-block d-md-none'>
            <OrangeIcon size='MD' color='#161616' icon='no-taxes' />
            <h2 className='fs-20 lh-20 text-grayscale--500 mb-0 pl-3'>Operações sem corretagem</h2>
          </TitleCard>
          <div className='d-none d-md-block mt-md-4'>
            <OrangeIcon size='MD' color='#6A6C72' icon='no-taxes' />
            <h2 className='fs-20 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mt-md-4'>Operações sem corretagem</h2>
          </div>
          <p className='fs-14 fs-lg-16 lh-17 lh-lg-20 text-grayscale--400 mb-4'>
            Aderindo ao
            <Link
              className='text-orange--base text-uppercase'
              to='/pra-voce/investimentos/rlp/'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_03',
                element_action: 'click button',
                element_name: 'rlp',
                section_name: 'Os melhores benefícios reunidos em um único Super App',
                redirect_url: 'https://inter.co/pra-voce/investimentos/rlp/',
              })
              }}
            > {' '}rlp
            </Link>, você opera sem corretagem
          </p>
          <hr className='d-block d-md-none' />
        </Card>
        <Card className='col-12 col-md-4'>
          <TitleCard className='d-flex align-items-center d-block d-md-none'>
            <OrangeIcon size='MD' color='#161616' icon='investments' />
            <h2 className='fs-20 lh-20 text-grayscale--500 mb-0 pl-3'>Melhor liquidez dos ativos</h2>
          </TitleCard>
          <div className='d-none d-md-block mt-md-4'>
            <OrangeIcon size='MD' color='#6A6C72' icon='investments' />
            <h2 className='fs-20 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mt-md-4'>Melhor liquidez dos ativos</h2>
          </div>
          <p className='fs-14 fs-lg-16 lh-17 lh-lg-20 text-grayscale--400 mb-4'>
            Com o RLP, suas ordens são executadas sempre ao preço de mercado ou melhor
          </p>
          <hr className='d-block d-md-none' />
        </Card>
        <Card className='col-12 col-md-4 mr-md-0'>
          <TitleCard className='d-flex align-items-center d-block d-md-none'>
            <OrangeIcon size='MD' color='#161616' icon='receipt' />
            <h2 className='fs-20 lh-20 text-grayscale--500 mb-0 pl-3'>Transparência</h2>
          </TitleCard>
          <div className='d-none d-md-block mt-md-4'>
            <OrangeIcon size='MD' color='#6A6C72' icon='receipt' />
            <h2 className='fs-20 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mt-md-4'>Transparência</h2>
          </div>
          <p className='fs-14 fs-lg-16 lh-17 lh-lg-20 text-grayscale--400 mb-4'>Operações reguladas pela CVM e dados totalmente divulgados</p>
        </Card>
      </div>
    </div>
  </section>
)
}

export default MelhoresBeneficiosSuperApp
