import styled from 'styled-components'

import { breakpoints, device } from 'src/styles/breakpoints'
import { brand, laranja, orange, white } from 'src/styles/colors'

const BannerMD = ' https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-1-768/image.webp'
const BannerLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-1-1024/image.webp'
const BannerXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-conta-pj-enterprise-dobra-1-1440/image.webp'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;

  h1 , p , span {
    color: ${laranja.terra};
  }

  h1, span {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  @media ${device.tablet} {
    height: 510px;
    background: url(${BannerMD}) no-repeat center;
    background-size: cover;
  }
  @media ${device.desktopLG} {
    background: url(${BannerLG}) no-repeat center;
    background-size: cover;
  }
  @media ${device.desktopXL} {
    height: 815px;
    background: url(${BannerXL}) no-repeat center;
    background-size: cover;
  }
  @media ${device.desktopXXXL} {
    background: url(${BannerXL}) no-repeat center;
    background-size: cover;
  }
`

export const CardBox = styled.div`

  @media (min-width: ${breakpoints.md}) {
    background-color: ${brand.sand};
    padding: 24px;
    border-radius: 24px;
    width: 100%;
    height: 427px;
  }
  @media (min-width: ${breakpoints.lg}){
    height: 380px;
  }
  @media (min-width: ${breakpoints.xl}){
    height: 505px;
    width: 573px;
    padding: 48px;
  }
`

export const Button = styled.button`
  height: 32px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 8px;
  background-color: ${orange.extra};
  width: 100%;
  color: ${white};
  font-weight: 600;
  margin-bottom: 16px;

  @media ${device.tablet} {
    height: 48px;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Sora', Helvetica, sans-serif;
  }
`

export const ButtonReverse = styled.button`
  height: 32px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid ${orange.extra};
  width: 100%;
  color: ${orange.extra};
  font-weight: 600;

  @media ${device.tablet} {
    height: 48px;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Sora', Helvetica, sans-serif;
  }
`
