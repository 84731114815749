import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Button, ButtonReverse } from './style'
import ImageWebp from 'src/components/ImageWebp'
import ScrollTo from 'src/components/ScrollTo'
interface IProps {
  openModal: () => void;
  oneLink: string;
}

const Hero = ({ openModal, oneLink }: IProps) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5 d-md-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>
              Tesouro Direto Premiado
            </h1>
            <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400'>
              Aproveite agora a oportunidade de participar da campanha do Tesouro Direto no Super App do Inter e concorrer a
              prêmios mensais de 10 mil reais, além de um prêmio final de 100 mil reais.
            </p>
            {
              width >= widths.md ? (
                <Button
                  title='Quero participar'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: 'Tesouro Direto Premiado',
                      element_action: 'click button',
                      element_name: 'Quero participar',
                    })
                  }}
                >Quero participar
                </Button>
              ) : (
                <a
                  href={oneLink}
                  title='Quero participar'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: 'Tesouro Direto Premiado',
                      element_action: 'click button',
                      element_name: 'Quero participar',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  <Button>Quero participar</Button>
                </a>
              )
            }
            <ScrollTo
              to='#check-out-the-awards-calendar'
              section='dobra_01'
              sectionName='Tesouro Direto Premiado'
              elementName='Já estou participando!'
              title='Já estou participando!'
            >
              <ButtonReverse>
                Já estou participando!
              </ButtonReverse>
            </ScrollTo>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2 mt-3 mt-md-0 text-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/tesouro-primiado-hero/image.webp'
              altDescription='Imagem de baú aberto com tesouro na praia'
              arrayNumbers={[ 300, 336, 376, 456 ]}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
