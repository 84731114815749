import React from 'react'
import { Button, Section, ButtonLink } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ModalProps = {
  setIsModal: Function;
}

const YouPlanYourTrip = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_2',
      section_name: 'Você planeja toda a sua viagem pelo Super App',
      element_action: 'click button',
      element_name: 'Viajar com o Inter',
      c_page: window.location.href,
    })
  }
  return (
    <Section
      className='py-5 d-flex align-items-md-center align-items-end'
      role='img'
      aria-label='Desenho de uma mala de viagem'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <span className='fs-12 lh-17 text-grayscale--500 fw-600 mb-4 d-md-block d-none'>PARA ORGANIZAR COM FACILIDADE</span>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>Você planeja toda a sua viagem pelo Super App</h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400'>O Inter te acompanha em suas viagens, do início ao fim. Aproveite para explorar novos destinos e ainda ganhar cashback em cada uma de suas reservas.</p>
            <Button onClick={() => handleClick()} className='d-none d-md-block'>Viajar com o Inter </Button>
            <ButtonLink
              className='d-md-none'
              href='https://inter-co.onelink.me/Qyu7/53fu0m0i'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_2',
                section_name: 'Você planeja toda a sua viagem pelo Super App',
                element_action: 'click button',
                element_name: 'Viajar com o Inter',
                c_page: window.location.href,
              })}
            >
              Viajar com o Inter
            </ButtonLink>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default YouPlanYourTrip
