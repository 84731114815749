import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'

import Img from 'gatsby-image'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import usePageQuery from '../../pageQuery'

import QrCodeFinanciamentoVeiculos from '../../assets/qr-code-financiamento-de-veiculos.png'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import data from './data.json'
import { Button, LinkButton, Section } from './style'

type CarouselProps = {
  upTitle: string;
  title: string;
  description: string;
  link: string;
  image: string;
  alt?: string;
  isModal?: boolean;
}

const DobraCarousel = () => {
  const images = usePageQuery()
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ carouselIndex, setCarouselIndex ] = useState(0)
  const [ isOpen, setIsOpen ] = useState(false)
  const width = useWidth()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_05',
    element_action: 'click button',
    section_name: 'Empréstimo com Garantia de Imóvel',
    element_name: 'Simule agora',
  })

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Acesse o Financiamento de Veículos do Inter'
        subtitle='Aponte a câmera do celular para o QR Code abaixo que você será direcionado para o simulador.'
        qrCode={QrCodeFinanciamentoVeiculos}
        dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <Section className='py-5'>
      {openModal}
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 order-1 order-md-2'>
            <Img fluid={images[data[carouselIndex].image].fluid} alt={data[carouselIndex].alt} />
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-2 order-md-1'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(next: number) => setCarouselIndex(next)}
            >
              { data.map((item: CarouselProps, index: number) => (
                <div key={index} className='mx-1'>
                  <h3 className='fs-18 fs-md-12 fs-lg-17 lh-22 lh-md-14 lh-lg-22 fw-700'>{item.upTitle}</h3>
                  <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 my-xl-4 fw-500 text-grayscale--500'>{item.title}</h2>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-grayscale--500'>{item.description}</p>
                  {
                    width < WIDTH_MD
                      ? (
                        <LinkButton
                          href={item.link}
                          rel='noreferrer'
                          target='_blank'
                          title='Simule agora'
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_05',
                              element_action: 'click button',
                              section_name: item.upTitle,
                              element_name: 'Simule agora',
                              redirect_url: item.link,
                            })
                          }}
                        >
                          Simule agora
                        </LinkButton>
                        )
                      : (item.isModal
                        ? (
                          <Button
                            title='Simule agora'
                            onClick={() => {
                              setDataLayer(dataLayer)
                              setIsOpen(true)
                              sendDatalayerEvent({
                                section: 'dobra_03',
                                element_action: 'click button',
                                section_name: 'Empréstimo com Garantia de Imóvel',
                                element_name: 'Simule agora',
                              })
                            }}
                          >
                            Simule agora
                          </Button>
                          )
                        : (
                          <LinkButton
                            href={item.link}
                            rel='noreferrer'
                            target='_blank'
                            title='Simule agora'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_05',
                                element_action: 'click button',
                                section_name: item.upTitle,
                                element_name: 'Simule agora',
                                redirect_url: item.link,
                              })
                            }}
                          >
                            Simule agora
                          </LinkButton>
                        )
                      )
                    }
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default DobraCarousel
