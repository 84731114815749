import React, { useEffect, useState } from 'react'
import LabelComponent from './LabelComponent/_index'
import ResultSearch from './ResultSearch/_index'

import { Container } from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { City, Partner, useCorrespondenteBancario } from 'src/context/CorrespondenteBancarioContext/CorrespondenteBancarioContext'

const SearchCorresponding = () => {
  const { states, cities, partners }: ReturnType<typeof useCorrespondenteBancario> = useCorrespondenteBancario()
  const [ selectedState, setSelectedState ] = useState<string | undefined>()
  const [ selectedCity, setSelectedCity ] = useState<string | undefined>()
  const [ selectedNeighborhood, setSelectedNeighborhood ] = useState<string | undefined>()
  const [ corresponding, setCorresponding ] = useState<Partner[]>()
  const [ result, setResult ] = useState<boolean>(false)
  const [ color, setColor ] = useState<string | undefined>('#B6B7BB')

  const [ cityOptions, setCityOptions ] = useState<string[]| undefined>()
  const [ neighborhoodsOptions, setNeighborhoodsOptions ] = useState<string[]| undefined>()

  useEffect(() => {
    if (selectedState) {
      const citiesAtState = cities.filter((city: City) => city.state === selectedState)[0].cities
      const uniqueCities = citiesAtState.reduce((acc: string[], city) => {
        if (!acc.includes(city.name.normalize())) {
          acc.push(city.name.normalize())
        }
        return acc
      }, [])
    setCityOptions(uniqueCities)
    setSelectedCity(undefined)
    setSelectedNeighborhood(undefined)
    }
  }, [ selectedState ])

  useEffect(() => {
    if (selectedState && selectedCity) {
      const citiesAtState = cities.filter((city: City) => city.state === selectedState)[0].cities
      const neighborhoods = citiesAtState.filter((city: {
        name: string;
        neighborhoods: string[];
      }) => city.name.normalize() === selectedCity.normalize())[0].neighborhoods
      console.log(neighborhoods)
      setNeighborhoodsOptions(neighborhoods)
    }
  }, [ selectedCity ])

  useEffect(() => {
    if (selectedState && selectedCity && selectedNeighborhood) {
      const filteredCorresponding: Partner[] = partners[selectedState].filter((cityObj: Partner) => cityObj.city === selectedCity && cityObj.neighborhood === selectedNeighborhood)
      setCorresponding(filteredCorresponding)
    }
  }, [ selectedState, selectedCity, selectedNeighborhood ])

  const handleSelectStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value)
    event.target.value !== 'Selecione o estado' && setColor('#161616')
    setResult(false)
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCity(event.target.value)
    event.target.value !== 'Selecione a cidade' && setColor('#161616')
    event.target.value !== '' && setResult(false)
  }

  const handleNeighborhoodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNeighborhood(event.target.value)
    event.target.value !== 'Selecione o bairro' && setColor('#161616')
    event.target.value !== '' && setResult(false)
  }

  return (
    <section>
      <Container>
        <div className='row mt-md-5 align-items-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <LabelComponent text='Estado' htmlFor='estado' />
            <span className='select mb-3'>
              <select
                value={selectedState}
                onChange={handleSelectStateChange}
                style={{ color: selectedState && color }}
              >
                <option value='' className='text-grayscale--300'>Selecione o estado</option>
                {states.map((state: string) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </span>

            <LabelComponent text='Cidade' htmlFor='cidade' />
            <span className='select mb-3'>
              <select
                value={selectedCity}
                onChange={handleCityChange}
                style={{ color: selectedCity && color }}
              >
                <option className='text-grayscale--300'>Selecione a cidade</option>
                {selectedState &&
                  cityOptions?.map((cityName: string) => (
                    <option key={cityName} value={cityName}>
                      {cityName}
                    </option>
                  ))
                }
              </select>
            </span>

            <LabelComponent text='Bairro' htmlFor='bairro' />
            <span className='select'>
              <select
                value={selectedNeighborhood}
                onChange={handleNeighborhoodChange}
                style={{ color: selectedNeighborhood && color }}
              >
                <option className='text-grayscale--300'>Selecione o bairro</option>
                {selectedCity &&
                  selectedState &&
                  neighborhoodsOptions?.map((name: string) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                ))}
              </select>
            </span>

            <div className='mt-5'>
              <button
                onClick={() => setResult(true)}
                type='submit' className='btn text-white bg-orange--linear w-100'
                title='Enviar' disabled={!selectedState || !selectedCity || !selectedNeighborhood || selectedNeighborhood === 'Selecione o bairro'}
              >
                Buscar correspondentes
              </button>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 mt-5 mt-md-0'>
            {corresponding ? (
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{ items: 1 }}
                lg={{ items: 1 }}
                xl={{ items: 1 }}
              >
                {corresponding?.map((item: Partner) => (
                  <ResultSearch
                    key={item?.cnpj}
                    result={result}
                    corresponding={item}
                  />
              ))}
              </DefaultCarousel>
            ) : (
              <ResultSearch result={false} />
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SearchCorresponding
