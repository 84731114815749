import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${orange.extra};
  overflow: hidden;

  img {
    @media ${device.tablet} {
      margin-left: -110px;
    }

    @media ${device.desktopLG} {
      margin-left: -60px;
    }

    @media ${device.desktopXL} {
      margin-left: -35%;
    }

    @media ${device.desktopXXL} {
      margin-left: -45%;
    }
  }
`
export const Title = styled.h2`
  color: ${orange.dark};
  font-weight: 500;
  font-family: 'Citrina', sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-top: 20px;

  @media ${device.desktopLG} {
    font-size: 28px;
    line-height: 32px;
    margin-top: 0;
  }

  @media ${device.desktopXL} {
    font-size: 48px;
    line-height: 54px;
  }
`
export const Texts = styled.p`
  color: white;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0;
  display: inline;
  a {
    color: white;
    text-decoration: underline;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`
