import React, { useLayoutEffect, useState, ChangeEvent } from 'react'

import useWidth from 'src/hooks/window/useWidth'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Img from 'gatsby-image'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import { Container, CloseButton } from './style'

type RequestGranitoRightFormProps = {
  closeModal: () => void;
  data: string[];
  QRCode: string;
  setQRCode: Function;
  contaType?: string;
};

type QRCodeOptionsType = {
  label: string;
  value: string;
  qrcode: string;
};

const widthMD = 768

function RequestGranitoRightForm ({
  closeModal,
  data,
  QRCode,
  setQRCode,
  contaType,
}: RequestGranitoRightFormProps) {
  const width = useWidth(200)

  const [ isMobile, setIsMobile ] = useState(true)

  useLayoutEffect(() => {
    setIsMobile(width < widthMD)
  }, [ width ])

  const QRCodeOptions = [
    {
      label: 'Pessoa Física e MEI',
      value: 'pessoa-fisica-mei',
      qrcode: 'QRCodePessoaFisicaMEI2',
    },
    {
      label: 'Pessoa Jurídica',
      value: 'pessoa-juridica',
      qrcode: 'QRCodePessoaJuridica2',
    },
  ]

  function handleChange (event: ChangeEvent<HTMLSelectElement>) {
    const optionName = event.target.value
    const option = QRCodeOptions.find(
      (option: QRCodeOptionsType) => option.value === optionName,
    )

    setQRCode(option ? option.qrcode : 'QRCodePessoaFisicaMEI')
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      <Container isModal={closeModal} className='d-flex flex-column justify-content-between align-items-center'>
        {closeButton}
        <div className='row'>
          <div className='col-12 py-4 pt-lg-0'>
            <h2 className='fs-24 lh-30 mb-4  lh-24 mt-3 text-grayscale--500 fw-600'>
              Falta pouco para solicitar a{' '}
              <span className='text-orange--extra'>Maquininha Inter Pag</span>
            </h2>
            {!isMobile ? (
              <p className='fs-14 lh-16 text-grayscale--400'>
                Para solicitar, clique no seu tipo de conta. Você será redirecionado para o Super App
              </p>
            ) : (
              <p className='fs-14 lh-16 text-grayscale--400 pr-4'>
                Para prosseguir com a solicitação, selecione o perfil de conta
                para ser redirecionado para o nosso App.
              </p>
            )}
          </div>
          <div className='col-12 d-flex flex-column justify-content-between'>
            <form className='form--default'>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column justify-content-between box mb-3 pb-1 px-0'>
                  {
                    !isMobile && (
                      <label
                        htmlFor='tipo-de-conta'
                        className='fs-14 lh-16 text-grayscale--400'
                      >
                        Tipo de conta
                      </label>
                    )
                  }
                  {
                    !isMobile ? (
                      <div className='select-form d-flex align-items-center'>
                        <select
                          className='fs-14 lh-16 fw-700'
                          name=''
                          id='tipo-de-conta'
                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleChange(event)
                          }
                        >
                          {contaType === 'pessoa-juridica'
                      ? QRCodeOptions.reverse().map(
                          (option: QRCodeOptionsType) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ),
                        )
                        : QRCodeOptions.map((option: QRCodeOptionsType) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                          ))}
                        </select>
                        <OrangeIcon size='MD' color='#FF7A00' icon='chevron-down' />
                      </div>
                      ) : (
                        <div className='mb-4'>
                          <a
                            href='https://inter-co.onelink.me/Qyu7/LPmaquininhaPF'
                            className='fs-18 lh-21 fw-600 text-orange--extra mb-4 d-block'
                          >
                            Pessoa Física e MEI
                          </a>
                          <a
                            href='https://inter-co-enterprise.onelink.me/q6ee/LPmaquininhaPJ'
                            className='fs-18 lh-21 fw-600 text-green--500 d-block'
                          >
                            Pessoa Jurídica
                          </a>
                        </div>
                      )}

                  {!isMobile ? (
                    <div>
                      <div className='d-flex justify-content-center py-5'>
                        <Img fluid={data[QRCode].fluid} alt='qrcode' />
                      </div>
                      <div>
                        <p className='fs-14 lh-16 text-grayscale--400 text-border-top'>
                          1º: Ligue e aponte a câmera do seu celular para o QR Code acima;
                        </p>
                        <p className='fs-14 lh-16 text-grayscale--400'>
                          2º: Você será redirecionado para o Super App;
                        </p>
                        <p className='fs-14 lh-16 text-grayscale--400'>
                          3º: Sendo correntista, você já pode solicitar sua Inter Pag;
                        </p>
                        <p className='fs-14 lh-16 text-grayscale--400'>
                          4º: Caso não seja correntista, basta abrir sua Conta Digital no Super App.
                        </p>

                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className='fs-14 lh-16 text-grayscale--400 mt-3'>
                        Após o redirecionamento para o Super App:
                      </p>
                      <p className='fs-14 lh-16 text-grayscale--400 '>
                        1º: Sendo correntista, você já pode solicitar sua Inter Pag;
                      </p>
                      <p className='fs-14 lh-16 text-grayscale--400'>
                        2º: Caso não seja correntista, basta abrir sua Conta Digital no Super App.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default RequestGranitoRightForm
