import styled, { createGlobalStyle } from 'styled-components'
import { grayscale, white, orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${grayscale[500]} ;


  h2  {
    font-family: 'Citrina' !important;
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};

      @media (min-width: ${breakpoints.md}) {
        background: ${white};
      }
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background: ${orange.extra};

          @media (min-width: ${breakpoints.md}) {
            background: ${white};
        }
      }
    }
  }

  img {
    width: 100%;
  }

  p {
    min-height: 76px;
  }


`
export const Card = styled.div`
  position: relative;
  margin-bottom: 42px;
  transition: transform 0.3s ease-in;


  &:hover {
      transform: scale(1.1);
    }

  img {
    max-width: 100%;


  }

  .content  {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 80%);
    padding: 24px;
    display: flex;
    align-items: flex-end;


  }


`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 8px;
  color: ${white};
  background: ${orange[500]};
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  margin-top: 32px;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 40px;
    width: 456px ;
  }

`
export const ContainerWrapper = styled.div`
  background-color: #3C3331;
  padding: 104px 44px 40px 44px;
  display: block ;
  min-height: 76px;

  @media (min-width: ${breakpoints.md}){
    padding: 39px 44px 40px 44px;
  }
  img {
     width: 100%;
     object-fit: cover;
     border-radius: 8px;


     @media (min-width: ${breakpoints.md}){
      height: 268px;
     }

     @media (min-width: ${breakpoints.xl}){
      height: 376px;
      object-fit: unset;
      width: 376px;
     }


  }


  p {
    min-height: 76px;
  }

`
export const Global = createGlobalStyle`

:root {
  --modal-close-icon-color: #ffffff !important;
}

svg {
  fill: #ffffff;
}

.react-multi-carousel-dot-list {
  button {
    background: ${white};
  }
}

h2 {
  font-family: 'Citrina' !important;
}



`
