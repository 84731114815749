import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import HeroConsultoresField from './hero'

const ConsultoresField: React.FC = () => {
  return (
    <Layout pageContext={pageContext}>
      <HeroConsultoresField />
    </Layout>
  )
}

export default ConsultoresField
