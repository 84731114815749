import React, { useState, useEffect } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import useDomReady from 'src/hooks/window/useDomReady'
// import Popup from 'src/components/Popup'
import PopupConsignado from './Popup/_popupConsignado'

import MensageAlerta from './sections/MensageAlerta/_MensageAlerta'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import pageContext from './pageContext.json'

import Hero from './sections/Hero/_Hero'
import WhatAreYouLookingFor from './sections/what-are-you-looking-for/_index'
import CarouselNovidades from './sections/CarouselNovidades/_CarouselNovidades'
import EmprestimoConsignadoINSS from './sections/EmprestimoConsignadoINSS/_EmprestimoConsignadoINSS'
import Portabilidade from './sections/Portabilidade/_Portabilidade'
import AtendimentoPersonalizado from './sections/AtendimentoPersonalizado/_AtendimentoPersonalizado'
import VantagensEmprestimo from './sections/VantagensEmprestimo/_VantagensEmprestimo'
import AlcanceObjetivos from './sections/AlcanceObjetivos/_AlcanceObjetivos'
import ComoContratarConsignado from './sections/ComoContratarConsignado/_index'
import TipoCredito from './sections/TipoCredito/_TipoCredito'
import FecheUmContratoDeConsignado from './sections/FecheUmContratoDeConsignado/_index'
import GanhePorAmigoIndicado from './sections/GanhePorAmigoIndicado/_GanhePorAmigoIndicado'
import CreditoProtegido from './sections/CreditoProtegido/_CreditoProtegido'
import CartaoBeneficioINSS from './sections/CartaoBeneficioINSS/_index'
import Faq from './sections/Faq/_Faq'

import { Wrapper } from './style'

const EmprestimoConsignadoPublico = () => {
  const [ popup, setPopup ] = useSessionStorage('@hide-bancointer-popup-emprestimo', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)

  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()

  const showModal = () => {
    setIsPopupOpen(true)
  }

  useEffect(() => {
    showModal()
  }, [ ])

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  domReady && document.addEventListener('mouseleave', showModal)

  const PopupExitPage = domReady && !popup && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <PopupConsignado
        closeModal={() => {
          setIsPopupOpen(false)
          setIsModalPopupOpen(false)
        }}
        setHidePopupSessionStorage={setPopup}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {PopupExitPage}
        <Hero sendDatalayerEvent={sendDatalayerEvent} />
        <WhatAreYouLookingFor sendDatalayerEvent={sendDatalayerEvent} />
        <CarouselNovidades sendDatalayerEvent={sendDatalayerEvent} section='dobra_3' />
        <EmprestimoConsignadoINSS sendDatalayerEvent={sendDatalayerEvent} />
        <Portabilidade sendDatalayerEvent={sendDatalayerEvent} />
        <AtendimentoPersonalizado sendDatalayerEvent={sendDatalayerEvent} />
        <VantagensEmprestimo />
        <AlcanceObjetivos />
        <ComoContratarConsignado sendDatalayerEvent={sendDatalayerEvent} />
        <TipoCredito sendDatalayerEvent={sendDatalayerEvent} />
        <FecheUmContratoDeConsignado sendDatalayerEvent={sendDatalayerEvent} />
        <GanhePorAmigoIndicado sendDatalayerEvent={sendDatalayerEvent} />
        <CreditoProtegido sendDatalayerEvent={sendDatalayerEvent} section='dobra_13' />
        <CartaoBeneficioINSS sendDatalayerEvent={sendDatalayerEvent} />
        <Faq faq={pageContext.structuredData.faq} />
        <MensageAlerta sendDatalayerEvent={sendDatalayerEvent} section='dobra_16' />
      </Layout>
    </Wrapper>
  )
}

export default EmprestimoConsignadoPublico
