import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import CustomHeader from './components/custom-header/_customHeader'
import S1AprendaTudoSobreCriptomoedas from './sections/section-1-aprenda-tudo-sobre-criptomoedas/_index'
import S2OportunidadeParaComprarCripto from './sections/section-2-oportunidade-para-comprar-cripto/_index'
import S3AMelhorMiniserieGratuita from './sections/section-3-a-melhor-webserie-gratuita/_index'
import S4FacaParteDaComunidade from './sections/section-4-faca-parte-da-comunidade-com-35-milhoes/_index'
import S5ESimplesRapidoSeguro from './sections/section-5-e-simples-rapido-seguro/_index'
import S6ConteudosQuePodemTeAjudar from './sections/section-6-conteudos-que-podem-te-ajudar/_index'
import S7FAQ from './sections/section-7-faq/_index'
import { initTheme, Theme } from '@interco/inter-ui'

const TudoSobreCriptomoedas = () => {
  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <CustomHeader />
      <S1AprendaTudoSobreCriptomoedas />
      <S2OportunidadeParaComprarCripto />
      <S3AMelhorMiniserieGratuita />
      <S4FacaParteDaComunidade />
      <S5ESimplesRapidoSeguro />
      <S6ConteudosQuePodemTeAjudar />
      <S7FAQ />
    </Layout>
  )
}

export default TudoSobreCriptomoedas
