import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

export const Tooltip = styled.div` 
  background: ${grayscale['500']};
  border-radius: 8px;
  position: absolute;
  width: 249px;
  top: 41px;
  z-index: 8;
  padding: 20px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.07);
  top: 51px;
`

export const ButtonToolTip = styled.span`
  display: block;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
`
