import React, { useState, useLayoutEffect, useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'

import Hero from './sections/hero/_index'
import DollarAccount from './sections/dollar-account/_index'
import SecureYourCard from './sections/assure-your-card/_index'
import BuyDollars from './sections/buy-dollars/_index'
import GlobalAccountAdvantages from './sections/global-account-advantages/_index'
import YourReaisAreWorthMoreDollars from './sections/your-reais-are-worth-more-dollars/_index'
import FacilityAndSecurity from './sections/facility-and-security/_index'
import HowToOpenYourGlobalAccount from './sections/how-to-open-your-global-account/_index'
import GlobalShopping from './sections/global-shopping/_index'
import InternationalGiftCards from './sections/international-gift-cards/_index'
import Faq from './sections/faq/_index'
import { Disclaimer } from './sections/disclaimer/_index'

import QrCode from './assets/images/qrcode-global-account.png'
import QrCodeNaoCorrentista from './assets/images/qr-code-global-account-nao-correntista.png'

import pageContextJSON from './pageContext.json'
// Styles
import { Content } from './style'

const WIDTH_MD = 768

const GlobalAccount = () => {
  const width = useWidth(200)
  const domReady = useDomReady()

  const oneLink = 'https://intergo.app/fd97087f'

  const [ isModalOpenAccount, setIsModalOpenAccount ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Conta Internacional Inter',
    element_action: 'click button',
    element_name: 'Não sou cliente Inter',
  }

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Escaneie o QR Code, acesse o app e abra sua Global Account'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a Global Account em nosso app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  const openAccountModal = domReady && (
    <Modal isModalOpen={isModalOpenAccount} setIsModalOpen={setIsModalOpenAccount} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpenAccount(false)}
        dataLayer={dataLayer}
        downloadAppCustomMessage='BAIXAR APP E ABRIR CONTA AGORA'
        qrBaixeApp={QrCodeNaoCorrentista}
        customOrigin='LP Global Account'
      />
    </Modal>
  )

  // Abrir modal com url
  const clearLocation = (location: string) => {
    const url = new URL(location)
    const modal = url.searchParams.get('modalGlobal')
    return modal
  }

  useLayoutEffect(() => {
    setIsMobile(WIDTH_MD > width)
  }, [ width ])

  useEffect(() => {
    const location = clearLocation(window.location.href)
    if (location) {
      setIsOpen(true)
    }
  }, [ ])

  return (
    <Content>
      <Layout pageContext={pageContextJSON}>
        {openAccountModal}
        {openModal}
        <Hero setIsOpen={setIsOpen} isMobile={isMobile} setIsModalOpenAccount={setIsModalOpenAccount} oneLink={oneLink} />
        <DollarAccount setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <SecureYourCard setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <BuyDollars setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <GlobalAccountAdvantages setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <YourReaisAreWorthMoreDollars />
        <FacilityAndSecurity />
        <HowToOpenYourGlobalAccount setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <GlobalShopping setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <InternationalGiftCards setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <Faq />
        <Disclaimer />
      </Layout>
    </Content>
  )
}

export default GlobalAccount
