import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'

const INSS = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
    </Layout>
  )
}

export default INSS
