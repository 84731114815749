import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './style'

import { GranitoForm } from './components/GranitoForm/_index'

import { Hero } from './sections/Hero/_index'
import { Machines } from './sections/Machines/_index'
import { StepByStep } from './sections/StepByStep/_index'
import { Faq } from './sections/Faq/_index'
import { MobileInterPag } from './sections/MobileInterPag/_index'

import pageContext from './pageContext.json'
import { OnePagPossibilities } from './sections/OnePagPossibilities/_index'
import { HowToGetInterPag } from './sections/HowToGetInterPag/_index'

const MaquinaGranito = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <GranitoForm isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <Hero setIsModalOpen={setIsModalOpen} />
        <Machines setIsModalOpen={setIsModalOpen} />
        <StepByStep setIsModalOpen={setIsModalOpen} />
        <MobileInterPag />
        <OnePagPossibilities />
        <HowToGetInterPag setIsModalOpen={setIsModalOpen} />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default MaquinaGranito
