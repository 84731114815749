import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'
import usePageQuery from './../../pageQuery'
import { SectionWrapper } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const MantenhaSeInformado = ({ sendDatalayerEvent }: DataLayerProps) => {
  const data = usePageQuery()
  const urlInterResearch = 'https://interinvest.inter.co/'
  const urlSpotify = 'https://open.spotify.com/show/2RGqqOTrQqTCplWIk5ht0j'
  const urlTwitter = 'https://twitter.com/interinvest'

  return (
    <SectionWrapper>
      <div className='container py-5'>
        <div className='row justify-content-center justify-content-xl-between '>
          <div className='col-12 col-sm-10 col-lg-6 col-xl-5 d-flex justify-content-center flex-column mb-4 title'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500'>Mantenha-se informado</h2>
            <p className='fs-18 lh-22 text-grayscale--500'>
              Mantenha-se informado com os nossos relátorios econômicos Inter Research, e saiba tudo sobre o mercado acompanhando nosso podcast Inter Invest e nosso twitter @interinvest.
            </p>
          </div>
          <div className='col-12 col-sm-10 col-xl-6'>
            <div className='social-media-card rounded-5 mb-4'>
              <div className='d-flex'>
                <Img fluid={data.rendaInterResearchIcon.fluid} />
                <p className='fs-16 lh-20 fw-600 text-orange--extra ml-3 mb-4'>Investimentos do Inter</p>
              </div>
              <div className='d-flex flex-wrap justify-content-between'>
                <p className='fs-16 lh-20 text-grayscale--500 p-250 subtitle fw-600 mr-3'>
                  Relatórios de estratégias e pesquisas econômicas
                </p>
                <a
                  href={urlInterResearch}
                  className='fs-14 lh-16 text-orange--extra fw-400 link'
                  target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_11',
                      section_name: 'Mantenha-se informado',
                      element_action: 'click button',
                      element_name: 'Veja nossos relatórios',
                      redirect_url: urlInterResearch,
                    })
                  }}
                > Veja nossos relatórios
                  <Icon color='orange--base' width='24' height='24' icon='navigation/arrow-right' directory='v2' className='ml-2' />
                </a>
              </div>
            </div>
            <div className='social-media-card rounded-5 mb-4'>
              <div className='d-flex'>
                <Icon color='orange--base' width='24' height='24' icon='social/new-spotify' directory='v2' />
                <p className='fs-16 lh-20 fw-600 text-orange--extra fw-600 ml-3'>Spotify</p>
              </div>
              <div className='d-flex flex-wrap justify-content-between'>
                <p className='fs-16 lh-20 text-grayscale--500 p-200 subtitle fw-600 mr-3'>
                  Podcast semanal sobre mercado financeiro
                </p>
                <a
                  href={urlSpotify}
                  className='fs-14 lh-16 text-orange--extra fw-400 link'
                  target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_11',
                      section_name: 'Mantenha-se informado',
                      element_action: 'click button',
                      element_name: 'Ouça agora mesmo',
                      redirect_url: urlSpotify,
                    })
                  }}
                > Ouça agora mesmo
                  <Icon color='orange--base' width='24' height='24' icon='navigation/arrow-right' directory='v2' className='ml-2' />
                </a>
              </div>
            </div>
            <div className='social-media-card rounded-5'>
              <div className='d-flex'>
                <Icon color='orange--base' width='24' height='24' icon='social/new-twitter' directory='v2' />
                <p className='fs-16 lh-20 fw-600 text-orange--extra fw-600 ml-3'>Twitter</p>
              </div>
              <div className='d-flex flex-wrap justify-content-between'>
                <p className='fs-16 lh-20 text-grayscale--500 p-200 subtitle fw-600 mr-3'>
                  Publicações diárias sobre investimento
                </p>
                <a
                  className='fs-14 lh-16 text-orange--extra fw-400 link'
                  href={urlTwitter}
                  target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_11',
                      section_name: 'Mantenha-se informado',
                      element_action: 'click button',
                      element_name: 'Fique por dentro',
                      redirect_url: urlTwitter,
                    })
                  }}
                > Fique por dentro
                  <Icon color='orange--base' width='24' height='24' icon='navigation/arrow-right' directory='v2' className='ml-2' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default MantenhaSeInformado
