import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import Icon from 'src/components/UI/MarkdownIcon'
import { SectionWrapper } from './style'
import { Link } from 'gatsby'
import headerJson from '../../assets/data/header.json'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

const { qrCodeAberturaDeContaRendaVariavel } = qrcodeInvestimentsData

interface IContentHeader {
  handleClick: () => void;
  sendDatalayerEvent: Function;
}

const ContentHeader = ({ handleClick, sendDatalayerEvent }: IContentHeader) => {
  return (
    <SectionWrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='bread'>
              <Link
                to='/'
                className='d-md-inline'
              ><Home height={24} width={24} color='#ffffff' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#fff' size='SM' />
              <Link
                to='/pra-voce/investimentos/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-white d-inline mr-2'
              >
                {headerJson.breadcrumb[0]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#fff' size='SM' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-white d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
            </div>
            <h1
              className='text-white mb-3 fs-32 lh-37 fs-lg-40 lh-lg-50 fs-xl-56 lh-xl-60 fw-600'
            >
              Invista na bolsa  de forma simples e com corretagem zero
            </h1>
            <p className='text-white fs-16 lh-19'>
              Aproveite o melhor de uma plataforma de investimentos com o que você precisa para investir
              e fazer seu dinheiro render mais.
            </p>
            <a
              href={qrCodeAberturaDeContaRendaVariavel.deeplink}
              className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none my-3 mb-md-0 d-flex d-md-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Invista na bolsa  de forma simples e com corretagem zero',
                  element_action: 'click button',
                  element_name: 'Quero Investir em Renda Variável',
                  })
              }}
            >Quero Investir em Renda Variável
            </a>
            <span
              className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none my-3 mb-md-0 d-none d-md-flex'
              onClick={() => {
                handleClick()
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Invista na bolsa  de forma simples e com corretagem zero',
                  element_action: 'click button',
                  element_name: 'Quero Investir em Renda Variável',
                  })
              }}
            >Quero Investir em Renda Variável
            </span>
          </div>
        </div>
        <div className='scroll-link'>
          <ScrollTo
            to='#produtos-completos'
            section='dobra_01'
            sectionName='Invista na bolsa  de forma simples e com corretagem zero'
            elementName='Conheça produtos de Renda Variável'
            styles='text-center d-block'
          >
            <p className='text-white mb-0 fs-14 fs-lg-16 fw-600'>Conheça produtos de Renda Variável</p>
            <Icon color='white' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
          </ScrollTo>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ContentHeader
