import React from 'react'

import { useVideoContext } from 'src/pages/tudo-sobre-criptomoedas/1/context/videosContext'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as contextTypes from '../../../../../context/_types'
import * as S from './_styles'

import OpenVideo from 'src/components/OpenVideo'
import { TitleContainerPosition } from '../_types'

const SelectedModule = {
  Thumb: (
    {
      setImageIsLoaded,
      titleContainerPosition,
    }: {
      setImageIsLoaded?: (isLoaded: boolean) => void;
    } & TitleContainerPosition) => {
    const { getCurrentModule }: contextTypes.VideoContextType = useVideoContext()
    const currentModule = getCurrentModule()
    const [ sendDataLayer ] = useDataLayer()

    return (
      <OpenVideo
        styles='selected_module_thumb col-12 p-0'
        link={currentModule?.link}
        onClick={() => {
          window.scroll({ top: titleContainerPosition, behavior: 'smooth' })
          sendDataLayer({
            section: 'dobra_01',
            section_name: 'Olá, bem-vindo(a) à websérie Tudo sobre Cripto!',
            element_name: `${currentModule?.name} ${currentModule?.title}`,
            element_action: 'click video',
          })
        }}
      >
        <S.VideoContentThumbnail
          src={currentModule?.image}
          onLoad={() => setImageIsLoaded && setImageIsLoaded(true)}
        />
      </OpenVideo>
    )
  },

  Title: () => {
    const { getCurrentModule }: contextTypes.VideoContextType = useVideoContext()
    const currentModule = getCurrentModule()

    return (
      <h2 className='m-0'>
        <S.VideoContentModuleNumber>{currentModule?.name}</S.VideoContentModuleNumber>
        <S.VideoContentModuleTitle><b>{currentModule?.title}</b></S.VideoContentModuleTitle>
      </h2>
    )
  },

  Topics: () => {
    const { getCurrentModule }: contextTypes.VideoContextType = useVideoContext()
    const currentModule = getCurrentModule()

    return (
      <>
        <S.VideoContentModuleTopics>
          {currentModule?.topics.map((topic: string, index: number) => (
            <S.VideoContentModuleTopicsItem
              key={topic}
            >
              <S.VideoContentModuleTopicsItemNumber>
                {index + 1}
              </S.VideoContentModuleTopicsItemNumber>
              <S.VideoContentModuleTopicsItemDescripion>
                {topic}
              </S.VideoContentModuleTopicsItemDescripion>
            </S.VideoContentModuleTopicsItem>
          ))}
        </S.VideoContentModuleTopics>
      </>
    )
  },
}

export default SelectedModule
