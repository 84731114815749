import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail, useSpring } from 'react-spring'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_MD, WIDTH_XL } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import Instagram from '../../assets/images/ic_instagram.png'
import TikTok from '../../assets/images/ic_tiktok.png'
import Youtube from '../../assets/images/ic_youtube.png'
import Linkedin from '../../assets/images/ic_linkedin.png'
import Facebook from '../../assets/images/ic_facebook.png'
import Spotify from '../../assets/images/ic_spotify.png'

import ImageWebp from 'src/components/ImageWebp'

import * as S from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

type RedesSociaisProps = {
  icon: string;
  title: string;
  url: string;
}

const FiquePorDentro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  const SM = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-360-dobra7/image.webp'
  const MD = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-768-dobra7/image.webp'
  const LG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-1024-dobra7/image.webp'
  const XL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-1440-dobra7/image.webp'

  const imgMobile = width < WIDTH_MD ? SM : MD
  const imgDesktop = width < WIDTH_XL ? LG : XL

  const imgSize = width <= WIDTH_MD ? imgMobile : imgDesktop

  const icons: RedesSociaisProps[] = [
    {
      icon: Instagram,
      title: "Instagram",
      url: "https://www.instagram.com/Interbr",
    },
    {
      icon: TikTok,
      title: "TikTok",
      url: "https://www.tiktok.com/@interbr",
    },
    {
      icon: Youtube,
      title: "Youtube",
      url: "https://youtube.com/@interbr",
    },
    {
      icon: Linkedin,
      title: "Linkedin",
      url: "https://www.linkedin.com/company/inter/",
    },
    {
      icon: Facebook,
      title: "Facebook",
      url: "https://www.facebook.com/interbrasil",
    },
    {
      icon: Spotify,
      title: "Spotify",
      url: "",
    },
  ]

  return (
    <section ref={sectionRef} className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 mt-2 mt-md-0'>
            <animated.h2 style={animatedSection[0]} className='fs-28 fs-md-40 fs-lg-48 lh-33 lh-md-44 lh-lg-52 mt-3 mt-md-0 mb-2 mb-xl-4 font-citrina'>Fique por dentro de tudo que acontece no Inter</animated.h2>
          </div>
        </div>
        <div className='row align-items-center mt-3 mt-md-0'>
          <animated.div style={animatedImage} className='col-12 col-md-7 col-xl-6 d-flex justify-content-center justify-content-md-start mt-lg-1'>
            <ImageWebp
              pathSrc={imgSize}
              altDescription='Tela celular mostrando o site Inter.co'
              arrayNumbers={[ 272, 359, 490, 559 ]}
            />
          </animated.div>
          <animated.div style={animatedSection[1]} className='col-12 col-md-5 col-xl-6 mt-4 mt-lg-0'>
            <S.Card className=' mt-md-1 mt-lg-0'>
              <h3 className='fs-24 fs-md-26 fs-lg-40 lh-28 lh-md-33 lh-lg-44 text-white fw-500 font-citrina'>Quer saber as novidades do Inter em primeira mão?</h3>
              <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 text-white'>Receba todas as informações sobre o Inter no seu e-mail: parcerias, principais conquistas e muito mais.</p>
              <Link
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_7',
                    section_name: 'Fique por dentro de tudo que acontece no Inter',
                    element_action: 'click button',
                    element_name: 'Cadastre-se',
                    redirect_url: 'https://inter.co/newsroom/',
                  })
                }}
                to='/newsroom/'
                className='fs-14 fs-md-16 fs-lg-18 lh-17 lh-md-19 lh-lg-22 text-white'
              >
                <u>Cadastre-se</u>
              </Link>
            </S.Card>
            <p className='fs-20 fs-md-24 fs-lg-28 lh-24 lh-md-28 lh-lg-33 text-primary--500 fw-500 mt-5 font-citrina'>Conecte-se ao Fórum, a comunidade exclusiva do Super App</p>
          </animated.div>
        </div>
      </div>
    </section>
  )
}

export default FiquePorDentro
