import React, { MouseEvent } from 'react'

import errorImg from 'src/assets/images/erro.png'

import { Container, SetError } from '../../style'

type ErrorStepProps = {
  handleReturn: (evt: MouseEvent<HTMLButtonElement>) => void;
  page: string;
}

const ErrorStep = ({ handleReturn, page }: ErrorStepProps) => {
  return (
    <Container page={page}>
      <SetError page={page}>
        <img
          src={errorImg}
          alt='Imagem que representa um erro no envio do formulário'
          width={64}
          height={64}
        />
        <p>
          Infelizmente não foi possível coletar seus dados no momento, por favor, tente mais tarde.
        </p>
        <button
          title='Tentar novamente'
          onClick={handleReturn}
        >
          Tentar novamente
        </button>
      </SetError>
    </Container>
  )
}

export default ErrorStep
