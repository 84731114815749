import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import OpenVideo from 'src/components/OpenVideo'
import { Section, Tag } from './style'
import { creditList } from './_creditList'
import { ModalContentDispatchT, useModalContent } from '../../context/_ModalContentContext'
import { SideModalDispatchT, useSideModal } from '../../context/_SideModalContext'
import { ViewsEnum } from '../../components/_ModalContentFactory'

import Play from '../../assets/default-play-button.png'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

type CreditoProps = {
  sectionId: string;
}

export const Credito = ({ sectionId }: CreditoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { setModalContentView }: ModalContentDispatchT = useModalContent()
  const { setIsModalOpen }: SideModalDispatchT = useSideModal()
  const deepLink = 'https://inter-co.onelink.me/Qyu7/f9qebj1r'
  const titleH2 = 'Como colocar crédito no celular?'
  const width = useWidth()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_5',
    section_name: titleH2,
    element_action: 'click button',
    element_name: 'Recarregar agora',
  }

  const handleClick = () => {
    sendDatalayerEvent(dataLayer)
    setIsModalOpen(true)
    setModalContentView(ViewsEnum.primaryFlow)
  }
  return (
    <Section className='py-4 py-md-5 d-flex align-items-end align-items-md-center' id={sectionId}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='open-video'>
            <OpenVideo
              link='https://www.youtube.com/embed/BixmEjqjvuY?si=aMGMSwFoLGR8O94Z?&autoplay=1'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_5',
                  section_name: titleH2,
                  element_action: 'click video',
                  element_name: 'Assista ao vídeo',
                  redirect_url: 'https://www.youtube.com/embed/BixmEjqjvuY?si=aMGMSwFoLGR8O94Z',
                })
              }}
            >
              <img
                src={Play}
                alt='Assista ao vídeo'
                className='cursor-pointer'
                title='Assista ao vídeo'
              />
            </OpenVideo>
          </div>

          <div className='col-12 col-md-6 offset-md-6 mb-4 mb-md-0'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500 mb-4 mr-n2 mb-md-3'>
              {titleH2}
            </h2>
            <p className='font-sora fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500 mb-4 mb-lg-3 mr-lg-n3'>
              Fazer uma <span className='fw-700'>{' '}recarga de celular{' '}</span> pelo Super App Inter é muito fácil e rápido:
            </p>

            <div className='mb-4 mb-xl-5'>
              {creditList?.map((credit: typeof creditList[0], index: number) => (
                <div className='d-flex align-items-center mb-4' key={index}>
                  <Tag className='font-sora'>{index + 1}</Tag>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0'>{credit}</p>
                </div>
              ))}
            </div>
            {width < WIDTH_MD
                ? (
                  <a
                    href={deepLink}
                    className='btn btn--lg btn-orange--extra text-none w-100 mw-100 mt-3 mt-md-2 mt-xl-0'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        element_action: 'click button',
                        section_name: titleH2,
                        element_name: 'Recarregar online',
                        redirect_url: deepLink,
                       })
                    }}
                  >
                    Recarregar agora
                  </a>
                )
                : (
                  <button
                    className='btn btn--lg btn-orange--extra text-none w-100 mw-100 mt-3 mt-md-2 mt-xl-0'
                    onClick={handleClick}
                  >
                    Recarregar agora
                  </button>
                )
              }
          </div>
        </div>
      </div>
    </Section>
  )
}
